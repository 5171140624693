import isServer from '@anm/helpers/is/isServer';
import useIsMountedCheck from '@anm/hooks/useIsMountedCheck';
import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { portals, PORTAL_ITEM_NAME } from '..';

type UniversalPortalProps = {
  children: ReactNode;
  containerSelector: string;
};

const UniversalPortal: FC<UniversalPortalProps> = ({
  children,
  containerSelector
}) => {
  const isMounted = useIsMountedCheck();

  if (isServer()) {
    !portals.includes(containerSelector) && portals.push(containerSelector);

    return (
      <div id={PORTAL_ITEM_NAME} data-universal-portal={containerSelector}>
        {children}
      </div>
    );
  }

  const container = document.querySelector(containerSelector);
  return container && isMounted ? createPortal(children, container) : null;
};

export default UniversalPortal;
