import { DEFAULT_DROPDOWN_DELAY, NO_DELAY } from '@anm/constants/delays';
import isMobile from '@anm/helpers/is/isMobile';
import noop from '@anm/helpers/noop';
import useHover from '@anm/hooks/useHover';
import useSwitchState from '@anm/hooks/useSwitchState';
import { useCallback, useEffect, useRef, useState } from 'react';

type UseDropdownProps = {
  openDelay: number;
  closeDelay: number;
  canToggle?: boolean;
  isOpenActionByHover: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

let openTimeout: number;
let closeTimeout: number;
let resetTimeout: number;

export const useAutoClose = (autoClose: boolean, closeDropdown: () => void) => {
  const prevAutoClose = useRef(autoClose).current;

  useEffect(() => {
    if (!autoClose || autoClose === prevAutoClose) return;

    closeDropdown();
  }, [autoClose]);
};

export const useDropdown = ({
  openDelay,
  closeDelay,
  canToggle,
  isOpenActionByHover,
  onOpen = noop,
  onClose = noop
}: UseDropdownProps) => {
  const [isOpened, open, close, toggle] = useSwitchState();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const isMobileDevice = isMobile();

  useEffect(() => {
    if (!isOpenActionByHover) return;

    const canOpen = !isOpened && isHovered;
    const canClose = isOpened && !isHovered;

    canOpen && openDropdown();
    canClose && closeDropdown();
  }, [isHovered, isOpened]);

  const handleToggle = useCallback(() => {
    toggle();
    isOpened ? onClose() : onOpen();
  }, [isOpened, toggle, onOpen]);

  const handleClose = useCallback(() => {
    close();
    onClose();
  }, [close, onClose]);

  const openDropdown = useCallback(() => {
    const cb = canToggle && !isMobileDevice ? handleToggle : open;

    if (isMobileDevice) cb();
    else openTimeout = window.setTimeout(cb, openDelay);
  }, [openDelay, handleToggle, isMobileDevice]);

  const closeDropdown = useCallback(() => {
    if (isMobileDevice) handleClose();
    else closeTimeout = window.setTimeout(handleClose, closeDelay);
  }, [closeDelay, handleClose, isMobileDevice]);

  useEffect(
    () => () => {
      window.clearTimeout(openTimeout);
      window.clearTimeout(closeTimeout);
    },
    []
  );

  const handleMouseLeave = useCallback(() => window.clearTimeout(openTimeout), []);

  return [hoverRef, isHovered, isOpened, openDropdown, closeDropdown, handleMouseLeave] as const;
};

export const useDropdownGroup = (initialDelay: number = DEFAULT_DROPDOWN_DELAY) => {
  const [delay, setDelay] = useState(initialDelay);
  const [isFirstOpen, setFirstOpen] = useState(false);

  const resetState = useCallback(() => {
    setDelay(initialDelay);
    setFirstOpen(false);
  }, [initialDelay]);

  const handleDropdownOpen = useCallback(() => {
    clearTimeout(resetTimeout);
    !isFirstOpen && setFirstOpen(true);
  }, []);

  const handleDropdownClose = useCallback(() => {
    resetTimeout = window.setTimeout(resetState, initialDelay);
  }, [initialDelay, resetState]);

  useEffect(() => {
    if (!isFirstOpen) return;

    setDelay(NO_DELAY);
  }, [isFirstOpen]);

  return [delay, handleDropdownOpen, handleDropdownClose] as const;
};

export const useDropdownOutside = (onOpen = noop, onClose = noop) => {
  const [isOpened, open, close] = useSwitchState(false);

  const handleOpenDropdown = useCallback(() => {
    open();
    onOpen();
  }, [open, onOpen]);

  const handleCloseDropdown = useCallback(() => {
    close();
    onClose();
  }, [close, onClose]);

  return [isOpened, handleOpenDropdown, handleCloseDropdown] as const;
};
