import { useEffect, useRef, useState } from 'react';

const useHover = <T extends Element>(delay = 0, defaultHover = false) => {
  const ref = useRef<T>(null);
  const [isHovered, setHovered] = useState(defaultHover);
  const [isDelayActive, setDelayActive] = useState(false);

  const handleMouseEnter = () => (delay ? setDelayActive(true) : setHovered(true));

  const handleMouseLeave = () => (delay ? setDelayActive(false) : setHovered(false));

  useEffect(() => {
    if (!isDelayActive) {
      setTimeout(() => setHovered(false), 0);
      return;
    }

    const timeId = setTimeout(() => setHovered(true), delay);

    return () => {
      clearTimeout(timeId);
    };
  }, [delay, isDelayActive]);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [ref.current]);

  return [ref, isHovered, setHovered] as const;
};

export default useHover;
