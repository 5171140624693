import styled, { css } from 'styled-components';

import getFromCdn from '../../helpers/getFromCdn';

import { CollapseVariants } from '.';
import { RowProps } from './types';

const variants: { [key in CollapseVariants]: ReturnType<typeof css> } = {
  'right-down-arrow': css<RowProps>`
    transform: ${({ isOpened }) => (isOpened ? 'rotate(90deg)' : '')};
    border: 10px solid transparent;
    box-sizing: content-box;
    background-image: url(${getFromCdn('images/collapse-icon.svg')});
    width: 10px;
    height: 6px;

    & ~ div {
      padding: 0;
    }
  `,
  'up-down-arrow': css<RowProps>`
    transform: ${({ isOpened }) => (isOpened ? 'rotate(180deg)' : '')};
    position: absolute;
    right: 20px;
    top: 35px;
    width: 20px;
    height: 12px;
    background-image: url(${getFromCdn(
      'images/arrow-down-collapse-gray-thin.svg'
    )});
    background-size: contain;
    pointer-events: none;
  `,
  'plus-minus': css<RowProps>`
    width: 10px;
    height: 10px;
    background-image: ${({ isOpened }) =>
      isOpened
        ? `url(${getFromCdn('images/icons/mobile_navigation/minus.svg')})`
        : `url(${getFromCdn('images/icons/mobile_navigation/plus.svg')})`};
  `,
  'up-down-small-arrow': css<RowProps>`
    width: 14px;
    height: 14px;
    transition: 0.2s;
    margin-right: 8px;
    background: url(${getFromCdn('images/icons/collapse-arrow.svg')});
    transform: rotate(${({ isOpened }) => (isOpened ? '180deg' : '0')});
  `
};

const IconArrow = styled.div<RowProps>`
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  ${({ variant }) => variants[variant]};
`;

export default IconArrow;
