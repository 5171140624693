import PlayControl from '@anm/helpers/PlayControl';
import getFromCdn from '@anm/helpers/getFromCdn';
import noop from '@anm/helpers/noop';
import Plyr from 'plyr';
import { useEffect, useState } from 'react';

import { DefaultProps, Player, VideoEvents } from '.';

export const useVideoPlayer = ({
  videoRef,
  playerRef
}: VideoEvents & DefaultProps) => {
  const [player, setPlayer] = useState({} as Player);
  const [unsubscribePlayControl = noop, setUnsubscribePlayControl] = useState<
    () => void
  >();

  const subscribePlayControl = () => {
    const playControl = new PlayControl();
    const subscription = playControl.subscribe(
      canPlay => !canPlay && player.pause()
    );
    setUnsubscribePlayControl(subscription);
  };

  useEffect(() => {
    const plyrPlayer = new Plyr(videoRef.current!, {
      iconUrl: `${getFromCdn('images/plyr.svg')}`,
      invertTime: false,
      playsinline: true,
      hideControls: true,
      fullscreen: { enabled: false, fallback: false, iosNative: false },
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'captions',
        'settings'
      ]
    }) as unknown;
    const player = plyrPlayer as Player;

    setPlayer(player);

    playerRef(player);

    subscribePlayControl();

    return () => {
      unsubscribePlayControl();
    };
  }, []);

  useEffect(() => {
    if (!player.elements) return;
    const poster = player.elements.poster as HTMLDivElement;
    poster.style.backgroundImage = `url(${player.poster})`; // The plyr has some bug with update poster and I fix it manually.
  });
};
