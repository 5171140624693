import getFromCdn from '@anm/helpers/getFromCdn';
import styled from 'styled-components';

import { media } from '../../../styles/helpers';

const QuoteIconOnLaptop = media.laptop`
  width: 163px;
  height: 127px;
  top: 0;
  left: 0;
`;

const FeedbackTextDataWrapperOnLaptop = media.laptop`
  padding: 23px 0 0 53px;
  position: relative;

  &:before {
    display: block;
    content: '';
    width: 104px;
    height: 81px;
    position: absolute;
    top: -5px;
    left: 0;
    background: url(${getFromCdn(
      'images/data/quote.svg'
    )}) no-repeat center / cover;
    transform: rotate(180deg);
    ${QuoteIconOnLaptop};
  }
`;

const FeedbackTextDataWrapper = styled.div`
  ${FeedbackTextDataWrapperOnLaptop};
`;

export default FeedbackTextDataWrapper;
