import { ButtonProps } from '@anm/components/buttons/Button';

import lfConfig from '../../../config';

type FeedbackCommonProps = {
  id: string;
  twit: string;
  name: string;
  avatar: string;
  link?: string;
};

export type ClassicFeedbacksProps = FeedbackCommonProps & {
  company: string;
  tutorial?: string;
};

export type CardFeedbacksProps = FeedbackCommonProps & {
  cardBackground: string;
  customButton?: ButtonProps;
};

export type FeedbacksItemProps = ClassicFeedbacksProps | CardFeedbacksProps;

const feedbacks: FeedbacksItemProps[] = [
  {
    id: 'smith1',
    avatar: 'mari-smith_png.webp',
    name: 'Mari Smith',
    company: 'Premier Facebook Marketing Expert, Social Media Thought Leader',
    twit:
      "I LOVE Wave.video for creating quick, professional videos! The UI is super easy to use, with intuitive drag and drop. Wave.video is definitely one of the easiest video editing tools I've come across!",
    tutorial: 'Watch the exclusive webinar'
  },
  {
    id: 'aandpete1',
    avatar: 'aandp_png.webp',
    name: 'Andrew and Pete',
    company: 'andrewandpete.com',
    twit:
      'Wave.video is an amazing platform for creating social media videos. A feature we love is the ability to change the dimensions of the video to square and portrait in a matter of seconds too — gamechanger!'
  },
  {
    id: 'andy_crestodina',
    avatar: 'andy_crestodina_png.webp',
    name: 'Andy Crestodina',
    company: 'Co-founder and the strategic director of Orbit Media Studios',
    twit:
      "If you haven't tried Wave.video, give it a shot. A very efficient way to create higher production value social content."
  },
  {
    id: 'jeff_sieh',
    avatar: 'jeff_sieh_png.webp',
    name: 'Jeff Sieh',
    company: 'Leading Pinterest marketing specialist',
    twit:
      'Wave.video is one of my favorite online tools that I use to create videos online, and I love it.'
  },
  {
    id: 'amy-schmittauer_landino',
    avatar: 'amy_schmittauer_landino_png.webp',
    name: 'Amy Schmittauer Landino',
    company: 'Globally-recognized YouTuber, speaker, author, and entrepreneur',
    twit:
      "Just loving what I'm seeing! Really fun interface. Super easy way to make a great video"
  },
  {
    id: 'lilach_bullock',
    avatar: 'Lilach-Bullock_jpg.webp',
    name: 'Lilach Bullock',
    company:
      'Professional Speaker, Lead Conversion Expert, Content Marketing & Outreach Specialist',
    twit:
      'Another big plus of using Wave.video? It’s incredibly easy to use. Once you get the hang of the tool, you can create a short video in under five minutes – it’s that quick.'
  },
  {
    id: 'donna_moritz',
    avatar: 'donna_moritz_png.webp',
    name: 'Donna Moritz',
    company: 'The Founder of the Award-winning Blog Socially Sorted',
    twit:
      'Wave.video is adding new templates, fonts and features all the time. It’s a tool to watch and grow with, for sure!'
  },
  {
    id: 'michael_keshen',
    avatar: 'homestars_author_png.webp',
    name: 'Michael Keshen',
    company: 'B2C Marketing Manager, HomeStars',
    twit:
      'The biggest difference was the integration with stock footage, images and audio, which I have not seen anywhere else. Wave.video solves the biggest challenge we were facing, which was where to find the footage to use in our videos.',
    link: `${lfConfig.waveUrl}success-stories/homestars`,
    tutorial: 'Read the whole story'
  },
  {
    id: 'bryon_mccartney',
    avatar: 'success_story_avatar_be_brilliant_png.webp',
    name: 'Bryon McCartney',
    company: 'Managing Partner & Chief Idea Guy, Be Brilliant Marketing',
    twit:
      'My clients rave about their Wave videos, not only because they look great, but also because we can deliver them quickly and at a fair price.',
    link: `${lfConfig.waveUrl}success-stories/be-brilliant`,
    tutorial: 'Read the whole story'
  },
  {
    id: 'austin_dwight',
    avatar: 'austin-dwight_png.webp',
    name: 'Austin Dwight',
    company: 'Owner and Creative Director, 981Media',
    twit:
      'I had looked at several video making tools. Most were either not working from an ROI point of view or the features were not there. I had even thought about creating my own tool. But after testing Wave.video for just a few minutes I knew that if I had built a platform in-house, I would want it to be like Wave.video.',
    link: `${lfConfig.waveUrl}success-stories/981-media`,
    tutorial: 'Read the whole story'
  },
  {
    id: 'nicoline_maes',
    avatar: 'nicoline-maes.jpg',
    name: 'Nicoline Maes',
    company: 'Online Marketing Specialist, Social Marketing Doctors',
    twit:
      'A super easy tool to generate videos in all different branded styles and it is affordable. It is a money generator if used correctly.'
  },
  {
    id: 'dawn_mccoy',
    avatar: 'dawn-mccoy.jpg',
    name: 'Dawn McCoys',
    company: 'Lead Consultant, Flourish Leadership',
    twit:
      'I love that you can go in seconds from having a Facebook to a Twitter to a YouTube video just by modifying the format that you want that in. You can use it in many different ways within seconds.'
  },
  {
    id: 'pavlos_rizos',
    avatar: 'pavlos_v2_png.webp',
    name: 'Pavlos Rizos, <br /> Founder of All Things Nordic',
    twit:
      'This daily Nordic Newsletter gets 67% Increase in Email Subscribers with the Help of Video',
    cardBackground: '#C4EAFF',
    customButton: {
      href: `${lfConfig.waveUrl}success-stories/all-things-nordic`,
      textColor: 'inherit',
      children: 'Read the story →',
      variant: 'link',
      size: 'medium'
    }
  },
  {
    id: 'margaret_hamilton',
    avatar: 'margaret_v2_png.webp',
    name: 'Margaret Hamilton, <br /> CEO, EXIT Realty Colorado',
    twit: 'Real Estate Agency gets 20 times more reach on Facebook with videos',
    cardBackground: '#FFECCE',
    customButton: {
      href: `${lfConfig.waveUrl}success-stories/real-estate-video-marketing`,
      textColor: 'inherit',
      children: 'Read the story →',
      variant: 'link',
      size: 'medium'
    }
  },
  {
    id: 'bryon_mccartney2',
    avatar: 'bryon_v2_png.webp',
    name:
      'Bryon McCartney <br /> Managing Partner & Chief Idea Guy, <br /> Be Brilliant Marketing',
    twit:
      'Marketing Agency Runs Facebook Video Advertising Campaigns at a Cost of 3¢ per Engagement',
    cardBackground: '#FFD5F2',
    customButton: {
      href: `${lfConfig.waveUrl}success-stories/be-brilliant`,
      textColor: 'inherit',
      children: 'Read the story →',
      variant: 'link',
      size: 'medium'
    }
  },
  {
    id: 'joe_wright',
    avatar: 'joe_v2_png.webp',
    name: 'Joe Wright, Creative Director, <br /> Thrive Mortgage',
    twit: 'This Texas-based company saves 2 days per video with fast editing',
    cardBackground: '#FEF791',
    customButton: {
      href: `${lfConfig.waveUrl}success-stories/thrive-mortgage`,
      textColor: 'inherit',
      children: 'Read the story →',
      variant: 'link',
      size: 'medium'
    }
  },
  {
    id: 'hannah_collins_lee',
    avatar: 'hannah-lee_png.webp',
    name: 'Hannah Collins Lee',
    company: 'Co-Founder and CEO at Second Mile Marketing',
    twit:
      'Wave.video has been integral to our team’s success. It provides us with an efficient and creative way to execute on social media video content. One of the best & easiest subscriptions we’ve purchased! Happy to recommend Wave.video whenever possible.',
    cardBackground: '#FEF791',
    link: `https://www.secondmilemarketing.com/`
  },
  {
    id: 'robert_ritter',
    avatar: 'Robert-Ritter_png.webp',
    name: 'Robert Ritter',
    company: 'Independent Health and Wellness Consultant',
    twit:
      'I have been using Wave.video for almost a year now. I have always been very pleased to work on their platform. It is beautifully designed for the least technical amongst us.',
    cardBackground: '#FEF791',
    link: `https://www.linkedin.com/in/robert-ritter-dna-designer-nutrition/`
  },
  {
    id: 'robert_ritter_1',
    avatar: 'Robert-Ritter_png.webp',
    name: 'Robert Ritter',
    company: 'Independent Health and Wellness Consultant',
    twit:
      'I have been using Wave.video for almost a year now. The video editor is very simply designed and very forgiving as well. I can have my videos done from start to finish in literally about 10 minutes for a promo video, and not much longer for longer projects.',
    cardBackground: '#FEF791',
    link: `https://www.linkedin.com/in/robert-ritter-dna-designer-nutrition/`
  },
  {
    id: 'julie_pierson_fields',
    avatar: 'Julie-Pierson-Fields_png.webp',
    name: 'Julie Pierson-Fields',
    company: 'President at Absolute Holdings Group (AHG Marketing)',
    twit:
      'The platform is incredibly easy to use and has been super helpful for us! Thank you!',
    cardBackground: '#FEF791',
    link: `https://www.linkedin.com/in/julie-pierson-fields`
  },
  {
    id: 'rose_davidson',
    avatar: 'Rose-Davidson_png.webp',
    name: 'Rose Davidson',
    company: 'Founder of DOES Biz',
    twit:
      'I love the platform. I have tried others but they do not stack up against Wave.video.',
    cardBackground: '#FEF791',
    link: `https://www.linkedin.com/showcase/rose-davidson-doesbiz`
  },
  {
    id: 'sam_mcnally',
    avatar: 'Sam-McNally_png.webp',
    name: 'Sam McNally',
    company: 'Musician, Music Educator, Recording Artist',
    twit:
      'Wave.video is fast, intuitive, and ..... works great! Love it. It has become a major tool for my' +
      ' work.',
    cardBackground: '#FEF791',
    link: `https://au.linkedin.com/in/sammcnally1`
  }
];

export default feedbacks;
