import { VideoWrapper } from '@anm/components/VideoPlayer';
import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

const MAX_MEDIA_WIDTH = 766;

export const ButtonContainer = styled.div`
  ${flexCenter};
  width: 100%;
  margin-top: 26px;
`;

const zoomInStyles = css`
  transform: scale(1.2);
  transition: all ease-in-out 6s;
`;

export const MediaContainer = styled.div`
  overflow: hidden;
  max-height: calc(100vh - 200px);
  max-width: ${MAX_MEDIA_WIDTH}px;
`;

const Wrapper = styled.div<{ isAnimating: boolean }>`
  width: 100%;
  height: 100%;
  pointer-events: all;
  position: relative;
  img {
    transform: scale(1);
    ${({ isAnimating }) => isAnimating && zoomInStyles}
  }
  img,
  video {
    width: 100%;
    object-fit: contain;
  }
  ${VideoWrapper} {
    position: static;
  }
`;

export default Wrapper;
