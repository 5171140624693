export default {
  'set 1': {
    list: [
      {
        title: 'Finally a Video App that Makes Video Creation Easy!',
        description:
          'I really enjoy the ability to easily create several canvas options for all the different social platforms. It also has a very robust stock image and stock video library to pull from.',
        author: 'Shannon L.',
        position: 'CEO, Marketing and Advertising',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Keeping it Simply Beautiful',
        description:
          'I have used Wave.video for a few months off and on as I was testing various software and platforms. But, the elegant simplicity and user friendly editor are what has made staying an easy choice. The assets and tools you have at your fingertips are outstanding. And, the developers work tirelessly to add new features continually. ',
        author: 'Robert R.',
        position: 'Small-Business',
        logo: {
          src: 'images/icons/g2crowd-logo.svg',
          link: 'https://www.g2.com/products/wave-video/reviews'
        }
      },
      {
        title: 'OMG! Could this be any easier',
        description:
          "I am officially in love with the software. I've already made 3 videos and it took me less thank 10 minutes. This is a gamechanger. I love that it can be branded, personalized and soooooo easy to use.",
        author: 'Yetunde S.',
        position: 'Coach, Public Relations and Communications',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      }
    ]
  },
  'set 2': {
    list: [
      {
        title: 'Great Tool for Social Media Videos',
        description:
          "I can easily scale my videos between social media platforms. It's easy to replace template images with my own.",
        author: 'Toiny W.',
        avatar: 'images/data/reviews/tony_w.jpg',
        position: 'Owner, Arts and Crafts',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'The only video creation tool you will need',
        description:
          'It takes 20-30 minutes to create a cool video for my clients. If you are not using it, give the free trial a try!',
        author: 'Anna F.',
        position: 'Blogger',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Easy to Use Software',
        description:
          'Wave is easy to use, has a lot of good Stock-Pictures and -Videos and Stickers. It´s easy to create a good looking video for social media.',
        author: 'Martin K.',
        position: 'Social Media Consultant',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      }
    ]
  },
  'set 3': {
    list: [
      {
        title: 'Best Value video marketing software',
        description:
          'Wave has enabled me to add video creation to my social media marketing efforts for my clients without significantly increasing my costs. They are and I are very happy. ',
        author: 'Andrew M.',
        position: 'Owner, Marketing and Advertising',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'Easy to Use, Flexible with New Features',
        description:
          'Wave allows us to quickly produce high quality videos to promote our online courses and to prepare tutorials for learners and faculty members. The images, videos and music in the library are extensive and generally of high quality. ',
        author: 'Nicola D.',
        position: 'Marketing Coordinator, E-Learning',
        logo: {
          src: 'images/icons/capterra-logo.svg',
          link: 'https://www.capterra.com/p/172640/Wave-video/reviews/'
        }
      },
      {
        title: 'The right tool for the right job',
        description:
          'For specific jobs of shorter videos made for social media, this online video editing tool is a huge time saver and delivers excellent results. Love the stock audio, love how it remembers settings I previously set as a pre-set. Love the time saving.',
        author: 'User in Marketing and Advertising',
        position: 'Small-Business',
        logo: {
          src: 'images/icons/g2crowd-logo.svg',
          link: 'https://www.g2.com/products/wave-video/reviews'
        }
      }
    ]
  }
};
