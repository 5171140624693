import { useCallback, useEffect, ChangeEvent, FocusEvent, FC } from 'react';
import { FieldRenderProps } from 'react-final-form';

import FormHelperText, { FormHelperTextVariants } from '../FormHelperText';
import InputLabel, { LabelVariant } from '../InputLabel';
import { useTextFieldFocusStatus } from '../TextInput/hooks';

import { TextAreaWrapper, Wrapper } from './Wrapper';

export * from './Wrapper';

type TextAreaProps = FieldRenderProps<string, HTMLTextAreaElement> & {
  label: string;
  helperTextVariant: FormHelperTextVariants;
  resize?: boolean;
  disabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  minHeight?: number;
  autoResize?: boolean;
  placeholder?: string;
  defaultValue?: string;
  isFieldFocused?: boolean;
  labelVariant?: LabelVariant;
};

const TextArea: FC<TextAreaProps> = ({
  input,
  meta,
  label,
  resize = true,
  minHeight,
  className,
  defaultValue,
  disabled = false,
  isFieldFocused = false,
  autoFocus,
  autoResize = false,
  placeholder = ' ',
  labelVariant = 'animated-inside',
  helperTextVariant
}) => {
  const [
    fieldRef,
    isFocused,
    setIsFocused,
    isPlaceholderShown
  ] = useTextFieldFocusStatus<HTMLTextAreaElement>(isFieldFocused);

  const handleResize = useCallback(() => {
    const textArea = fieldRef.current;
    if (!textArea || !autoResize) return;

    textArea.style.height = `${textArea.scrollHeight}px`;
  }, [!!fieldRef.current, autoResize]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      handleResize();
      input?.onChange(event);
    },
    []
  );

  const handleBlur = useCallback((event: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);
    input?.onBlur(event);
  }, []);

  const handleFocus = useCallback((event: FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true);
    input?.onFocus(event);
  }, []);

  useEffect(() => {
    handleResize();
  }, [defaultValue]);

  return (
    <>
      <Wrapper {...(className && { className })}>
        {meta?.touched && meta?.error && (
          <FormHelperText variant={helperTextVariant}>
            {meta?.error}
          </FormHelperText>
        )}
        <InputLabel
          variant={labelVariant}
          {...{ isFocused, isPlaceholderShown }}
        >
          {label}
        </InputLabel>
        <TextAreaWrapper
          {...input}
          ref={fieldRef}
          value={input?.value || defaultValue}
          hasError={meta?.touched && meta?.error}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          {...{
            resize,
            placeholder,
            disabled,
            ...(minHeight && { minHeight }),
            ...(autoFocus && { autoFocus })
          }}
        />
      </Wrapper>
    </>
  );
};

export default TextArea;
