import { useCallback, PropsWithChildren, ReactNode } from 'react';

import Wrapper, { Input, LabelText } from './Wrapper';

export { LabelText };

export enum RadioButtonSizes {
  big = 'big',
  small = 'small'
}

export type RadioButtonProps<T, N = string> = {
  name: N;
  value: string;
  label: ReactNode;
  isChecked: boolean;
  size?: RadioButtonSizes;
  disabled?: boolean;
  onChange(val: T): void;
};

const RadioButton = <T extends string>({
  name,
  value,
  label,
  onChange,
  disabled,
  isChecked,
  size = RadioButtonSizes.small
}: PropsWithChildren<RadioButtonProps<T>>) => {
  const handleChange = useCallback(() => onChange(value as T), [value, onChange]);

  return (
    <Wrapper {...{ size }}>
      <label>
        <Input
          type="radio"
          checked={isChecked}
          onChange={handleChange}
          {...{ name, value, ...(disabled && { disabled }) }}
        />
        <LabelText>{label}</LabelText>
      </label>
    </Wrapper>
  );
};

export default RadioButton;
