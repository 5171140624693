import useResize from '@anm/hooks/useResize';
import compact from 'lodash/fp/compact';
import compose from 'lodash/fp/compose';
import flatten from 'lodash/fp/flatten';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

import Columns from './Columns';
import { getColumnsWithItems, getMasonryContent } from './helpers';
import { useColumn } from './hooks';
import { MasonryProps } from './types';
export * from './helpers';
export * from './hooks';
export * from './types';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const flattenAndValidateArray = (arr: ReactNode[]) => compose(compact, flatten)(arr);

const Masonry: FC<MasonryProps> = ({ children, breakpointCols, className }) => {
  const elements = getMasonryContent(flattenAndValidateArray(children as ReactNode[]));
  const { columnCount, columnWidth, reCalculateColumn } = useColumn(breakpointCols);

  const list = getColumnsWithItems(columnCount, elements);

  useResize(reCalculateColumn);

  return (
    <Wrapper id="masonry-wrapper" className={className}>
      <Columns {...{ list, columnWidth }} />
    </Wrapper>
  );
};

export default Masonry;
