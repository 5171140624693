import { FooterLinksProps } from '@anm/components/footers/TopFooter';
import { HrefLinkProps, NavigationLinkWithNested } from '@anm/components/header';
import getWaveLinks from '@anm/data/links/wave/getWaveLinks';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import isMobile from '@anm/helpers/is/isMobile';
import { Component, ComponentType } from 'react';

import lfConfig from '../config';

import { WithUserProps } from './withUser';

export type Links = {
  footerLinks: FooterLinksProps[];
  navList: NavigationLinkWithNested[];
};

export type WithNavigationProps = WithUserProps & {
  pickSocialLinks(links: SocialLinksKeys[]): HrefLinkProps[];
};

export type SocialLinksKeys =
  | 'socialTwitter'
  | 'socialFacebookDefault'
  | 'socialFacebookGroup'
  | 'socialYoutube'
  | 'socialInstagram'
  | 'socialLinkedin'
  | 'socialPinterest'
  | 'socialTelegram';
type SocialLinks = { [key in SocialLinksKeys]: HrefLinkProps };

const { waveUrl, isProd } = lfConfig;
const socialLinks: SocialLinks = getWaveLinks({ waveUrl, isProd });

const pickSocialLinks = (links: SocialLinksKeys[]) => {
  let filteredLinks: HrefLinkProps[] = [];
  links.forEach(link => (filteredLinks = [...filteredLinks, socialLinks[link]]));

  return filteredLinks;
};

const openIntercom = () => {
  window.Intercom && window.Intercom('show');
};

const getLinks = (isUserLoggedIn: boolean) => ({
  footerLinks: [
    {
      menuTitle: 'company',
      links: [
        {
          title: 'About',
          href: `${waveUrl}about`
        },
        {
          title: 'Contact Us',
          onClick: openIntercom
        },
        {
          title: 'Privacy Policy',
          href: `${waveUrl}privacy`
        },
        {
          title: 'Terms of Use',
          href: `${waveUrl}terms-of-service`
        },
        {
          title: 'Jobs',
          href: 'https://www.notion.so/Wave-video-Jobs-40a951c3496141e7a1506e48426ef478',
          target: '_blank',
          rel: 'noopener'
        },
        {
          title: 'Partner with Us',
          href: `${waveUrl}partner`
        }
      ]
    },
    {
      menuTitle: 'Use cases',
      links: [
        {
          title: 'Facebook Video Cover',
          href: `${waveUrl}facebook-cover-video`
        },
        {
          title: 'Text on Video',
          href: `${waveUrl}text-on-video`
        },
        {
          title: 'Intro and Outro',
          href: `${waveUrl}assets/effects/video-intro-maker`
        },
        {
          title: 'Video in Email',
          href: `${waveUrl}video-email`
        },
        {
          title: 'Combine Video Clips',
          href: `https://wave.video/blog/combine-video-clips/`
        },
        {
          title: 'Add Gifs to Videos',
          href: `https://wave.video/blog/add-gifs-to-videos/`
        }
      ]
    },
    {
      menuTitle: 'solutions',
      links: [
        {
          title: 'Social Media Video Maker',
          href: `${waveUrl}social-media-video-maker`
        },
        {
          title: 'Instagram Video Editor',
          href: `${waveUrl}tools/video-marketing/instagram`
        },
        {
          title: 'Video Ads Maker',
          href: `${waveUrl}ad-maker`
        },
        {
          title: 'Animated Text Generator',
          href: `${waveUrl}animated-text`
        },
        {
          title: 'Online Video Maker',
          href: `${waveUrl}online-video-maker`
        },
        {
          title: 'Video Marketing',
          href: `${waveUrl}video-marketing-platform`
        }
      ]
    },
    {
      menuTitle: 'resources',
      links: [
        {
          title: 'Facebook Video Sizes',
          href: `https://wave.video/blog/facebook-video-specs/`
        },
        {
          title: 'Instagram Video Formats',
          href: `https://wave.video/blog/video-format-for-instagram/`
        },
        {
          title: 'YouTube Video Ideas',
          href: `https://wave.video/blog/youtube-video-ideas/`
        },
        {
          title: 'Free Stock Video List',
          href: `https://wave.video/blog/free-stock-footage/`
        },
        {
          title: 'Royalty Free Music Sites',
          href: `https://wave.video/blog/royalty-free-music/`
        },
        {
          title: 'Animated Text Generators',
          href: `https://wave.video/blog/best-animated-text-generators/`
        },
        {
          title: 'Video Editing Tutorials',
          href: `${waveUrl}tutorial`
        }
      ]
    },
    {
      menuTitle: 'Reads',
      links: [
        {
          title: 'Facebook Video Cover Tutorial',
          href: `https://wave.video/blog/how-to-make-a-facebook-cover-video-in-5-easy-steps/`
        },
        {
          title: 'How to Make a Video Storyboard',
          href: `https://wave.video/blog/what-is-a-storyboard/`
        },
        {
          title: 'Make Your Videos Look Professional',
          href: `https://wave.video/blog/12-simple-tips-for-making-your-videos-look-more-professional/`
        },
        {
          title: 'Instagram Story Video Guide',
          href: `https://wave.video/blog/instagram-story-video/`
        },
        {
          title: 'How to Rotate a Video',
          href: `https://wave.video/blog/4-easy-ways-to-rotate-a-video/`
        },
        {
          title: 'Make YouTube Videos',
          href: `${waveUrl}how-to-make-youtube-videos`
        },
        {
          title: 'Make Instagram Videos',
          href: `${waveUrl}tools/video-marketing/instagram`
        }
      ]
    }
  ],
  navList: [
    isMobile() &&
      hasAuthAccess() && {
        title: 'My projects',
        href: `${waveUrl}videos`
      },
    {
      title: 'Live streaming',
      isNew: true,
      isDropdownWithPreview: true,
      children: [
        {
          links: [
            {
              title: 'Multi-streaming',
              href: `${waveUrl}multi-streaming`
            },
            {
              title: 'Video recorder',
              href: `${waveUrl}live-streaming/online-video-recorder`
            },
            {
              title: 'Webcam test',
              href: `${waveUrl}webcam-test`
            },
            {
              title: 'Co stream (coming soon)'
            }
          ]
        },
        {
          links: [
            {
              title: 'Live streaming software',
              href: `${waveUrl}live-streaming/streaming-software`
            },
            {
              title: 'Streaming overlay maker',
              href: `${waveUrl}live-streaming/live-stream-overlay-maker`
            },
            {
              title: 'Facebook live streaming',
              href: `${waveUrl}live-streaming/facebook-live-streaming-service`
            },
            {
              title: 'YouTube live streaming',
              href: `${waveUrl}live-streaming/youtube-live-streaming-service`
            }
          ]
        }]
    },
    {
      title: 'Templates',
      href: '/video-templates',
      isDropdownWithPreview: true,
      children: [
        {
          title: 'Live streaming',
          href: `${waveUrl}video-templates/live-streaming`,
          links: [
            {
              title: 'Countdown',
              href: `${waveUrl}video-templates/live-streaming-countdown`
            },
            {
              title: 'Lower Third',
              href: `${waveUrl}video-templates/live-streaming-trn-lower-third`
            },
            {
              title: 'Thumbnail',
              href: `${waveUrl}video-templates/live-streaming-thumbnails`
            },
            {
              title: 'Starting Soon Screen',
              href: `${waveUrl}video-templates/live-streaming-starting-soon`
            },
            {
              title: 'Live Stream Intro',
              href: `${waveUrl}video-templates/live-streaming-intro`
            }
          ]
        },
        {
          title: 'Social Media Templates',
          href: `${waveUrl}video-templates/social-media`,
          links: [
            {
              title: 'YouTube Video',
              href: `${waveUrl}video-templates/social-media-youtube`
            },
            {
              title: 'Facebook Video',
              href: `${waveUrl}video-templates/social-media-facebook`
            },
            {
              title: 'Instagram Video',
              href: `${waveUrl}video-templates/social-media-instagram`
            },
            {
              title: 'Facebook Cover Video',
              href: `${waveUrl}video-templates/social-media-facebook-cover`
            },
            {
              title: 'Reels & Stories Video',
              href: `${waveUrl}video-templates/social-media-stories`
            }
          ]
        },
        {
          title: 'Ads & Promo',
          href: `${waveUrl}video-templates/ads-promo`,
          links: [
            {
              title: 'Video Ad Templates',
              href: `${waveUrl}video-templates/ads-promo-ads`
            },
            {
              title: 'Promo Video Templates',
              href: `${waveUrl}video-templates/ads-promo-promo`
            },
            {
              title: 'News Video Templates',
              href: `${waveUrl}video-templates/use-cases-news`
            },
            {
              title: 'Testimonials',
              href: `${waveUrl}video-templates/ads-promo-testimonials`
            },
            {
              title: 'Video Quotes',
              href: `${waveUrl}video-templates/use-cases-quotes`
            }
          ]
        },
        {
          title: 'Trending Templates',
          href: `${waveUrl}video-templates/trending`,
          footerButton: {
            title: 'See all templates',
            href: `${waveUrl}video-templates`
          },
          links: [
            {
              title: 'Collage Videos',
              href: `${waveUrl}video-templates/use-cases-collage`
            },
            {
              title: 'Zoom Virtual Backgrounds',
              href: `${waveUrl}video-templates/use-cases-zoom-backgrounds`
            },
            {
              title: 'Holiday Videos',
              href: `${waveUrl}video-templates/holidays`
            },
            {
              title: 'Frame Videos',
              href: `${waveUrl}video-templates/use-cases-frame`
            },
            {
              title: 'Video Intro & Outro',
              href: `${waveUrl}video-templates/use-cases-intro-outro`
            }
          ]
        }
      ]
    },
    {
      title: 'Creative assets',
      isDropdownWithPreview: true,
      children: [
        {
          title: 'Stock libraries',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}assets/stock`
          },
          iconSrc: 'images/icons/header/stock-libraries_v1.jpg',
          links: [
            {
              title: 'Free stock video',
              href: `${waveUrl}assets/stock/free-videos`
            },
            {
              title: 'Royalty-free music',
              href: `${waveUrl}assets/stock/free-music`
            },
            {
              title: 'Free stock images',
              href: `${waveUrl}assets/stock/free-images`
            }
          ]
        },
        {
          title: 'Visual effects',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}assets/effects`
          },
          iconSrc: 'images/icons/header/visual-effects_v1.jpg',
          links: [
            {
              title: 'Video filters',
              href: `${waveUrl}assets/effects/video-filters`
            },
            {
              title: 'Video overlays',
              href: `${waveUrl}assets/effects/video-overlays`
            },
            {
              title: 'Video transition',
              href: `${waveUrl}assets/effects/video-transitions`
            },
            {
              title: 'Animated text',
              href: `${waveUrl}assets/effects/animated-text`
            }
          ]
        },
        {
          title: 'Graphic elements',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}assets/graphics`
          },
          iconSrc: 'images/icons/header/graphic-elements_v1.jpg',
          links: [
            {
              title: 'Video thumbnail',
              href: `${waveUrl}assets/graphics/thumbnail`
            },
            {
              title: 'Lower third',
              href: `${waveUrl}assets/graphics/lower-third`
            },
            {
              title: 'Video intro',
              href: `${waveUrl}assets/effects/video-intro-maker`
            },
            {
              title: 'Frame video',
              href: `${waveUrl}assets/graphics/frame`
            }
          ]
        }
      ]
    },
    {
      title: 'Tools',
      isArrows: true,
      contentMaxWidth: 966,
      isDropdownWithPreview: true,
      children: [
        {
          title: 'Online video editing',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}tools/video-editing`
          },
          iconSrc: 'images/icons/header/video-editing_v1.jpg',
          links: [
            {
              title: 'Online video maker',
              href: `${waveUrl}online-video-maker`
            },
            {
              title: 'Combine video clips',
              href: `${waveUrl}tools/video-editing/merge-video-clips`
            },
            {
              title: 'Animated text generator',
              href: `${waveUrl}assets/effects/animated-text`
            },
            {
              title: 'Video collage maker',
              href: `${waveUrl}tools/video-editing/video-collage-maker`
            },
            {
              title: 'GIF maker',
              href: `${waveUrl}tools/video-editing/gif-maker`
            }
          ]
        },
        {
          title: 'Audio editing',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}tools/audio-editing`
          },
          iconSrc: 'images/icons/header/speech-audio_v1.jpg',
          links: [
            {
              title: 'Add music to video',
              href: `${waveUrl}tools/audio-editing/add-music-to-video`
            },
            {
              title: 'Automatic Captions',
              href: `${waveUrl}video-captioning`
            },
            {
              title: 'Text to speech',
              href: `${waveUrl}tools/audio-editing/text-to-speech`
            },
            {
              title: 'Video voiceover',
              href: `${waveUrl}tools/audio-editing/voiceover`
            },
            {
              title: 'Subtitler',
              href: `${waveUrl}tools/audio-editing/subtitler`
            }
          ]
        },
        {
          title: 'Video marketing tools',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}tools/video-marketing`
          },
          iconSrc: 'images/icons/header/video-marketing_v1.jpg',
          links: [
            {
              title: 'Convert text to video',
              href: `${waveUrl}tools/video-marketing/turn-text-into-video`
            },
            {
              title: 'Video ad maker',
              href: `${waveUrl}ad-maker`
            },
            {
              title: 'Make videos for Instagram',
              href: `${waveUrl}tools/video-marketing/instagram`
            },
            {
              title: 'Make videos for YouTube',
              href: `${waveUrl}tools/video-marketing/make-youtube-videos`
            },
            {
              title: 'Meme maker',
              href: `${waveUrl}tools/video-editing/meme-maker`
            }
          ]
        },
        {
          title: 'Video hosting',
          footerLink: {
            title: 'See all →',
            href: `${waveUrl}tools/video-hosting`
          },
          iconSrc: 'images/icons/header/hosting_v1.jpg',
          links: [
            {
              title: 'Free video hosting',
              href: `${waveUrl}tools/video-hosting/free-video-hosting`
            },
            {
              title: 'Embed video ',
              href: `${waveUrl}tools/video-hosting/embed-video`
            },
            {
              title: 'Password protect video ',
              href: `${waveUrl}tools/video-hosting/password-protect-video`
            },
            {
              title: 'Branded Video player ',
              href: `${waveUrl}tools/video-hosting/branded-video-player`
            },
            {
              title: 'Send video in e-mail',
              href: `${waveUrl}tools/video-marketing/video-email`
            }
          ]
        }
      ]
    },
    {
      title: 'Resources',
      children: [
        {
          title: 'Video Marketing Blog',
          href: 'https://wave.video/blog/'
        },
        {
          title: 'Live Better show',
          href: 'https://wave.video/blog/live-better-show/'
        },
        {
          title: 'Knowledge Base',
          href: 'https://wave.video/help/',
          target: '_blank'
        },
        {
          title: 'Video Tutorials',
          href: `${waveUrl}tutorial`
        },
        {
          title: 'Facebook Community',
          href: 'https://www.facebook.com/groups/wave.video/',
          target: '_blank'
        },
        {
          title: 'Affiliate Program',
          href: 'https://animatron.tapfiliate.com/',
          target: '_blank'
        },

        {
          title: 'Content Calendar',
          href: isUserLoggedIn ? `${waveUrl}calendar` : `${waveUrl}social-calendar`
        }
      ]
    },
    {
      title: 'Pricing',
      href: `${waveUrl}pricing`
    }
  ].filter(v => v) as NavigationLinkWithNested[]
});

const withNavigation = <P extends object>(WrappedComponent: ComponentType<P & WithNavigationProps>) => {
  class WithNavigation extends Component<P & WithNavigationProps> {
    static displayName = `WithNavigation(${WrappedComponent.name || 'BaseComponent'})`;

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          pickSocialLinks={pickSocialLinks}
          {...{ ...getLinks(this.props.isUserLogged) }}
        />
      );
    }
  }

  return WithNavigation;
};

export default withNavigation;
