import { ApiError } from '@anm/api';
import getAuthHeaders from '@anm/helpers/getAuthHeaders';
import SHA256 from 'crypto-js/sha256';

import playerConfig, { EnvType } from '../../config';
import { Manifest, ManifestSource } from '../../types';

type ApiResponse = SuccessResponse & ApiError;

type SuccessResponse = Manifest;

type GetManifestProps = {
  source: ManifestSource;
  hostId: string;
  env?: EnvType;
  query?: string;
};

const getSourceUrl = ({ hostId, env, source, query }: GetManifestProps) => {
  const queryParam = typeof query !== 'undefined' ? `?hash=${SHA256(query)}` : '';

  switch (source) {
    case 'api':
      return `${playerConfig(env).apiUrl}/host/${hostId}/manifest`;

    case 'cdn':
      return `${playerConfig(env).cdnUrl}/${hostId}/manifest.json${queryParam}`;
  }
};

const getManifest = async ({ env, hostId, query, source = 'cdn' }: GetManifestProps) => {
  const manifestSource = getSourceUrl({
    env,
    query,
    hostId,
    source
  });

  const getHeaders = () => (source !== 'cdn' ? getAuthHeaders() : {});

  return new Promise(async (resolve, reject) => {
    const result = await fetch(manifestSource, getHeaders());
    const res = (await result.json()) as ApiResponse;

    res.main ? resolve(res) : reject(res);
  });
};

export default getManifest;
