import { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import LandingButton, { LandingButtonProps } from '../../LandingButton';
import { SectionTitleColor, SectionTitleProps } from '../../SectionTitle';

import Wrapper, { ButtonWrapper, ContainerWrapper, UltraTitleText } from './Wrapper';

export type TitleVariant = 'medium' | 'big';

export type UltraTitleProps = CommonLandingProps &
  Omit<SectionTitleProps, 'color'> & {
    color?: SectionTitleColor;
    titleVariant?: TitleVariant;
    button?: Partial<LandingButtonProps>;
    bottomMargin?: number;
    containerSize?: number;
    descriptionMaxWidth?: number;
    customAuthButtonGuestText?: string;
  };

const UltraTitle: FC<UltraTitleProps> = ({
  title,
  headingLevel,
  titleVariant = 'medium',
  containerSize,
  descriptionMaxWidth,
  customAuthButtonGuestText,
  bottomMargin,
  button,
  ...titleProps
}) => (
  <Wrapper {...{ bottomMargin }}>
    <ContainerWrapper size={containerSize || 1150}>
      <UltraTitleText
        color="black"
        level={headingLevel}
        title={title}
        {...{ ...titleProps, titleVariant, descriptionMaxWidth }}
      />
      <LandingButton size="upper-big" variant="orange" {...{ ...button, customAuthButtonGuestText }}>
        {landingButton => <ButtonWrapper>{landingButton} </ButtonWrapper>}
      </LandingButton>
    </ContainerWrapper>
  </Wrapper>
);

export default UltraTitle;
