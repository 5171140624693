import TitleBlock, { TitleDescription } from '@anm/components/TitleBlock';
import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import Image from '@anm/components/image/Image';
import { commonInputStyles, flexCenter, flexColumn, flexRow } from '@anm/styles/helpers';
import styled from 'styled-components';

export const MainTitle = styled(TitleBlock)`
  margin-bottom: 14px;
  h1,
  h2,
  h3,
  h4 {
    font-size: 32px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 11px;
  }
  ${TitleDescription} {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`;

export const Content = styled.div`
  ${flexColumn};
  ${flexCenter};
  padding: 41px 40px 41px;
`;

export const TextArea = styled.textarea<{ isUseTextIs: boolean; isFocused?: boolean }>`
  width: 100%;
  resize: none;
  overflow: auto;
  color: #292a2b;
  font-size: 16px;
  line-height: 24px;
  background: transparent;
  padding: 9px 10px 10px 11px;
  height: ${({ isUseTextIs }) => (isUseTextIs ? 323 : 188)}px;
  ${commonInputStyles};
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: -3.5px;
`;

export const Input = styled.input<{ isFocused?: boolean }>`
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding: 0 11px;
  margin-top: 12px;
  line-height: 21px;
  ${commonInputStyles};
`;

export const WrappedButtonLoader = styled(ButtonLoader)`
  height: 50px;
  min-width: 130px;
`;

export const Wrapper = styled.div`
  width: 600px;
  background: #fff;
  border-radius: 10px;
  pointer-events: all;
  margin: 5% 0;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const ChecklistRow = styled.div`
  ${flexRow};
  margin-bottom: 25px;
  justify-content: flex-start;
`;

export const WrapperImage = styled(Image)`
  margin-right: 13.5px;
  width: 17px;
  height: 17px;
`;

export const CheckListContent = styled.div`
  padding: 30px 20px;
  ${ChecklistRow}:last-child {
    margin: 0;
  }
`;

export const TitleInput = styled(Input)`
  margin: 15px 0 14px;
`;

export const Description = styled.span`
  margin-top: 10px;
  line-height: 24px;
`;
