import InfoBlock from '@anm/components/InfoBlock';
import InlineSelection from '@anm/components/list/InlineSelection';
import CardModal from '@anm/components/modals/CardModal';
import useSwitchState from '@anm/hooks/useSwitchState';
import { FC } from 'react';

import {
  Content,
  Description,
  MainTitle,
  TextArea,
  TextAreaWrapper,
  TitleInput,
  WrappedButtonLoader,
  Wrapper
} from '../Wrapper';
import ModalSettings from '../components/ModalSettings';
import TextFormatingHint from '../components/TextFormatingHint';
import { GenerateVideoModalProps } from '../types';

import CheckList from './CheckList';
import isValidText from './helpers/isValidText';
import { useCreateVideoFromTextHandlers } from './hooks';

const GenerateVideoFromTextModal: FC<GenerateVideoModalProps> = ({
  timeList,
  isPending,
  onModalClose,
  onCreateVideo
}) => {
  const [isTitleFocused, onTitleFocus, onTitleBlur] = useSwitchState();
  const [isTextFocused, onTextFocus, onTextBlur] = useSwitchState();

  const [
    text,
    title,
    textValue,
    isUseTextIs,
    activeSummarizeText,
    activeTextSceneButton,
    activeStockAssetsButton,
    setTime,
    setMood,
    handleTextChange,
    handleTitleChange,
    handleTextSceneChange,
    setActiveSummarizeText,
    handleCreateVideoFromText,
    setActiveStockAssetsButton
  ] = useCreateVideoFromTextHandlers(onCreateVideo);

  const isInvalidText = isUseTextIs ? !textValue : !isValidText(textValue);

  return (
    <CardModal
      {...{ onModalClose }}
      cardSettings={{
        isPadding: false,
        borderRadius: 10,
        variant: 'transparent'
      }}
    >
      <Wrapper>
        <Content>
          <MainTitle level={2} title="Generate video from text" />
          <InlineSelection
            activeItem={activeSummarizeText}
            onChange={setActiveSummarizeText}
            list={['Use my text as is', 'Reduce my text with AI']}
          />
          {!isUseTextIs && (
            <Description>
              Let the artificial intelligence analyze the text to pick its best bits and compile the video.
            </Description>
          )}
          <TitleInput
            value={title!}
            isFocused={isTitleFocused}
            onBlur={onTitleBlur}
            onFocus={onTitleFocus}
            onChange={handleTitleChange}
            placeholder="Type your title here. Optional. Up to 60 characters work best. "
          />
          <TextAreaWrapper>
            <InfoBlock
              canShow={isInvalidText && !isUseTextIs}
              direction="to-left-bottom"
              renderContent={() => <CheckList text={text!} />}
            >
              <TextArea
                value={text!}
                maxLength={50000}
                onBlur={onTextBlur}
                onFocus={onTextFocus}
                isFocused={isTextFocused}
                onChange={handleTextChange}
                {...{ isUseTextIs }}
                placeholder="Paste your text here. 50,000 character max"
              />
            </InfoBlock>
            {isUseTextIs && <TextFormatingHint />}
          </TextAreaWrapper>

          <ModalSettings
            isVideoDurationAndTextScene={!isUseTextIs}
            {...{
              setMood,
              setTime,
              timeList,
              activeTextSceneButton,
              handleTextSceneChange,
              activeStockAssetsButton,
              setActiveStockAssetsButton
            }}
          />

          <WrappedButtonLoader
            size="large"
            {...{ isPending }}
            disabled={isInvalidText}
            onClick={handleCreateVideoFromText}
            variant="light-blue-lowercase"
          >
            Next
          </WrappedButtonLoader>
        </Content>
      </Wrapper>
    </CardModal>
  );
};

export default GenerateVideoFromTextModal;
