import { useCallback, useState, ChangeEvent } from 'react';

const useTextAreaOrInputState = (initialValue: string = '') => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(event.target.value);
  }, []);

  const resetText = useCallback(() => {
    setValue('');
  }, []);

  const handleSetText = useCallback((val: string) => {
    setValue(val);
  }, []);

  return [value, handleChange, resetText, handleSetText] as const;
};

export default useTextAreaOrInputState;
