import { ApiConfig } from '@anm/api';
import createApiInstance from '@anm/api/helpers/createApiInstance';
import presetAxiosInstance from '@anm/api/helpers/presetAxiosInstance';
import * as auth from '@anm/api/modules/auth';
import { getAuthToken, setAuthToken } from '@anm/auth/helpers/authToken';

import { setAnon } from '../authAnon';

const genApi = (apiConfig: ApiConfig) => {
  const { bind } = presetAxiosInstance(apiConfig);
  return { auth: bind(auth) };
};

const api = createApiInstance(genApi);

const signupAnon = async () => {
  const token = getAuthToken();
  if (token) return;

  try {
    const user = await api().auth.signupAnon();
    if (!user.encryptedToken) return null;

    setAuthToken(user.encryptedToken);
    setAnon(true);

    return user;
  } catch (err) {
    return Promise.reject(err);
  }
};

export default signupAnon;
