import { Request } from '../../types';

import { VideoFormat } from './types';

export * from './types';

export const getVideoFormats = (request: Request) => () =>
  request<VideoFormat[]>({
    method: 'GET',
    url: 'project/formats'
  });
