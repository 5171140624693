import { flexCenter, media } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle from '../../SectionTitle';
import Button from '@anm/components/buttons/Button/Button';

const titleOnMobile = media.phone`
  font-size: 28px;
  max-width: 300px;
  line-height: 40px;
  margin: 0 auto 20px;
`;

export const Title = styled(SectionTitle)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 40px;
    line-height: 54px;
    margin-bottom: 35px;
    ${titleOnMobile};
  }
`;

const Wrapper = styled.section`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
  ${Button} {
    a {
      ${flexCenter};
      padding: 0 30px;
      font-size: 20px;
      color: #fff !important;
    }
  }
`;

export default Wrapper;
