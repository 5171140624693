import noop from '@anm/helpers/noop';
import { useCallback, useEffect, FC, MouseEvent } from 'react';

import Backdrop from './Backdrop';
import Content from './Content';
import Overlay from './Overlay';
import ModalPortal from './Portal';
import { EscOnBlockWrapper } from './Wrapper';

export { ModalPortal };

export type ModalProps = {
  onModalClose?: () => void;
  contentPadding?: string;
  isModalTopAligned?: boolean;
  isClickableBackdrop?: boolean;
};
export type ModalPropsWithoutDefault = ModalProps;

export let isModalOpen = false;

const Modal: FC<ModalProps> = ({
  children,
  onModalClose = noop,
  contentPadding,
  isModalTopAligned = false,
  isClickableBackdrop = true
}) => {
  useEffect(() => {
    isModalOpen = true;

    return () => {
      isModalOpen = false;
    };
  }, []);

  const handleModalClose = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onModalClose();
    },
    [onModalClose]
  );

  return (
    <ModalPortal>
      <EscOnBlockWrapper onEsc={onModalClose}>
        <Backdrop onClick={isClickableBackdrop ? handleModalClose : noop} />
        <Overlay onModalClose={isClickableBackdrop ? onModalClose : noop}>
          <Content {...{ isModalTopAligned, contentPadding }}>{children}</Content>
        </Overlay>
      </EscOnBlockWrapper>
    </ModalPortal>
  );
};

export default Modal;
