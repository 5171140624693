import { Request } from '../../types';

import {
  AddLogoParams,
  Brand,
  BrandFile,
  BrandLogo,
  CreateBrandFileRequest,
  DeleteBrandFileRequest,
  DeleteBrandRequest,
  DeleteLogoParams,
  GetBrandFilesRequest,
  GetBrandRequest,
  UpdateBrandRequest,
  ReorderBrandFileRequest,
  UpdateBrandColorProps,
  UpdateBrandMetaProps,
  UploadBrandLogoParams
} from './types';

export * from './types';

export const fetchBrands = (request: Request) => (baseURL?: string) =>
  request<Brand[]>({
    method: 'GET',
    url: `/branding`,
    ...(baseURL && { baseURL })
  });

export const uploadBrandLogo = (request: Request) => ({ token, brandId, file, baseURL }: UploadBrandLogoParams) => {
  const data = new FormData();
  data.append('file', file);

  return request<Brand>({
    data,
    method: 'POST',
    url: `/branding/${brandId}/logo/${token}`,
    ...(baseURL && { baseURL })
  });
};

export const deleteBrandLogo = (request: Request) => ({ logoId, baseURL, brandId }: DeleteLogoParams) => {
  return request<Brand>({
    method: 'DELETE',
    url: `/branding/${brandId}/logo/${logoId}`,
    ...(baseURL && { baseURL })
  });
};

export const addBrandLogo = (request: Request) => ({ baseURL, brandId, logo }: AddLogoParams) => {
  return request<Brand>({
    method: 'POST',
    data: logo,
    url: `/branding/${brandId}/logo`,
    ...(baseURL && { baseURL })
  });
};

export const updateBrandMeta = (request: Request) => ({ data, baseURL, brandId }: UpdateBrandMetaProps) => {
  return request<Brand>({
    url: `/branding/${brandId}/meta`,
    data,
    method: 'PUT',
    ...(baseURL && { baseURL })
  });
};

export const updateBrandColors = (request: Request) => ({ colors, baseURL, brandId }: UpdateBrandColorProps) => {
  return request<Brand>({
    url: `/branding/${brandId}/colors`,
    data: colors,
    method: 'PUT',
    ...(baseURL && { baseURL })
  });
};

export const getBrandFiles = (request: Request) => ({ brandId, baseURL }: GetBrandFilesRequest) => {
  return request<BrandFile[]>({
    url: `/branding/${brandId}/files`,
    method: 'GET',
    ...(baseURL && { baseURL })
  });
};

export const getSystemFiles = (request: Request) => ({ baseURL }: { baseURL?: string }) => {
  return request<BrandFile[]>({
    url: `/branding/systemfiles`,
    method: 'GET',
    ...(baseURL && { baseURL })
  });
};

export const getSystemLogos = (request: Request) => ({ baseURL }: { baseURL?: string }) => {
  return request<BrandLogo[]>({
    url: `branding/systemlogos`,
    method: 'GET',
    ...(baseURL && { baseURL })
  });
};

export const createBrandFile = (request: Request) => ({ brandId, baseURL, data }: CreateBrandFileRequest) => {
  return request<BrandFile>({
    url: `/branding/${brandId}/files`,
    data,
    method: 'POST',
    ...(baseURL && { baseURL })
  });
};

export const updateBrandFile = (request: Request) => ({ brandId, baseURL, data }: CreateBrandFileRequest) => {
  return request<BrandFile>({
    url: `/branding/${brandId}/files`,
    data,
    method: 'PUT',
    ...(baseURL && { baseURL })
  });
};

export const reorderBrandFile = (request: Request) => ({ brandId, baseURL, id, to }: ReorderBrandFileRequest) => {
  return request<void>({
    url: `/branding/${brandId}/files/${id}`,
    method: 'PUT',
    params: { to },
    ...(baseURL && { baseURL })
  });
};

export const deleteBrandFile = (request: Request) => ({ brandId, baseURL, fileId }: DeleteBrandFileRequest) => {
  return request<BrandFile>({
    url: `/branding/${brandId}/files/${fileId}`,
    method: 'DELETE',
    ...(baseURL && { baseURL })
  });
};

export const getBrand = (request: Request) => ({ brandId, baseURL }: GetBrandRequest) => {
  return request<Brand>({
    url: `/branding/${brandId}`,
    method: 'GET',
    ...(baseURL && { baseURL })
  });
};

export const deleteBrand = (request: Request) => ({ brandId, baseURL }: DeleteBrandRequest) => {
  return request<void>({
    url: `/branding/${brandId}`,
    method: 'DELETE',
    ...(baseURL && { baseURL })
  });
};

export const updateBrand = (request: Request) => ({ brand, baseURL }: UpdateBrandRequest) => {
  return request<Brand>({
    url: `/branding`,
    method: 'PUT',
    data: brand,
    ...(baseURL && { baseURL })
  });
};

export const createBrand = (request: Request) => ({ brand, baseURL }: UpdateBrandRequest) => {
  return request<Brand>({
    url: `/branding`,
    method: 'POST',
    data: brand,
    ...(baseURL && { baseURL })
  });
};
