import {
  centerOriented,
  leftCenterOriented,
  leftCenterOrientedHeight,
  leftCenterOrientedWithArrowHeight,
  leftOriented,
  lowTunnelBottomDropdownStyles,
  rightCenterOriented
} from '@anm/components/Dropdown/helpers';
import getFromCdn from '@anm/helpers/getFromCdn';
import { flexCenter, media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import DropdownContent from './Content';
import { DropdownDirections, DropdownOpenerVariants, DropdownProps } from './index';

type DropDownWrapperProps = {
  isOpened: boolean;
  isHovered: boolean;
  closeDelay: number;
  isDisabledButton: boolean;
  direction?: DropdownDirections;
  isOpenActionByHover?: boolean;
  openerVariant?: DropdownOpenerVariants;
  dropdownContentWidth?: number;
};

const dropdownContentDirections: {
  [key in DropdownDirections]: ReturnType<typeof css>;
} = {
  'to-top': css`
    ${leftCenterOriented};
    bottom: 0;
    top: auto;
    transform: none;
    border-radius: 0 0 0 100%;
    height: ${leftCenterOrientedWithArrowHeight};
  `,
  'to-top-low-tunnel': css`
    ${leftCenterOriented};
    bottom: auto;
    top: -10px;
    transform: none;
    border-radius: 0 0 0 100%;
    height: 50%;
  `,
  'to-top-center-with-arrow': css`
    ${centerOriented};
  `,
  'to-top-center': css`
    ${centerOriented};
    bottom: 100%;
  `,
  'to-bottom': css`
    ${centerOriented};
    top: 60%;
    bottom: auto;
    border-radius: 100% 100% 0 0;
    height: ${leftCenterOrientedWithArrowHeight};
  `,
  'to-bottom-left-with-arrow': css`
    ${leftCenterOriented};
    top: 40%;
    bottom: auto;
    transform: none;
    border-radius: 100% 0 0 0;
    height: ${leftCenterOrientedWithArrowHeight};
  `,
  'to-bottom-right': css`
    ${leftCenterOriented};
    top: 0;
    bottom: auto;
    transform: none;
    border-radius: 100% 0 0 0;
    height: ${leftCenterOrientedHeight};
  `,
  'to-bottom-left-low-tunnel': css`
    ${leftCenterOriented};
    transform: none;
    border-radius: 100% 0 0 0;
    ${lowTunnelBottomDropdownStyles};
  `,
  'to-bottom-right-low-tunnel': css`
    ${rightCenterOriented};
    transform: none;
    border-radius: 0 100% 0 0;
    ${lowTunnelBottomDropdownStyles};
  `,
  'to-bottom-left': css`
    ${rightCenterOriented};
    top: 0;
    bottom: auto;
    transform: none;
    border-radius: 0 100% 0 0;
    height: ${leftCenterOrientedHeight};
  `,
  'to-bottom-no-arrow-static': css`
    ${leftCenterOriented}
  `,
  'to-bottom-from-left-corner': css``,
  'to-bottom-from-right-corner': css``,
  'to-left': css`
    ${leftCenterOriented};
  `,
  'to-right': css``,
  'to-left-no-arrow': css`
    ${leftOriented}
  `
};

const commonTunnelStyles = css<DropdownProps>`
  position: relative;
  &:before {
    content: '';
    display: none;
    position: absolute;
    bottom: 0;
    width: ${({ dropdownContentWidth }) => dropdownContentWidth}px;
    height: 15px;
    transform: translateY(100%);
    ${({ direction }) => [dropdownContentDirections[direction]]};
  }
`;

const commonOpenerStyles = css<DropdownProps>`
  ${flexCenter};
  height: 100%;
  align-items: center;
  &:after {
    width: 10px;
    height: 6px;
    content: '';
    margin-left: 8px;
    cursor: pointer;
  }
  ${commonTunnelStyles};
`;

const mobileBackdropStyles = css`
  background: rgba(41, 42, 43, 0.5);
  z-index: 133;
  top: 0;
  left: 0;
`;

const dotsMobileActiveStyles = media.phone`
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(0);
    border-radius: 5px;
    background: rgba(41, 42, 43, 0.5);
    z-index: 132;
  }
`;

export const MobileBackdrop = styled.div`
  ${mobileBackdropStyles};
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: auto;
  transform: translate(0);
  display: none;
`;

const activeBlackArrowStyles = css`
  &:after {
    transform: rotate(180deg);
    filter: invert(48%) sepia(36%) saturate(876%) hue-rotate(146deg) brightness(110%) contrast(98%);
  }
`;

const blackArrowClickStyles = css<DropDownWrapperProps>`
  ${({ isOpened }) => isOpened && activeBlackArrowStyles}
`;

const blackArrowHoverStyles = css`
  &:hover {
    ${activeBlackArrowStyles}
  }
`;

const blackArrowActiveStyles = css`
  ${({ isOpenActionByHover }: DropDownWrapperProps) =>
    isOpenActionByHover ? blackArrowHoverStyles : blackArrowClickStyles}
`;

const getOpenerStyles = ({ openerVariant = 'light-blue' }: DropDownWrapperProps) =>
  ({
    'no-icon': css`
      ${commonOpenerStyles};
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      cursor: pointer;
    `,
    black: css`
      ${commonOpenerStyles};
      &:after {
        background: url(${getFromCdn('images/dropdown_arrow_black.svg')}) no-repeat center transparent;
        transition: 0.3s;
        filter: ${({ theme }) => theme.blackDropdownArrowColorFilter};
      }
      ${blackArrowActiveStyles};
    `,
    'light-blue': css`
      ${commonOpenerStyles};
      &:after {
        background: url(${getFromCdn('images/dropdown_arrow_lb.svg')}) no-repeat center transparent;
      }
      &:hover {
        > a {
          color: #aff5fe;
        }
        &:after {
          transform: rotate(180deg);
        }
      }
    `,
    blue: css`
      ${commonOpenerStyles};
      &:after {
        transition: 0.3s;
        background: url(${getFromCdn('images/dropdown_arrow_black.svg')}) no-repeat center transparent;
      }
      &:hover {
        > a {
          color: #2294df;
        }
        &:after {
          transform: rotate(180deg);
          background-image: url(${getFromCdn('images/dropdown_arrow_studio_blue.svg')});
        }
      }
    `,
    dots: css`
      ${commonOpenerStyles};
      width: 40px;
      height: 40px;
      border-radius: 5px;
      flex-shrink: 0;
      cursor: pointer;
      ${DropdownContent} {
        transition: none;
      }
      &:after {
        background: url(${getFromCdn('images/dashboard/dots.svg')}) no-repeat center transparent;
        width: 20px;
        height: 4px;
        margin: 0;
      }
      &:hover {
        background: rgba(32, 171, 210, 0.2);
        & + ${MobileBackdrop} {
          display: block;
        }
        ${dotsMobileActiveStyles};
      }
    `,
    transparent_square: css`
      position: relative;
      width: 40px;
      height: 40px;
      background: transparent;
    `,
    absolute: css`
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      cursor: pointer;
      &:after {
        content: '';
        width: 20px;
        height: 20px;
        margin: auto;
        background: url(${getFromCdn('images/dropdown_arrow.svg')}) no-repeat center transparent;
        position: absolute;
        z-index: 1;
        right: 30px;
        top: 0;
        bottom: 0;
      }
      &:hover {
        &:after {
          transform: rotate(180deg);
        }
      }
    `,
    blue_square: css`
      ${commonOpenerStyles};
      height: 50px;
      width: 50px;
      border-radius: 0 5px 5px 0;
      margin-left: 2px;
      background-color: var(--blue_100);
      transition: background-color 0.3s ease-in-out;
      ${DropdownContent} {
        width: 236px;
      }
      &:after {
        width: 100%;
        height: 20px;
        margin: 0;
        background: url(${getFromCdn('images/dropdown_arrow.svg')}) no-repeat center transparent;
      }
      &:hover {
        background-color: #008cb3;
        &:after {
          transform: rotate(180deg);
        }
      }
    `,
    default: css`
      ${commonTunnelStyles};
    `
  }[openerVariant]);

const hoverStyles = css<DropDownWrapperProps>`
  & ${DropdownContent} {
    opacity: ${({ isOpened }) => (isOpened ? 1 : 0)};
    z-index: 134;
    max-height: 100vh;
    max-width: fit-content;
    transition: opacity 200ms ease-in-out;
    transition-delay: ${({ closeDelay }) => closeDelay}ms;
    pointer-events: ${({ isOpened }) => (isOpened ? 'all' : 'none')};
  }
  &:hover {
    z-index: 135;
    & ${DropdownContent} {
      transition: all 200ms ease-in-out;
    }
    &:before {
      display: block;
    }
  }
`;

const clickStyles = css<DropDownWrapperProps>`
  & ${DropdownContent} {
    pointer-events: ${({ isOpened }) => (isOpened ? 'all' : 'none')};
  }
`;

const DropdownWrapper = styled.div<DropDownWrapperProps>`
  ${getOpenerStyles};
  pointer-events: ${({ isDisabledButton }) => (isDisabledButton ? 'none' : 'all')};
  ${({ isOpenActionByHover }) => (isOpenActionByHover ? hoverStyles : clickStyles)};
`;

export default DropdownWrapper;
