import { FC } from 'react';
import styled from 'styled-components';

import { ButtonLinkProps } from '.';
import Button from './Button';

const LinkWrapper = styled<FC<ButtonLinkProps>>(Button.withComponent('a'))`
  text-decoration: none;
`;

const Link: FC<ButtonLinkProps> = ({ newWindow, children, ...restProps }) => (
  <LinkWrapper target={newWindow ? '_blank' : '_self'} {...restProps}>
    {children}
  </LinkWrapper>
);

export default Link;
