import UserProvider, { UserContext } from '@anm/components/user/UserProvider';
import * as sentry from '@anm/helpers/sentry/client';
import { NextPageContext } from 'next';
import React, { useContext, FC } from 'react';
import Loadable from 'react-loadable';
import { compose } from 'recompose';

import withAnalyticSupport from '../HOCs/withAnalyticSupport';
import withSupport from '../HOCs/withSupport';
import IntroduceVideo from '../components/IntroduceVideo';
import Layout from '../components/Layout';
import MetaHead from '../components/MetaHead';
import LandingFramework from '../components/landing/LandingFramework';
import lfConfig from '../config';
import { LandingData } from '../features/getLanding';
import getVideoData, { VideoData } from '../features/getVideoData';
import initAffiliate from '../helpers/initAffiliate';
import { EnvProvider } from '../store/EnvContext';
import { LandingDataProvider } from '../store/LandingDataContext';
import { VideoDataProvider } from '../store/VideoDataContext';

type IndexProps = {
  path: string;
  videoData: VideoData;
  currentLanding: LandingData;
};
export type IndexQuery = {
  landing: string;
};

const AffiliateDiscountModal = Loadable({
  loader: () => import('../components/AffiliateDiscountModal'),
  loading: () => null
});

const HooksContainer: FC = () => {
  const { user } = useContext(UserContext);
  sentry.setUser(user);

  return null;
};

const Index = ({ currentLanding, videoData, path }: IndexProps) => {
  const {
    title,
    footer,
    preview,
    noindex,
    isSimple,
    canonical,
    description,
    topBanner,
    facebookPixelIds
  } = currentLanding;

  initAffiliate();

  return (
    <UserProvider apiUrl={lfConfig.apiUrl}>
      <LandingDataProvider value={currentLanding}>
        <IntroduceVideo />
        <AffiliateDiscountModal />
        <HooksContainer />
        <MetaHead
          {...{
            path,
            title,
            noindex,
            preview,
            description,
            canonical,
            facebookPixelIds
          }}
        />
        {isSimple ? (
          <LandingFramework landing={currentLanding} />
        ) : (
          <Layout {...{ footer, topBanner }}>
            <VideoDataProvider value={videoData}>
              <EnvProvider value={{ env: 'prod' }}>
                <LandingFramework landing={currentLanding} />
              </EnvProvider>
            </VideoDataProvider>
          </Layout>
        )}
      </LandingDataProvider>
    </UserProvider>
  );
};

Index.getInitialProps = async ({ query, asPath }: NextPageContext) => {
  const { getLandingByUrl } = await import('../features/getLanding');
  const currentLanding = getLandingByUrl((query.landing as string) || '');

  const videoData = currentLanding && (await getVideoData(currentLanding));

  const path = asPath && asPath.replace('/landings', '').replace(/^\//, '');
  const url = `${lfConfig.waveUrl}${path}`;

  return { currentLanding, videoData, path: url };
};

export default compose(withSupport, withAnalyticSupport)(Index as any);
