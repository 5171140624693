import isUnitTesting from '@anm/helpers/testing/isUnitTesting';

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

export let audioContext: AudioContext;

const getAudioContext = () => {
  if (!audioContext && !isUnitTesting()) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)();
  }

  if (audioContext) {
    if (audioContext.state !== 'running') {
      return audioContext.resume().then(_ => audioContext);
    }
    return Promise.resolve(audioContext);
  }
  return Promise.reject('audio context reject');
};

export default getAudioContext;
