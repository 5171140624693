import { Request } from '../../types';

import { AudioDisputeParams, FetchStockParams, StockResponse } from './types';

export * from './types';

export const fetchVideoStocks = (request: Request) => ({ searchName, ...params }: FetchStockParams) =>
  request<StockResponse>({
    params,
    method: 'GET',
    url: `/stock/video/search/${searchName}`
  });

export const fetchImageStocks = (request: Request) => ({ searchName, ...params }: FetchStockParams) =>
  request<StockResponse>({
    params,
    method: 'GET',
    url: `/stock/image/search/${searchName}`
  });

export const fetchAudioStocks = (request: Request) => ({ searchName, ...params }: FetchStockParams) =>
  request<StockResponse>({
    params,
    method: 'GET',
    url: `/stock/audio/search/${searchName}`
  });

export const submitBlocksAudioDispute = (request: Request) => (params: AudioDisputeParams) =>
  request<void>({
    params,
    method: 'POST',
    url: '/stock/audio/blocks-dispute'
  });

export const checkYoutubeChannelId = (request: Request) => (youtubeChannelId: string) =>
  request<string>({
    method: 'POST',
    url: '/stock/audio/blocks-whitelist-youtube',
    params: {
      channelId: youtubeChannelId
    }
  });
