import withDefaultProps from '@anm/HOCs/withDefaultProps';
import noop from '@anm/helpers/noop';
import React, { FC } from 'react';

import VideoWrapper from './Wrapper';
import { useVideoPlayer } from './hooks';

export { VideoWrapper };

// TODO Roma please fix any types
export type Player = {
  pause: () => void;
  poster: any;
  elements: any;
};

export type VideoPlayerOptions = {
  autoPlay?: boolean;
  preload?: 'auto' | 'metadata' | 'none';
  muted?: boolean;
  loop?: boolean;
  controls?: boolean;
};

export type VideoPlayerProps = VideoEvents & {
  src: string;
  poster: string;
  options?: VideoPlayerOptions;
} & DefaultProps;

export type VideoEvents = {
  onVideoPlay?: () => void;
  onVideoLoaded?: () => void;
  onVideoLoadStart?: () => void;
};

export type DefaultProps = Readonly<typeof defaultProps> & {
  playerRef: (player: Player) => void;
};

const defaultProps = {
  videoRef: React.createRef<HTMLVideoElement>(),
  playerRef: noop
};

const VideoPlayer: FC<VideoPlayerProps> = ({
  onVideoPlay,
  onVideoLoadStart,
  onVideoLoaded,
  videoRef,
  playerRef,
  src,
  poster,
  options
}) => {
  useVideoPlayer({
    videoRef,
    playerRef
  });

  return (
    <VideoWrapper>
      <video
        id="player"
        src={src}
        ref={videoRef}
        width="100%"
        height="100%"
        poster={poster}
        onPlay={onVideoPlay}
        onLoadStart={onVideoLoadStart}
        onLoadedData={onVideoLoaded}
        {...options}
      />
    </VideoWrapper>
  );
};

export default withDefaultProps(defaultProps)(VideoPlayer);
