import Dropdown from '@anm/components/Dropdown';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import Wrapper, { HintDropdownContent, HintText, Info } from './Wrapper';

const CONTENT = [
  {
    title: '<b>Short</b> – up to 100 symbols. Dynamic, focused, perfect for news and facts.',
    imgSrc: 'images/storyboard/short_scene.png'
  },
  {
    title: '<b>Medium</b> – up to 200 symbols. The scene lasts longer, best for expanded sentences.',
    imgSrc: 'images/storyboard/medium_scene.png'
  },
  {
    title: '<b>Long</b> – up to 300 symbols. Fewer visuals, more text, precisely describing your idea.',
    imgSrc: 'images/storyboard/long_scene.png'
  }
];

const HintContent: FC = () => (
  <HintDropdownContent>
    {CONTENT.map(({ title, imgSrc }) => (
      <div key={title}>
        <Image src={imgSrc} alt={title} />
        <HintText dangerouslySetInnerHTML={{ __html: title }} />
      </div>
    ))}
  </HintDropdownContent>
);

const TextSplittingHint: FC = () => (
  <Wrapper>
    <Dropdown openByAction="click" openerVariant="default" direction="to-right" renderContent={() => <HintContent />}>
      <div>
        <Info></Info>
      </div>
    </Dropdown>
  </Wrapper>
);

export default TextSplittingHint;
