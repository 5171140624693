import { DropdownContent } from '@anm/components/Dropdown';
import IconButton from '@anm/components/buttons/IconButton';
import { flexRow } from '@anm/styles/helpers';
import { flexColumn, media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';

export const ActionListHeading = styled.span`
  flex: 1;
  display: flex;
  color: #292a2b;
  font-size: 16px;
  line-height: 36px;
  font-weight: bold;
  margin-left: 6px;
`;

const headerOnMobile = media.phone`
  ${ActionListHeading}{
    margin-left: 16px;
  }
`;

export const ActionListHeadingWrapper = styled.div`
  ${flexColumn};

  ${ActionListHeading}:last-of-type:not(:first-of-type) {
    margin-left: 20px;
  }
  ${headerOnMobile};
`;

export const HeaderActions = styled.div`
  ul {
    ${flexRow};
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const HeaderTitles = styled.div`
  ${flexRow};
  margin-top: 2px;
`;

export const CreateVideoButton = styled(IconButton)`
  width: 100%;
  max-width: 160px;
`;

const wrapperNewVideoMobileOrTablet = css`
  div + ul {
    max-height: unset;
  }
  && li {
    width: 100%;
    margin: 0;
  }
`;

export const WrapperNewVideo = styled.div`
  position: relative;
  margin-bottom: 10px;
  ${DropdownContent} {
    min-width: 470px;
    cursor: default;
    padding: 23px 19px 21px 19px;
  }
  div + ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 7px;
    max-height: 150px;
    justify-content: space-between;
  }
  && li {
    min-height: 52px;
    line-height: 52px;
    margin-bottom: 2px;
    position: relative;
    border-radius: 5px;
    display: inline-flex;
    background-position: 6px;
    width: calc(50% - 6px);

    a,
    > span,
    label > span,
    button {
      height: 100%;
      line-height: 52px;
      padding-bottom: 1px;
      padding-left: 62px;
      &:hover {
        color: var(--blue_100);
        border-radius: 5px;
      }
    }
    a > span {
      &:hover {
        color: var(--blue_100);
        border-radius: 5px;
      }
      line-height: 52px;
      padding-left: 0;
    }
  }
  ${isMobileOrIPad() && wrapperNewVideoMobileOrTablet};
`;
