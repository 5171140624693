import { Request } from '../../types';

import {
  CreateFolderParams,
  DeleteFolderParams,
  Folder,
  MoveFolderParams,
  RenameFolderParams
} from './types';

export * from './types';

const defaultParams = {
  product: 'WAVE'
};

const extendParams = <T extends {}>(params: T = {} as T) => ({
  ...defaultParams,
  ...params
});

export const create = (request: Request) => (params: CreateFolderParams) =>
  request<Folder>({
    params: extendParams(params),
    method: 'POST',
    url: 'folder'
  });

export const getById = (request: Request) => (folderId: number) =>
  request<Folder>({
    params: extendParams({ folderId }),
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0 '
    },
    method: 'GET',
    url: 'folder/fetch'
  });

export const getRoot = (request: Request) => (username: string) =>
  request<Folder>({
    params: defaultParams,
    method: 'GET',
    url: `folder/users/${encodeURIComponent(username)}`
  });

export const getByPath = (request: Request) => (path: string) =>
  request<Folder>({
    params: extendParams({ path: `/${path || ''}` }),
    method: 'GET',
    url: 'folder/fetch'
  });

export const getTree = (request: Request) => (username: string) =>
  request<Folder>({
    params: defaultParams,
    method: 'GET',
    url: `folder/users/${encodeURIComponent(username)}/tree`
  });

export const move = (request: Request) => (params: MoveFolderParams) =>
  request<Folder>({
    params: extendParams(params),
    method: 'PUT',
    url: 'folder/move'
  });

export const rename = (request: Request) => (params: RenameFolderParams) =>
  request<Folder>({
    params: extendParams(params),
    method: 'PUT',
    url: 'folder/rename'
  });

export const deleteFolder = (request: Request) => (
  params: DeleteFolderParams
) =>
  request<Folder>({
    params: extendParams(params),
    method: 'DELETE',
    url: 'folder/delete'
  });
