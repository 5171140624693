import React, { FC, lazy, Suspense } from 'react';
import { EnvType } from '../../config';
import { Manifest, ParentVideoData, Password, Target } from '../../types';
import { VideoPlayerCallbacks } from '../Player/hooks/useVideoPlayer';

import { usePlayerWidth } from './hooks';
import DelayedLoader from '../DelayedLoader';

type AppProps = {
  videoId: string;
  env?: EnvType;
  password?: Password;
  manifest?: Manifest;
  isPopover?: boolean;
  playerWidth?: number;
  parentVideoData?: ParentVideoData;
} & Target &
  VideoPlayerCallbacks;

const AppContent = lazy(() => import(/* webpackChunkName: "plr" */ '../AppContent'));

const App: FC<AppProps> = ({ playerWidth: playerWithFromProps, ...appProps }) => {
  const [playerRef, playerWidth] = usePlayerWidth(playerWithFromProps);

  return (
    <div ref={playerRef}>
      {playerWidth && (
        <Suspense fallback={() => <DelayedLoader />}>
          <AppContent {...{ playerWidth, ...appProps }} />
        </Suspense>
      )}
    </div>
  );
};

export default App;
