import CodeSample from '@anm/components/CodeSample';
import FaqSection from '@anm/components/sections/FaqSection';
import WorkshopList from '@anm/components/sections/WorkshopList';
import { ComponentType, FC } from 'react';

import { LandingData, LandingSectionListItem } from '../../../features/getLanding';
import AbsoluteBg from '../../sections/AbsoluteBg';
import AccordionMedia from '../../sections/AccordionMedia';
import BackgroundText from '../../sections/BackgroundText';
import Banner from '../../sections/Banner';
import CardList from '../../sections/CardList';
import CompanyInfo from '../../sections/CompanyInfo';
import CompanyQuote from '../../sections/CompanyQuote';
import CreateEmbedButton from '../../sections/CreateEmbedButton';
import Feedback from '../../sections/Feedback';
import HorizontalImageText from '../../sections/HorizontalImageText';
import HorizontalList from '../../sections/HorizontalList';
import ImageList from '../../sections/ImageList';
import LandingPreviewCardList from '../../sections/LandingPreviewCardList';
import LandingsList from '../../sections/LandingsList';
import MasonryImageList from '../../sections/MasonryImageList';
import MediaTesterSection from '../../sections/MediaTesterSection';
import NavLinksList from '../../sections/NavLinksList';
import Partners from '../../sections/Partners';
import PostAuthor from '../../sections/PostAuthor';
import Reviews from '../../sections/Reviews';
import SideList from '../../sections/SideList';
import SideMedia from '../../sections/SideMedia/';
import SideMediaLinkList from '../../sections/SideMediaLinkList';
import SimpleLinkList from '../../sections/SimpleLinkList';
import SimpleMedia from '../../sections/SimpleMedia';
import StocksList from '../../sections/StocksList';
import TableComparison from '../../sections/TableComparison';
import TabsSection from '../../sections/TabsSection';
import TemplatePageList from '../../sections/TemplatePageList';
import TextAside from '../../sections/TextAside';
import TextSection from '../../sections/TextSection';
import TopSection from '../../sections/TopSection';
import TutorialList from '../../sections/TutorialList';
import UltraTitle from '../../sections/UltraTitle';
import VerticalList from '../../sections/VerticalList';
import VerticalTextVideo from '../../sections/VerticalTextVideo';
import VideoGallery from '../../sections/VideoGallery';
import VideoStatisticList from '../../sections/VideoStatisticList';
import VideoSteps from '../../sections/VideoSteps';
import ButtonDemo from '../../sections/wave-button/ButtonDemo';
import ButtonDocsTable from '../../sections/wave-button/ButtonDocsTable';
import ButtonExampleHeader from '../../sections/wave-button/ButtonExampleHeader';
import ButtonExamplesList from '../../sections/wave-button/ButtonExamplesList';

type LandingFrameworkProps = {
  landing: LandingData;
};

export const sectionComponentMap = {
  faq: FaqSection,
  tabs: TabsSection,
  banner: Banner,
  reviews: Reviews,
  feedback: Feedback,
  partners: Partners,
  'card-list': CardList,
  'side-list': SideList,
  'text-aside': TextAside,
  'side-media': SideMedia,
  'image-list': ImageList,
  'stock-list': StocksList,
  'button-demo': ButtonDemo,
  'post-author': PostAuthor,
  'top-section': TopSection,
  'ultra-title': UltraTitle,
  'code-sample': CodeSample,
  'video-steps': VideoSteps,
  'absolute-bg': AbsoluteBg,
  'simple-media': SimpleMedia,
  'text-section': TextSection,
  'company-info': CompanyInfo,
  'tutorial-list': TutorialList,
  'workshop-list': WorkshopList,
  'docs-table': ButtonDocsTable,
  'landings-list': LandingsList,
  'company-quote': CompanyQuote,
  'vertical-list': VerticalList,
  'video-gallery': VideoGallery,
  'nav-links-list': NavLinksList,
  'accordion-media': AccordionMedia,
  'horizontal-list': HorizontalList,
  'background-text': BackgroundText,
  'table-compare': TableComparison,
  'media-tester': MediaTesterSection,
  'simple-link-list': SimpleLinkList,
  'masonry-images': MasonryImageList,
  'button-examples': ButtonExamplesList,
  'template-page-list': TemplatePageList,
  'create-embed-button': CreateEmbedButton,
  'vertical-text-video': VerticalTextVideo,
  'side-media-link-list': SideMediaLinkList,
  'video-statistic-list': VideoStatisticList,
  'button-example-header': ButtonExampleHeader,
  'horizontal-image-text': HorizontalImageText,
  'landing-preview-card-list': LandingPreviewCardList
};

const renderSections = (landing: LandingData) => {
  return landing.sections.map(({ name, ...otherProps }: LandingSectionListItem, i) => {
    const Section: ComponentType = sectionComponentMap[name];
    return Section ? <Section key={i} {...otherProps} /> : null;
  });
};

const Landing: FC<LandingFrameworkProps> = ({ landing }) => <>{renderSections(landing)}</>;

export default Landing;
