import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import isString from 'lodash/fp/isString';
import { useEffect, useState } from 'react';

import { getLogos, getWaveMainPageUrl } from './helpers';
import { LogoVariant } from './types';

type UseLogoDataProps = {
  variant: LogoVariant;
  link?: string | boolean;
  waveUrl?: string;
  studioUrl?: string;
};

export const useLogoData = ({ link, waveUrl, variant, studioUrl }: UseLogoDataProps) => {
  const [{ href, iconSrc, alt }, setLogos] = useState(getLogos(variant, studioUrl, waveUrl));
  const logoHref = link === false ? '' : isString(link) ? link : href;

  useEffect(() => {
    setLogos(getLogos(variant, studioUrl, waveUrl && getWaveMainPageUrl(waveUrl)));
  }, [hasAuthAccess(), variant]);

  return [logoHref, iconSrc, alt] as const;
};
