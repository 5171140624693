import videoConstrains from '@anm/data/media/videoConstrains';
import logger from '@anm/helpers/logger';
import { noop } from 'lodash/fp';
import { useEffect, FC } from 'react';

import MediaError from '../MediaError';

export type AllowMicCamProps = {
  onAllow?: () => void;
};

const AllowMicCam: FC<AllowMicCamProps> = ({ onAllow = noop }) => {
  useEffect(() => {
    (async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: videoConstrains('hd') });
        onAllow();
      } catch (e) {
        logger(`Error occurred when try to allow mic and webcam: ${e}`);
      }
    })();
  }, []);

  return (
    <MediaError
      title="No camera and microphone access"
      description="Click “Allow” in the browser popup to get started."
    />
  );
};

export default AllowMicCam;
