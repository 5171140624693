import browserStore from '@anm/helpers/browserStore';
import { VIDEO_FROM_AI_KEY } from '@anm/constants/keys';
import useSwitchState from '@anm/hooks/useSwitchState';
import { CreateVideoHandlerProps } from '@anm/components/my-projects/FormatAndStyleModal/types';
import { AllJobIdParams } from '@anm/api/modules/storyboard';
import { trackCreateVideoFromAI } from '@anm/components/NewVideoButton/helpers';
import VideoFromAILimitModal from '..';

type HookProps = {
  limit?: number;
  jobParams?: AllJobIdParams;
};

const getCounter = () => browserStore.get<number>(VIDEO_FROM_AI_KEY) || 0;
const incrementCounter = () => browserStore.set(VIDEO_FROM_AI_KEY, getCounter() + 1);

const useVideoFromAILimit = ({ limit, jobParams }: HookProps) => {
  const [isLimitModalOpened, openLimitModal, closeLimitModal] = useSwitchState();

  const canCreateVideo = limit ? getCounter() < limit : true;

  const limitGuard = (createVideoAction: () => void) =>
    canCreateVideo ? () => createVideoAction() : () => openLimitModal();

  const renderModal = () => <>{isLimitModalOpened && <VideoFromAILimitModal onCancel={closeLimitModal} />}</>;

  const handleCreateVideo = (params: CreateVideoHandlerProps) => {
    trackCreateVideoFromAI({ params, jobParams });

    limit && incrementCounter();
  };

  return [handleCreateVideo, limitGuard, renderModal] as const;
};

export default useVideoFromAILimit;
