import debounce from 'lodash/fp/debounce';
import { useEffect } from 'react';

const useResize = (callback: EventListener) => {
  const debounceCallback = debounce(150)(callback);

  useEffect(() => {
    window.addEventListener('resize', debounceCallback);
    window.addEventListener('orientationchange', debounceCallback);

    return () => {
      window.removeEventListener('resize', debounceCallback);
      window.removeEventListener('orientationchange', debounceCallback);
    };
  }, [callback]);
};

export default useResize;
