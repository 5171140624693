// import { getSrc } from '@anm/helpers/image';
// import useFetchImage from '@anm/hooks/image/useFetchImage';
// import { stretchBlock } from '@anm/styles/helpers';
import NextImage from '@anm/components/image/NextImage';
import { ImageLoader } from 'next/image';
import { FC, MouseEvent } from 'react';
import styled from 'styled-components';

import Thumb from './Thumb';

export type ProgressiveImageLoadingProps = {
  alt?: string;
  thumb?: string;
  width?: number;
  height?: number;
  imageSrc?: string;
  className?: string;
  loader?: ImageLoader;

  // isFirstRenderImage?: boolean;
  onClick?: (e: MouseEvent<HTMLImageElement>) => void;
};

const Wrapper = styled.div`
  position: relative;
  svg {
    display: block;
  }
`;

// const MainImage = styled.img`
//   ${stretchBlock}
// `;

const ProgressiveImageLoading: FC<ProgressiveImageLoadingProps> = ({
  alt,
  thumb,
  imageSrc,
  className,
  loader,
  // isFirstRenderImage,
  width = 300,
  height = 200,
  onClick
}) => {
  // const [scrollAreaRef, isImageReady] = useFetchImage(imageSrc, isFirstRenderImage);

  return (
    <Wrapper>
      {imageSrc && (
        <NextImage loader={loader} layout="fill" alt={alt} src={imageSrc} className={className} onClick={onClick} />
      )}
      <Thumb {...{ thumb, width, height }} />
    </Wrapper>
  );
};

export default ProgressiveImageLoading;
