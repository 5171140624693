import createApiInstance from './helpers/createApiInstance';
import presetAxiosInstance from './helpers/presetAxiosInstance';
import * as stream from './modules/stream';
import { ApiConfig } from './types';
export * from './types';

const genApi = (apiConfig: ApiConfig) => {
  const { bind } = presetAxiosInstance(apiConfig);
  return bind(stream.wave);
};

const liveStreamApi = createApiInstance(genApi);

export default liveStreamApi;
