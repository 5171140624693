import useSafeAction from '@anm/auth/hooks/useSafeAction';
import { ButtonProps } from '@anm/components/buttons/Button';
import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import { UserContext } from '@anm/components/user/UserProvider';
import { FC, useContext } from 'react';

export type SignupAndOpenProps = ButtonProps & {
  text?: string;
};

const SignupAndOpen: FC<SignupAndOpenProps> = ({ href, text = 'Start creating', ...otherProps }) => {
  const { anon, isUserLogged } = useContext(UserContext);
  const getSafeAction = useSafeAction();
  const signupAndOpen = getSafeAction(() => window.open(href, '_self'));

  return (
    <ButtonLoader
      isPending={anon.isPending}
      {...otherProps}
      {...(isUserLogged ? { href } : { onClick: signupAndOpen })}
    >
      {text}
    </ButtonLoader>
  );
};

export default SignupAndOpen;
