import api from '@anm/api';
import { CreateNewVideoData, UploadFileProps } from '@anm/api/modules/hosting';
import isIOS from '@anm/helpers/is/isIOS';
import { DEFAULT_VIDEO_NAME } from '@anm/constants/my-videos';
import cutFileExtension from '@anm/helpers/cutFileExtension';
import getExtensionFromPath from '@anm/helpers/getExtensionFromPath';
import { UploadFileProps as UploadProps } from '@anm/components/InputFile';
import { useEffect, useState } from 'react';
import config from '../../../../../config';
import { Logger } from '@anm/helpers/Debugger';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import { OpenEditorProps, UseUploadVideoProps } from '../types';
import useVideoStatusChecker from '@anm/hooks/useVideoStatusChecker';

const logger = new Logger('upload_video');

const DEFAULT_DESCRIPTION = 'hosted by Wave.video';

const openEditor = ({ uploadIds, intent }: OpenEditorProps) => {
  const query = intent ? `&intent=${intent}` : '';
  const uploadParam = `#uploadIds=${uploadIds.join(',')}`;
  const editorUrl = config.editorUrl;

  window.open(`${editorUrl}/${uploadParam}${query}`, '_self');
};

const useUploadVideo = ({
  intent,
  onUpload = 'openEditor',
  onUploadProgress: onProgress,
  checkStorage
}: UseUploadVideoProps) => {
  const [uploadIds, setUploadIds] = useState<string[]>();
  const [totalFiles, setTotalFiles] = useState(1);
  const [progressValue, setProgressValue] = useState('');
  const [hostId, setHostId] = useState('');
  const [canCheckUpload, setCanCheckUpload] = useState(false);
  const [isPreparingAudio, setIsPreparingAudio] = useState(false);
  const [currentUploadIndex, setCurrentUploadIndex] = useState(1);

  const createVideo = (data: CreateNewVideoData) => api().hosting.createNewVideo(data);
  const uploadVideo = (data: UploadFileProps) => api().hosting.uploadNewVideo(data);

  const onUploadProgress = (e: ProgressEvent) => {
    const value = `${Math.round((e.loaded * 100) / e.total)}%`;
    onProgress?.(value);
    setProgressValue(value);
  };

  const handleFileChange = async (uploads: UploadProps[]) => {
    if (!hasAuthAccess()) return;

    setTotalFiles(uploads.length);

    for (const uploadProps of uploads) {
      const isStorageError = await checkStorage();

      if (isStorageError) return;

      await upload(uploadProps.file);
    }
  };

  const upload = async (file: File) => {
    try {
      const name = isIOS() ? DEFAULT_VIDEO_NAME : cutFileExtension(file.name);
      const description = `${name} - ${DEFAULT_DESCRIPTION}`;
      const extension = getExtensionFromPath(file.name) || '';

      const params: CreateNewVideoData = {
        extension,
        meta: { name, description }
      };

      const createdVideoData = await createVideo(params);

      const url = createdVideoData.request.url;
      const uploadId = createdVideoData.data.upload.id;

      setHostId(createdVideoData.hostId);

      await uploadVideo({
        url,
        file,
        onUploadProgress
      }).then(() => {
        setUploadIds(ids => [...(ids || []), uploadId]);
        setCurrentUploadIndex(upload => upload + 1);
      });
    } catch (e) {
      logger.error(e);
    }
  };

  useEffect(() => {
    if (typeof totalFiles !== 'number' || !uploadIds) return;

    const isAllUploaded = totalFiles === uploadIds?.length;

    if (isAllUploaded) {
      setProgressValue('');

      switch (onUpload) {
        case 'openEditor':
          return openEditor({ uploadIds, intent });
        case 'downloadAudio': {
          setIsPreparingAudio(true);
          setCanCheckUpload(true);
        }
      }
    }
  }, [totalFiles, uploadIds?.length, onUpload]);

  const media = useVideoStatusChecker({
    hostId,
    source: 'api',
    mediaType: 'audio',
    supportedExtensions: config.supportedAudioExtensions,
    progress: canCheckUpload ? 100 : null
  });

  useEffect(() => {
    const mp3 = media.videoManifest?.main.find(file => file.type === 'audio')?.path;

    if (!media.isMediaReady || !mp3) return;
    setIsPreparingAudio(false);

    window.open(mp3, '_self');
  }, [media.isMediaReady]);

  return [progressValue, handleFileChange, totalFiles, currentUploadIndex, isPreparingAudio] as const;
};

export default useUploadVideo;
