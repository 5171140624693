import Select, { SelectOption } from '@anm/components/select/Select';
import { SelectOptionsContainer, SelectValueContainer } from '@anm/components/select/Select';
import { useEffect, useState, FC } from 'react';
import styled from 'styled-components';

import { Label } from './ModalSettings/Wrapper';

const Wrapper = styled.div`
  z-index: 2;
  margin-left: 12px;
  position: relative;
  ${SelectValueContainer} {
    height: 40px;
    width: 148px;
    font-size: 16px;
    line-height: 19px;
    padding: 8px 12px;
    &:after {
      right: 0;
      position: absolute;
    }
  }
  ${SelectOptionsContainer} {
    max-height: 150px;
    overflow-y: auto;
    & > div {
      max-height: 40px;
    }
  }
`;

export const MOODS = [
  'inspiring',
  'anxious',
  'confident',
  'confused',
  'crazy',
  'curios',
  'creamy',
  'epic',
  'funny',
  'happy',
  'melancholy',
  'mysterious',
  'relaxing',
  'romantic',
  'sad'
];

const moodsOptions = MOODS.map(m => ({ label: m, value: m }));

const MoodPicker: FC<any> = ({ onMoodChange }) => {
  const [mood, setMood] = useState<SelectOption<string>>(moodsOptions[0]);

  useEffect(() => {
    onMoodChange(mood.value);
  }, [mood]);

  return (
    <Wrapper>
      <Label>Audio mood</Label>
      <Select name="mood" value={mood} options={moodsOptions} onChange={setMood} />
    </Wrapper>
  );
};

export default MoodPicker;
