import { isArray, isEmpty, isEqual, isObject, isString } from 'lodash/fp';

import clearStream from '../clearStream';
import {MediaKind} from "mediasoup/node/lib/RtpParameters";
import {Logger} from "@anm/helpers/Debugger";

const logger = new Logger('media_getter');

const createStream = async (constraints: MediaStreamConstraints, prevStream?: MediaStream) => {
  clearStream(prevStream);
  return navigator.mediaDevices.getUserMedia(constraints);
};

const updateConstraint = (stream: MediaStream, constraints: MediaTrackConstraints) => {
  const track = stream.getTracks()[0];
  return track.applyConstraints(constraints);
};

const getDeviceId = (constraints?: MediaTrackConstraints) => {
  const deviceId = constraints?.deviceId;
  // @ts-ignore
  const sourceId = constraints?.mandatory?.sourceId;

  switch (true) {
    case isArray(deviceId) && isEmpty(deviceId):
    case (isString(deviceId) && !deviceId) || (isString(sourceId) && !sourceId): {
      return;
    }
    case isString(deviceId) && !!deviceId: {
      return deviceId;
    }
    case isString(sourceId) && !!sourceId: {
      return sourceId;
    }

    case isObject(deviceId): {
      const { exact, ideal } = deviceId as ConstrainDOMStringParameters;
      return exact || ideal;
    }
  }
};

const isValid = (constraints?: boolean | MediaTrackConstraints): constraints is MediaTrackConstraints => {
  return isObject(constraints as MediaTrackConstraints);
};

const createMediaGet = (mediasType: MediaKind) => {
  const streams = new Map<string, Promise<MediaStream>>();
  const prevConstraintsList = new Map<string, undefined | boolean | MediaTrackConstraints>();

  return async (streamConstraints: MediaStreamConstraints, id: string) => {
    const stream = streams.get(id);
    const prevConstraints = prevConstraintsList.get(id);
    const constraints = streamConstraints[mediasType];

    switch (true) {
      case isValid(constraints) && !getDeviceId(constraints) && stream !== undefined:
      case constraints === true && stream !== undefined: {
        // logger.debug(`first[${id}]`, JSON.stringify(constraints, null, 4));
        // logger.debug(`prevConstraints`, JSON.stringify(prevConstraints, null, 4));
        return stream;
      }
      case stream === undefined:
      case !(await stream)?.active:
      case isValid(constraints) &&
        (!isValid(prevConstraints) || !isEqual(getDeviceId(constraints), getDeviceId(prevConstraints))): {
        logger.debug(`createStream[${id}]`, JSON.stringify(constraints, null, 4));
        logger.debug(`prevConstraints`, JSON.stringify(prevConstraints, null, 4));

        const newStream = createStream(streamConstraints, await stream);
        streams.set(id, newStream);
        prevConstraintsList.set(id, constraints);
        return newStream;
      }
      case isValid(prevConstraints) && isValid(constraints) && !isEqual(prevConstraints, constraints) && !!stream: {
        logger.debug(`updateConstraints[${id}]`, JSON.stringify(streamConstraints, null, 4));
        logger.debug(`prevConstraints`, JSON.stringify(prevConstraints, null, 4));

        await updateConstraint(await stream!, constraints as MediaTrackConstraints);
        prevConstraintsList.set(id, constraints);
        return stream;
      }

      default: {
        // logger.debug(`default[${id}]`, JSON.stringify(constraints, null, 4));
        // logger.debug(`prevConstraints`, JSON.stringify(prevConstraints, null, 4));
        return stream;
      }
    }
  };
};

export default createMediaGet;
