import getFromCdn from '@anm/helpers/getFromCdn';
import slick from '@anm/styles/third-party/slick';
import styled, { css } from 'styled-components';

import SectionTitle, { Description } from '../../../SectionTitle';
import { SliderArrowVariants } from '../types';

export const CarouselWrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 0 20px 30px;

  ${slick}

  div {
    outline: none;
    cursor: grab;
  }

  .slick-slider {
    margin: 0 auto;
    position: static;
    max-width: 850px;
    min-height: 200px;
  }

  .slick-dots {
    bottom: 0;
    li {
      width: 35px;
      margin: 0 0 0 -20px;
      button:before {
        font-size: 20px;
      }
    }
  }
`;

export const Title = styled(SectionTitle)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 24px;
    line-height: 32px;
  }
  ${Description} {
    margin-top: 15px;
    font-size: 16px;
    line-height: 25px;
  }
`;

const variants: { [key in SliderArrowVariants]: ReturnType<typeof css> } = {
  left: css`
    left: 0;
  `,
  right: css`
    right: 0;
    transform: rotate(180deg);
  `
};

export const SliderArrowWrapper = styled.button<{
  direction: SliderArrowVariants;
}>`
  top: 25%;
  position: absolute;
  cursor: pointer;
  background: url('${getFromCdn(
    'images/icons/accordion_slider_arrow.svg'
  )}') no-repeat
      center / contain;
  z-index: 1;
  width: 20px;
  height: 20px;

  ${({ direction }) => variants[direction]};
`;
