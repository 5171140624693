import Button from '@anm/components/buttons/Button/Button';
import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';

import HorizontalListIconHolder from './IconHolder';
import { CommonListProps, ListItemProps } from './types';

const simpleImageListMobileStyles = css`
  display: flex;
  justify-content: space-between;
`;

const ListWrapperOnTablet = media.tablet`
  margin-top: 52px;
  display: flex;
  flex-wrap: ${({ multiLine, columns }: CommonListProps) => (multiLine || columns ? 'wrap' : 'nowrap')};
  justify-content: space-between;
`;

const ListItemOnTablet = media.tablet`
  width: ${({ length, multiLine }: ListItemProps) => `calc(100% / ${length / (multiLine ? 2 : 1)})`};
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: ${({ multiLine }) => (multiLine ? '80px' : '60px')};
  margin-bottom: ${({ isSimpleImageList }) => isSimpleImageList && '0'};
`;

const ListItemOnLaptop = media.laptop`
  padding: 0 20px 0;
`;

const IconOnLaptop = media.laptop`
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin: 0 auto 22px;
`;

const SpanOnLaptop = media.laptop`
  font-size: 24px;
  line-height: 1.33;
`;

const ParagraphOnLaptop = media.laptop`
  font-size: 18px;
  line-height: 1.44;
`;

const counterStyles = css<ListItemProps>`
  &:before {
    width: 70px;
    height: 70px;
    display: block;
    color: #292a2b;
    font-size: 36px;
    line-height: 70px;
    margin: 0 auto 14px;
    text-align: center;
    font-weight: bold;
    content: counter(step-counter);
    border-radius: ${({ customIcon }) => (customIcon?.borderRadius ? `${customIcon.borderRadius}px` : '50%')};
    background-color: ${({ customIcon }) => (customIcon?.background ? `${customIcon.background}` : '#fff200')};
    ${IconOnLaptop};
  }
`;

export const ListItem = styled.li<ListItemProps>`
  position: relative;
  text-align: center;
  counter-increment: step-counter;
  margin-bottom: 24px;
  ${ListItemOnTablet};
  ${ListItemOnLaptop};
  ${({ iconUrl }) => !iconUrl && counterStyles}
`;

const columnsStyles = css<CommonListProps>`
  li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    padding: 0 15px;
    min-width: ${({ columns }) => `calc(100% / ${columns})`};
    ${HorizontalListIconHolder} {
      margin: 0 28px 0 0;
    }
  }
  p {
    font-size: 16px;
    min-width: calc(100% - 130px);
    text-align: left;
    margin: 10px 0 0;
  }
  a {
    color: #292a2b;
    text-decoration: underline;
    &:hover {
      color: #2294df;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 60px;
  text-align: center;
  ${Button} {
    a {
      ${flexCenter};
      padding: 0 30px;
      font-size: 20px;
      color: #fff !important;
    }
  }
`;

export const ListWrapper = styled.ul<CommonListProps>`
  margin-top: 22px;
  ${simpleImageListMobileStyles};
  ${ListWrapperOnTablet};
  span {
    font-size: 20px;
    line-height: 1.5;
    font-weight: bold;
    color: #292a2b;
    ${SpanOnLaptop};
  }
  p {
    font-size: 17px;
    line-height: 1.65;
    color: #292a2b;
    margin-top: 10px;
    ${ParagraphOnLaptop};
    a {
      color: inherit;
      &:hover {
        color: #2294df;
      }
    }
  }
  ${({ columns }) => columns && columnsStyles}
`;
