import { isAnon } from '@anm/auth/helpers/authAnon';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import { UserContext } from '@anm/components/user/UserProvider';
import useEventPending from '@anm/hooks/useEventPending';
import { useCallback, useContext, useEffect, useState, FC, MouseEvent } from 'react';

import { LandingButtonOwnProps } from '../..';
import lfConfig from '../../../../config';
import { LandingButtonKind } from '../../types';

import useSignUpUrl from './hooks/useSignUpUrl';

type ButtonAuthThemeItem = {
  url: string;
  text: string;
  entry?: string;
  anonUser?: boolean;
  redirectUrl?: string;
};

const { waveUrl } = lfConfig;

export const buttonAuthData = {
  templates: {
    text: 'Create video',
    url: `${waveUrl}video-templates`,
    entry: 'TEMPLATE'
  },

  'streaming-templates': {
    text: 'Sign up free',
    url: `http://animatron.typeform.com/to/Yt4yyAQl?utm_source=landing_page&utm_medium=banner&utm_campaign=streaming_beta_application&utm_content=first_group`,
    entry: `${waveUrl}video-templates/live-streaming`
  },
  calendar: {
    text: 'Get inspired',
    url: `${waveUrl}calendar`,
    redirectUrl: `${waveUrl}calendar`
  },
  videos: {
    text: 'Upload video',
    url: `${waveUrl}videos`,
    redirectUrl: `${waveUrl}videos`
  },
  'my-videos': {
    text: 'Create video',
    url: `${waveUrl}videos`,
    redirectUrl: `${waveUrl}videos`
  },
  'my-streams': {
    text: 'Live Stream',
    url: `${waveUrl}lives`,
    redirectUrl: `${waveUrl}lives`
  },
  'start-streaming': {
    text: 'Start streaming',
    url: `${waveUrl}lives`,
    redirectUrl: `${waveUrl}lives`
  },
  'image-project-editor': {
    text: 'Create from template',
    url: `${waveUrl}editor/#new=1&kind=image&target=templates`,
    redirectUrl: `${waveUrl}editor/#new=1&kind=image&target=templates`,
    anonUser: true
  },

  'record-stream': {
    text: 'Record video',
    url: `${waveUrl}live/new-recording?skipSetup=yes`,
    anonUser: true
  },
  'custom-auth': {
    text: '',
    url: ''
  }
} as {
  [key in LandingButtonKind]: ButtonAuthThemeItem;
};

export type ButtonAuthProps = LandingButtonOwnProps & {
  anonUser?: boolean;
  redirectUrl?: string;
  customAuthButtonGuestText?: string;
};

const ButtonAuth: FC<ButtonAuthProps> = ({
  customAuthButtonGuestText,
  anonUser,
  kind,
  redirectUrl,
  href: buttonHref,
  text,
  ...buttonProps
}) => {
  const { anon } = useContext(UserContext);

  const { url: themeUrl, text: themeText, anonUser: themeAnonUser } = buttonAuthData[kind || 'custom'];
  const localUrl = buttonHref || themeUrl;
  const localText = text || themeText;
  const openPage = () => window.open(localUrl, '_self');
  const statEventPending = useEventPending(anon.isPending, openPage);

  const signUpUrl = useSignUpUrl({ url: localUrl, kind });

  const anonUserLocal = anonUser || (anonUser === undefined && themeAnonUser);
  const hasAccess = (hasAuthAccess() && !isAnon()) || anonUserLocal;
  const buttonTitle = hasAccess ? localText : customAuthButtonGuestText || 'Sign up free →';
  const [href, setHref] = useState<string>();

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (hasAuthAccess() || !anonUserLocal || anon.isPending) return;

      e.preventDefault();
      anon.signup();
      statEventPending();
    },
    [anon.isPending, anonUserLocal, hasAuthAccess()]
  );

  useEffect(() => {
    setHref(hasAccess ? localUrl : signUpUrl);
  }, [hasAccess, localUrl, signUpUrl]);

  return (
    <ButtonLoader
      isGrayPending
      href={href || signUpUrl}
      isPending={anon.isPending}
      onClick={handleClick}
      {...buttonProps}
    >
      {buttonProps.children || buttonTitle}
    </ButtonLoader>
  );
};

export default ButtonAuth;
