import ButtonLoader from '@anm/components/buttons/ButtonLoader';
import IconButton from '@anm/components/buttons/IconButton';
import { blackFilter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';
import { ButtonWrapperProps } from './types';

const commonStyles = css`
  font-size: 20px;
  min-height: 60px;
`;

export const ButtonWrapper = styled(ButtonLoader)<ButtonWrapperProps>`
  cursor: pointer;
  ${commonStyles};
  ${({ isLogged }) =>
    isLogged &&
    css`
      pointer-events: none;
    `};
`;

export const Label = styled.label`
  cursor: pointer;
  display: block;
`;

export const PreparingAudio = styled(IconButton)`
  ${commonStyles};
  cursor: default;

  &:before {
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
    filter: ${blackFilter};
    animation: spin 0.7s infinite linear;
  }

  &:hover {
    border-color: #ffea00;
    background: #ffea00;
  }
`;
