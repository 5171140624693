import isDisplayName from '@anm/helpers/is/isDisplayName';
import isEmail from '@anm/helpers/is/isEmail';
import isUrlAbsolute from '@anm/helpers/is/isUrlAbsolute';
import isUserExist from '@anm/helpers/is/isUserExist';
import isValidPassword from '@anm/helpers/is/isValidPassword';
import memoize from 'lodash/fp/memoize';

import couponValidator from './couponValidator';
export * from './creditCardValidators';
export { couponValidator };

export const youtubeUrl = (url: string) =>
  isUrlAbsolute(url) && url.includes('//www.youtube.com/watch?') ? null : 'Incorect Youtube url';

const memoizedIsUserExist = memoize(isUserExist);
export const mailWithCheckExistValidator = (currentEmail?: string) => async (email: string) =>
  !email
    ? 'This field is required'
    : !isEmail(email)
    ? 'Incorrect email'
    : (!currentEmail || currentEmail !== email) && (await memoizedIsUserExist(email))
    ? 'This email is already registered'
    : null;

export const displayName = (displayName: string) => (!isDisplayName(displayName) ? 'Incorrect name' : null);

export const password = (password: string) => (!isValidPassword(password) ? ' ' : null);

export const required = (message = 'This field is required') => (value: any) => (value ? undefined : message);

export const confirmPassword = (newPassword: number | string | boolean) => (prevPassword: number | string | boolean) =>
  newPassword !== prevPassword ? 'Doesn’t match' : undefined;

export const mustBeNumber = (value: any) => (isNaN(value) ? 'Must be a number' : undefined);

export const minValue = (min: number) => (value: number) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const minLength = (min: number) => (value: string) =>
  !!value && value.length < min ? `Should be at least ${min} characters long` : undefined;

export const email = (email: string) => (!isEmail(email) ? 'Incorrect email' : null);

export const composeValidators = (...validators: ((value: any) => any)[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);
