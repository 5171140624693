import { styledFormats } from '@anm/data/videoTemplatesFormatModal';
import { toUrlParams } from '@anm/helpers/parseParams';
import { useCallback, useState } from 'react';

import { getSelectedIdFormat, getSmallMedia } from './helpers';
import { HandlersProps, TemplateDataProps } from './types';

export const useCurrentFormatWidth = ({ currentFormat }: TemplateDataProps) => {
  const { width } = styledFormats.find(f => f.format === currentFormat)!;

  return [width] as const;
};

export const useTemplateData = ({ templateId, media, currentFormat, selectedIdFormat }: TemplateDataProps) => {
  if (!media) return [];

  const mediaKeys = Object.keys(media);
  const mediaKey = mediaKeys.find(k =>
    k.includes(currentFormat === 'vertical' ? 'facebook_vertical_feed' : currentFormat.split(' ').join('_'))
  ) as string;

  const mediaValue = media[mediaKey];

  if (!mediaValue) return [];

  const { width } = styledFormats.find(f => f.format === currentFormat)!;

  const { size, url: previewUrl } = getSmallMedia(mediaValue.thumb);
  const { url: videoUrl } = getSmallMedia(mediaValue.video);

  const formatWidth = size.width;
  const formatHeight = size.height;

  const templateHeight = (formatHeight / formatWidth) * width;

  const templateSize = {
    width,
    height: templateHeight
  };

  const [selectedId] = getSelectedIdFormat(selectedIdFormat);

  const isSelected = selectedId === templateId;

  return [mediaValue, mediaKey, previewUrl, videoUrl, isSelected, templateSize] as const;
};

export const useModalHandlers = (
  { editorUrl, jobId, onCreateVideo, onModalClose }: HandlersProps,
  folderId?: number
) => {
  const [selectedIdFormat, setIdFormat] = useState<string | null>(null);
  const [selectedId, , mediaFormat] = getSelectedIdFormat(selectedIdFormat);

  const urlParams = toUrlParams({
    new: jobId,
    target: 'edit',
    stbId: selectedId,
    format: mediaFormat,
    ...(folderId && { folderId })
  });

  const handleCreateVideo = useCallback(() => {
    onCreateVideo({
      format: mediaFormat,
      template: selectedId
    });
    onModalClose();
    window.open(`${editorUrl}#${urlParams}`, '_blank');
  }, [urlParams]);

  return [selectedIdFormat, setIdFormat, handleCreateVideo] as const;
};
