import Image from '@anm/components/image/Image';
import noop from '@anm/helpers/noop';
import { ChangeEvent, FC } from 'react';
import styled, { css } from 'styled-components';

export type CheckboxProps = {
  checked: boolean;
  className?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
};

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  width: 1px;
  padding: 0;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
`;

const checkedStyles = css`
  background: var(--blue_100);
`;

const uncheckedStyles = css`
  border: solid 1px #cbdce5;
`;

const CheckboxImage = styled(Image)``;

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  transition: all 150ms;
  display: inline-block;
  ${CheckboxImage} {
    margin: 0;
    position: absolute;
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
  ${({ checked }) => (checked ? checkedStyles : uncheckedStyles)};
`;

export const CheckboxContainer = styled.div`
  margin-top: -1px;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
`;

const Checkbox: FC<CheckboxProps> = ({ className = '', checked, onChange = noop, ...props }) => (
  <CheckboxContainer {...{ className }}>
    <HiddenCheckbox {...{ ...props, checked, onChange }} />
    <StyledCheckbox {...{ checked }}>
      <CheckboxImage src="images/icons/checkbox.svg" alt="checkbox" />
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
