import VideoPlayer from '@anm/components/VideoPlayer';
import Button, { ButtonProps, ButtonSize, ButtonVariants } from '@anm/components/buttons/Button';
import LazyImage from '@anm/components/image/LazyImage';
import CardModal, { CardModalProps } from '@anm/components/modals/CardModal';
import { StockPrice } from '@anm/components/stocks/StockItem/Wrapper';
import { EMBED_INTERNAL_PARAM } from '@anm/constants/wave-editor';
import usePageParams from '@anm/hooks/usePageParams';
import { FC } from 'react';

import Wrapper, { ButtonContainer, MediaContainer } from './Wrapper';
import { useAnimation } from './hooks';

type StockModalProps = CardModalProps & {
  title: string;
  isVideo: boolean;
  priceValue: string;
  thumbnail_url: string;
  templateFromStockUrl: string;
  preview_url?: string;
  handleAddStock?(): void;
  renderAnonButton?(props: Omit<ButtonProps, 'onClick'> & { onClick?(): void }): JSX.Element;
};

const StockModal: FC<StockModalProps> = ({
  title,
  isVideo,
  priceValue,
  preview_url,
  cardSettings,
  thumbnail_url,
  templateFromStockUrl,
  onModalClose,
  handleAddStock,
  renderAnonButton
}) => {
  const { embed } = usePageParams();
  const isAnimating = useAnimation();
  const isInternalEmbed = embed === EMBED_INTERNAL_PARAM;

  const buttonText = isInternalEmbed ? 'Add' : 'Make Video';

  const buttonProps = {
    size: 'large' as ButtonSize,
    variant: 'light-blue-lowercase' as ButtonVariants,
    children: buttonText,
    ...(isInternalEmbed ? { onClick: handleAddStock } : { href: templateFromStockUrl })
  };

  return (
    <CardModal {...{ cardSettings, onModalClose }}>
      <Wrapper {...{ isAnimating }}>
        <MediaContainer>
          <StockPrice>{priceValue}</StockPrice>
          {isVideo ? (
            <VideoPlayer src={preview_url!} poster={thumbnail_url} />
          ) : (
            <LazyImage src={preview_url || thumbnail_url} alt={title} />
          )}
        </MediaContainer>
        <ButtonContainer>
          {renderAnonButton ? renderAnonButton(buttonProps) : <Button {...buttonProps} />}
        </ButtonContainer>
      </Wrapper>
    </CardModal>
  );
};

export default StockModal;
