import mediaManager from '@anm/helpers/media/mediaManager';
import { useEffect, useState } from 'react';

const useMediaDevicesControls = () => {
  const [webcams, setWebcams] = useState<MediaDeviceInfo[]>(() => mediaManager().webcams.devices());
  const [mics, setMics] = useState<MediaDeviceInfo[]>(() => mediaManager().mics.devices());
  const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>(() => mediaManager().speakers.devices());

  const resetWebcams = () => setWebcams(mediaManager().webcams.devices());
  const resetMics = () => setMics(mediaManager().mics.devices());
  const resetSpeakers = () => setSpeakers(mediaManager().speakers.devices());

  useEffect(() => {
    resetWebcams();
    resetMics();
    resetSpeakers();

    return mediaManager().changeEvent.on(event => {
      if (event.webcamsChanged) resetWebcams();
      if (event.micsChanged) resetMics();
      if (event.speakersChanged) resetSpeakers();
    });
  }, []);

  return {
    mics,
    webcams,
    speakers
  };
};

export default useMediaDevicesControls;
