import * as analytic from '@anm/analytic';
import deleteRequest from '@anm/auth/helpers/logoutRequest';
import { AUTH_COOKIE } from '@anm/constants/auth';
import * as cookie from '@anm/helpers/cookie';

const logout = async () => {
  const res = await deleteRequest();

  cookie.remove(AUTH_COOKIE);
  localStorage.clear();
  analytic.logout();

  return res;
};

const updateAuthCookie = async (token: string | null) => (token ? cookie.set(AUTH_COOKIE, token) : await logout());

export default updateAuthCookie;
