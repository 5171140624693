import { ApiConfig, Request } from '../types';

type HOFWithRequest<T = any> = (
  request: Request,
  apiConfig: ApiConfig
) => (...args: any[]) => Promise<T>;
type ObjectWithRequestHOF<T> = { [key in keyof T]: HOFWithRequest };
type BindedObject<
  T extends {
    [key: string]: HOFWithRequest<T>;
  }
> = { [K in keyof T]: ReturnType<T[K]> };

const bindFor = (request: Request, apiConfig: ApiConfig) => <
  T extends ObjectWithRequestHOF<T>
>(
  obj: T
) =>
  Object.keys(obj).reduce<BindedObject<T>>(
    (result, key: Extract<keyof T, string>) => ({
      ...result,
      [key]: (...args: any[]) => obj[key](request, apiConfig)(...args)
    }),
    {} as BindedObject<T>
  );

export default bindFor;
