import { CommonStats } from 'types/user';

type HelperProps = {
  storageStats: CommonStats | null;
  canPurchase?: boolean;
};

const checkIsStorageError = ({ storageStats }: HelperProps) => {
  // const usedData = storageStats?.data?.used || 0;
  // const availableData = storageStats?.data?.available || 0;

  // const hasAvailableStorage = availableData - usedData > 0;
  // const isStorageError = !canPurchase && !hasAvailableStorage;

  // return isStorageError;

  return storageStats?.state !== 'enabled';
};

export default checkIsStorageError;
