import { Request } from '@anm/api';
import { SECURE_TOKEN_HEADER_NAME } from '@anm/constants/auth';
import { RoomComment } from '@anm/shared/types/room';
import { AxiosResponse } from 'axios';
import { DestinationList, DestinationObject, LiveStream } from 'types/stream';

import {
  ChangeKindParams,
  CreateStreamRequest,
  DeleteStreamProps,
  DuplicateRequestParams,
  EditStreamProps,
  FacebookTokenProps,
  GetCommentsParams,
  GetStreamsByIdsParams,
  GetStreamParams,
  PauseStreamRequest,
  PostCommentParams,
  RemoveDestinationsFromEventProps,
  RemoveGuestDestinationsParams,
  RemoveSocialDestinationRequest,
  ResumeStreamRequest,
  RtmpNetworkRequest,
  StartStreamRequest,
  StopStreamRequest,
  StreamListFilterParams,
  ToggleDestinationsMap,
  TwitchCategory,
  UpdateStreamResolution
} from './types';

export const updateStreamResolution = (request: Request) => ({ streamId, width, height }: UpdateStreamResolution) =>
  request<string>({
    url: '/stream/update-resolution',
    method: 'POST',
    params: { streamId },
    data: { width, height }
  });

export const getStream = (request: Request) => ({ streamId, token }: GetStreamParams) => {
  return request<LiveStream>({
    url: '/stream',
    headers: {
      ...(token && { [SECURE_TOKEN_HEADER_NAME]: token })
    },
    params: { streamId }
  });
};

export const getStreamsByIds = (request: Request) => ({ streamIds, token }: GetStreamsByIdsParams) => {
  return request<LiveStream[]>({
    url: '/streams',
    headers: {
      ...(token && { [SECURE_TOKEN_HEADER_NAME]: token })
    },
    params: { streamIds: streamIds.join() }
  });
};

export const getComments = (request: Request) => {
  let after: number;
  return async ({ streamId, token }: GetCommentsParams) => {
    const res = (await (request<RoomComment[]>({
      url: '/comments-stream',
      params: { after, streamId },
      headers: {
        ...(token && { [SECURE_TOKEN_HEADER_NAME]: token }),
        accept: 'application/json'
      },
      onlyData: false
    }) as unknown)) as AxiosResponse<RoomComment[]>;

    after = res.headers['anm-time'] - 10 * 1000;

    return res.data;
  };
};

export const postComment = (request: Request) => {
  return async ({ streamId, destinationIds, data, token }: PostCommentParams) => {
    return await request<string>({
      method: 'POST',
      url: '/post-comment',
      params: { destinationIds, streamId },
      headers: {
        ...(token && { [SECURE_TOKEN_HEADER_NAME]: token }),
        accept: 'application/json'
      },
      data
    });
  };
};

export const getStreams = (request: Request) => (params: StreamListFilterParams) =>
  request<LiveStream[]>({
    params,
    url: '/list',
    headers: {
      'Cache-Control': 'no-cache'
    }
  });

export const removeStream = (request: Request) => ({ streamId }: DeleteStreamProps) =>
  request<DestinationList>({
    url: '/stream',
    method: 'DELETE',
    params: { streamId }
  });

export const removeDestinationsFromStream = (request: Request) => ({
  streamId,
  destinationIds
}: RemoveDestinationsFromEventProps) =>
  request<DestinationList>({
    url: '/remove-destination',
    method: 'POST',
    params: { streamId },
    data: destinationIds
  });

export const getDestinations = (request: Request) => (query = '') =>
  request<DestinationList>({
    url: `/social${query}`
  });

export const socialCheck = (request: Request) => () =>
  request<DestinationList>({
    url: `/social-check`
  });

export const removeSocialDestination = (request: Request) => ({ destinationId }: RemoveSocialDestinationRequest) =>
  request({
    url: '/remove-social-destination',
    method: 'DELETE',
    params: { destinationId }
  });

export const toggleSocialDestinations = (request: Request) => (map: ToggleDestinationsMap) =>
  request({
    url: '/toggle-social-destinations',
    method: 'POST',
    data: map
  });

export const saveFacebookToken = (request: Request) => (tokenProps: FacebookTokenProps) =>
  request({
    url: '/social/facebook/save-token',
    method: 'POST',
    data: tokenProps
  });

export const createStream = (request: Request) => (data: CreateStreamRequest) =>
  request<LiveStream>({
    data,
    method: 'POST',
    url: '/stream'
  });

export const startStream = (request: Request) => ({ streamId, token }: StartStreamRequest) =>
  request<string>({
    params: { streamId },
    method: 'POST',
    headers: {
      ...(token && { [SECURE_TOKEN_HEADER_NAME]: token })
    },
    url: '/stream-start/now'
  });

export const stopStream = (request: Request) => ({ streamId, token }: StopStreamRequest) =>
  request<string>({
    params: { streamId },
    method: 'POST',
    headers: {
      ...(token && { [SECURE_TOKEN_HEADER_NAME]: token })
    },
    url: '/stream-stop'
  });

export const resumeStream = (request: Request) => ({ streamId }: ResumeStreamRequest) =>
  request<string>({
    params: { streamId },
    method: 'POST',
    url: '/stream-resume'
  });

export const pauseStream = (request: Request) => ({ streamId }: PauseStreamRequest) =>
  request<string>({
    params: { streamId },
    method: 'POST',
    url: '/stream-pause'
  });

export const addRTMPDestination = (request: Request) => (data: RtmpNetworkRequest) =>
  request<LiveStream>({
    data,
    url: '/rtmp',
    method: 'POST'
  });

export const updateStream = (request: Request) => ({ streamId, ...data }: EditStreamProps) =>
  request<LiveStream>({
    data,
    url: '/update-stream',
    method: 'POST',
    params: { streamId }
  });

export const updateStreamGuest = (request: Request) => ({ streamId, ...data }: EditStreamProps) =>
  request<LiveStream>({
    data,
    url: '/update-stream-guest',
    method: 'POST',
    params: { streamId }
  });

export const editRecordingMeta = (request: Request) => ({ streamId, ...data }: EditStreamProps) =>
  request<LiveStream>({
    data,
    url: '/edit-meta-record',
    method: 'POST',
    params: { streamId }
  });

export const changeStreamKind = (request: Request) => (params: ChangeKindParams) =>
  request<string>({
    params,
    url: '/change-kind',
    method: 'POST'
  });

export const checkUrl = (request: Request) => (url: string) =>
  request<string>({
    url,
    method: 'HEAD',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });

export const duplicateStream = (request: Request) => ({ baseUrl, roomIdTo, roomIdFrom }: DuplicateRequestParams) =>
  request<string>({
    method: 'POST',
    baseURL: baseUrl,
    url: `rooms/copy/${roomIdFrom}/${roomIdTo}`
  });

export const getTwitchCategories = (request: Request) => (query: string, baseUrl: string) =>
  request<TwitchCategory[]>({
    params: { query },
    url: 'twitch/category/search',
    baseURL: baseUrl
  });

export const getGuestDestinations = (request: Request) => (streamId: string) =>
  request<Record<string, DestinationObject>>({
    url: '/guest-destinations',
    params: { streamId }
  });

export const getDestinationAvatar = (request: Request) => (streamId: string, destinationId: string) =>
  request<string>({
    url: '/dest-avatar',
    params: { streamId, destinationId }
  });

export const removeGuestDestinations = (request: Request) => (params: RemoveGuestDestinationsParams) =>
  request<string>({
    params,
    method: 'POST',
    url: '/remove-guest-destinations'
  });

export const deleteRecording = (request: Request) => (streamId: string) =>
  request<void>({
    method: 'DELETE',
    url: '/stream-simple',
    params: { streamId }
  });
