import { MODAL_NOTIFICATION_CONTAINER_ID } from '@anm/components/modals/NotificationModal/ModalNotificationRoot';
import { UniversalPortal } from '@anm/helpers/react/portal';
import { useEffect, FC } from 'react';

import { MODAL_CONTAINER_ID } from './ModalRoot';

const MODAL_CONTAINERS_IDS = [MODAL_CONTAINER_ID, MODAL_NOTIFICATION_CONTAINER_ID];

const setBlur = (val: string) => {
  const elements = document.querySelectorAll<HTMLElement>(`#__next > *`);

  elements.forEach(element => {
    if (!MODAL_CONTAINERS_IDS.includes(element.id)) element.style.filter = val;
  });
};

const setOverflow = (val: string) => {
  document.getElementsByTagName('body')[0].style.overflow = val;
};

const ModalPortal: FC = ({ children }) => {
  useEffect(() => {
    setOverflow('hidden');
    setBlur('blur(4px)');

    return () => {
      setOverflow('visible');
      setBlur('none');
    };
  }, []);

  return <UniversalPortal containerSelector="#modal-root">{children}</UniversalPortal>;
};
export default ModalPortal;
