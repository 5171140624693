import { MAIN_MEDIA_STREAM_ID } from '@anm/constants/streaming';
import videoConstrains, { VideoConstrainOptions } from '@anm/data/media/videoConstrains';
import { Logger } from '@anm/helpers/Debugger';
import getUserMedia from '@anm/helpers/media/getUserMedia';
import mediaManager from '@anm/helpers/media/mediaManager';

import { getConstraints } from '../getConstraints';

type CreateStreamSettings = { echoCancellation?: boolean; resolution?: VideoConstrainOptions };
type CreateStreamProps = {
  micId?: string;
  webcamId?: string;
  streamName?: string;
  settings?: CreateStreamSettings;
};

const logger = new Logger('mediastreams');

const createStream = async ({
  micId,
  webcamId,
  settings = {},
  streamName = MAIN_MEDIA_STREAM_ID
}: CreateStreamProps) => {
  const resolutionName = mediaManager().webcams.getCurrentOptions().resolution;
  const defaultEchoCancellation = mediaManager().mics.getCurrentOptions().echoCancellation;
  const { echoCancellation = defaultEchoCancellation, resolution = videoConstrains(resolutionName) } = settings;

  const constraints = {
    ...(micId && getConstraints(micId, { ...mediaManager().mics.getCurrentOptions(), echoCancellation })),
    ...(webcamId && {
      video: {
        deviceId: { exact: webcamId },
        ...resolution
      }
    })
  } as MediaStreamConstraints;
  const timeout = 15000;
  const delayStep = 100;
  let currentDelay = 0;
  try {
    const waitStream = async (resolve: (stream: MediaStream) => void, reject: (msg?: string) => void) => {
      currentDelay = currentDelay + delayStep;
      if (currentDelay >= timeout) {
        reject(`Did not wait for stream creation by webcamId:${webcamId} and micId: ${micId}`);
        return;
      }
      try {
        const stream = await getUserMedia(constraints, streamName);
        stream
          ? resolve(stream)
          : setTimeout(() => {
              waitStream(resolve, reject);
            }, delayStep);
      } catch (e) {
        setTimeout(() => waitStream(resolve, reject), delayStep);
      }
    };

    const stream = await Promise.race([
      new Promise<MediaStream>(waitStream),
      new Promise<null>((_, reject) =>
        setTimeout(() => {
          reject(null);
        }, timeout)
      )
    ]);

    mediaManager().refresh(false);
    return stream;
  } catch (e) {
    logger.error(e);
  }
};

export default createStream;
