import { getParams, toObject } from '@anm/helpers/parseParams';
import compose from 'lodash/fp/compose';
import { useEffect, useState } from 'react';

export type PageParams = { [key: string]: any };

export const getParamsFormSearch = <T extends PageParams>(): T => compose(getParams, toObject)(location.search);

const usePageParams = <T extends PageParams>() => {
  const [pageParams, setPageParams] = useState({} as T);
  useEffect(() => {
    setPageParams(getParamsFormSearch<T>());
  }, []);

  return pageParams;
};

export default usePageParams;
