import Button from '@anm/components/buttons/Button/Button';
import { flexCenter } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { media } from '../../../styles/helpers';
import SectionTitle from '../../SectionTitle';
import { LandingVideoProps } from '../../landing/LandingVideo';

import { StickerPositionType, TextAlignProps } from './types';
import Container from '@anm/components/Container';
import getFromCdn from '@anm/helpers/getFromCdn';

type VideoStepsWrapperProps = {
  fullWidth?: boolean;
};

type VideoStepItemProps = { stickerUrl?: string; stickerPositionVariant?: StickerPositionType };

const TitleHeadOnLaptop = media.laptop`
  font-size: 32px;
  text-align: left;
`;

const TitleDescriptionOnLaptop = media.laptop`
  text-align: left;
`;

const videoStepsTitleFullWidthTablet = media.tablet`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 40px;
    line-height: 50px;
  }

  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
  }
`;

const videoStepsTitleFullWidth = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }

  p {
    font-size: 16px;
    line-height: 25px;
    margin: 16px 0;
    text-align: left;
  }

  ${videoStepsTitleFullWidthTablet};
`;

export const VideoStepsTitle = styled(SectionTitle)<VideoStepsWrapperProps>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 30px;
    text-align: center;
    line-height: 38px;
    ${TitleHeadOnLaptop};
  }

  p {
    text-align: center;
    font-weight: 400;
    margin: 17px 0 28px 0;
    font-size: 20px;
    line-height: 32px;

    a {
      color: #292a2b;
      text-decoration: underline;

      &:hover {
        color: #2294df;
      }
    }

    ${TitleDescriptionOnLaptop};
  }
`;

const VideoStepsDataWrapperOnLaptop = media.laptop<TextAlignProps>`
  width: 35%;
  max-width: 380px;
  ${Button} {
    display: block;
    max-width: fit-content;
    margin: ${({ textAlign }) => (textAlign === 'center' ? '0 auto' : textAlign === 'right' ? '0 0 0 auto' : 0)}
  }
`;

export const VideoStepsDataWrapper = styled.div`
  ${VideoStepsDataWrapperOnLaptop};
`;

export const ButtonWrapper = styled.div`
  margin-top: 60px;
  text-align: center;

  ${Button} {
    padding: 0 20px;

    a {
      ${flexCenter};
      padding: 0 30px;
      font-size: 20px;
      color: #fff !important;
    }
  }
`;

const mediaWrapperOnLaptop = media.laptop`
  margin: 0;
  width: 58%;
`;

const mediaWrapperOnLaptopFullWidth = media.laptop`
  margin: 0;
  width: 65%;
  max-width: 1053px
`;

const dataWrapperOnLaptopFullWidth = media.laptop`
  margin: 0;
  width: 32%;
  max-width: 507px;
`;

const dataWrapperOnTabletFullWidth = media.tablet`
  & > a {
    font-size: 24px;
    line-height: 40px;
  }
`;

const dataWrapperFullWidth = css`
  width: 100%;
  max-width: 100%;
  & > a {
    font-size: 16px;
    line-height: 25px;
  }
  ${dataWrapperOnLaptopFullWidth};
  ${dataWrapperOnTabletFullWidth};
`;

export const MediaWrapper = styled.div<Partial<LandingVideoProps>>`
  width: 100%;
  max-width: 640px;
  min-height: 158px;
  margin: 0 auto 30px auto;
  ${mediaWrapperOnLaptop};

  img {
    display: block;
  }

  ${({ isShadow }) =>
    isShadow &&
    css`
      box-shadow: 0 5px 50px rgba(4, 25, 79, 0.25);
    `};
`;

const wrapperOnLaptop = media.laptop`
  padding: 20px 0 100px;
`;

const wrapperOnLaptopFullWidth = media.laptop`
  padding: 20px 0 0;
`;

export const MainTitle = styled(SectionTitle)`
  margin-bottom: 20px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 36px;
    line-height: 44px;
  }

  p {
    max-width: 740px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 400;
  }
`;

const mainTitleFullWidthTablet = media.tablet`
  margin-bottom: 40px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 60px;
    line-height: 72px;
  }
`;

const mainTitleFullWidth = css`
  margin-bottom: 16px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 800;
  }

  ${mainTitleFullWidthTablet};
`;

const ListItemOnLaptop = media.laptop`
  display: flex;
  text-align: left;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

const NotLastListItemOnLaptop = media.laptop`
  margin-bottom: 40px;
`;

const stickerTopLeftTablet = media.tablet`
    left: 80px;
    right: auto;
    transform: translateY(-50%);
    top: 10%;
`;
const stickerTopLeftLaptop = media.laptop`
    left: 80px;
    right: auto;
    transform: translateY(-50%);
    top: 15%;
`;
const stickerTopLeftDesktop = media.desktop`
    top: 10%;
`;

const stickerTopRightTablet = media.tablet`
    right: 80px;
    left: auto;
    transform: translateY(-50%);
    top: 0;
`;
const stickerTopRightLaptop = media.laptop`
    right: 80px;
    left: auto;
    transform: translateY(-50%);
    top: -25%;
`;

const stickerTopRightDesktop = media.desktop`
    top: -10%;
`;

const stickerBottomLeftTablet = media.tablet`
    top: auto;
    bottom: 0;
    transform: translateY(50%);
    left: 80px;
    right: auto;
`;
const stickerBottomRightTablet = media.tablet`
    top: auto;
    bottom: 0;
    transform: translateY(60%);
    right: 60px;
    left: auto;
`;

const variants: { [key in StickerPositionType]: ReturnType<typeof css> } = {
  'top-left': css`
    ${stickerTopLeftTablet};
    ${stickerTopLeftLaptop};
    ${stickerTopLeftDesktop};
  `,
  'top-right': css`
    ${stickerTopRightTablet};
    ${stickerTopRightLaptop};
    ${stickerTopRightDesktop};
  `,
  'bottom-left': css`
    ${stickerBottomLeftTablet};
  `,
  'bottom-right': css`
    max-width: 20%;
    ${stickerBottomRightTablet};
  `
};

const stickerTablet = media.tablet`
    width: 306px;
    height: 353px;
`;

const stickerLaptop = media.laptop`
    width: 360px;
    height: 415px;
`;

const videoStepsStickerStyles = css<VideoStepItemProps>`
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 120px;
    height: 138px;
    background: ${({ stickerUrl }) => `url(${getFromCdn(stickerUrl!)}) no-repeat center / contain`};
    position: absolute;
    top: 0;
    right: 8px;
    left: auto;
    z-index: 2;
    transform: translateY(-66%);
    max-width: 30%;
    ${({ stickerPositionVariant }) => variants[stickerPositionVariant!]};
    ${stickerTablet};
    ${stickerLaptop};
  }
`;

export const VideoStepsListItemContent = styled.div<{ stickerUrl?: string }>`
  ${({ stickerUrl }) => stickerUrl && videoStepsStickerStyles};
  ${ListItemOnLaptop};
`;

export const VideoStepsListItemWrapper = styled.li<{ index: number; stepBackground?: string }>`
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 50px;
    position: relative;
    z-index: ${({ index }) => index};
    ${NotLastListItemOnLaptop};
  }

  background-color: ${({ stepBackground }) => stepBackground};
`;

const Wrapper = styled.section<VideoStepsWrapperProps>`
  padding: 50px 0;
  ${({ fullWidth }) => (fullWidth ? wrapperOnLaptopFullWidth : wrapperOnLaptop)};

  ${Container} {
    ${({ fullWidth }) => fullWidth && 'max-width: 100%; padding: 0;'};
  }

  ${MediaWrapper} {
    ${({ fullWidth }) => fullWidth && mediaWrapperOnLaptopFullWidth};
  }

  ${VideoStepsDataWrapper} {
    ${({ fullWidth }) => fullWidth && dataWrapperFullWidth};
  }

  ${VideoStepsTitle} {
    ${({ fullWidth }) => fullWidth && videoStepsTitleFullWidth};
  }

  ${MainTitle} {
    ${({ fullWidth }) => fullWidth && mainTitleFullWidth};
  }

  ${VideoStepsListItemContent} {
    ${({ fullWidth }) => fullWidth && 'text-align: left'};
  }
`;

export default Wrapper;
