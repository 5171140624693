import {FC, useEffect, useState} from 'react';

import Select, {SelectOption} from '../select/Select';

import {HrefLang, LANGUAGES_BY_HREFLANG, showInLanguageSwitcher} from './LangScripts';
import {Wrapper} from './Wrapper';

type Language = {
  name: string;
  href: string;
};

const EXCLUDED_PAGES_REGEXP = [
  '^/login',
  '^/signup',
  '^/blog',
  '^/video-templates',
  '^/calendar',
  '^/privacy$',
  '^/terms-of-service$'
];

const LanguageSwitcher: FC = () => {
  const [languages, setLanguages] = useState<Language[]>();
  const [selectedLang, setSelectedLang] = useState<SelectOption>({ label: 'English', value: 'en' });

  useEffect(() => {
    if (EXCLUDED_PAGES_REGEXP.some(r => new RegExp(r).test(location.pathname))) return;

    const langsLinks = document.querySelectorAll('link[hreflang]');
    const linksArray = Array.from(langsLinks) as HTMLLinkElement[];

    const langs = linksArray
      .filter(l => showInLanguageSwitcher(l.hreflang as HrefLang))
      .map(l => ({ href: l.href, name: LANGUAGES_BY_HREFLANG.get(l.hreflang as HrefLang)?.label as string }));

    const currentHref = location.href.includes('http:') ? `https:${location.href.split('http:')[1]}` : location.href; // for debug

    const selectedLink = linksArray.reverse().find(l => l.href === currentHref);

    !languages && setLanguages(langs);

    const selectedCode = selectedLink && selectedLink.hreflang;

    selectedCode &&
      setSelectedLang({
        label: LANGUAGES_BY_HREFLANG.get(selectedCode as HrefLang)?.label as string,
        value: selectedCode,
        href: selectedLink?.href
      });
  }, []);

  const handelLanguageChange = (v: SelectOption) => window.open(v.href, '_self');

  if (!languages) return null;

  const selectOptions = languages.map(l => ({ label: l.name, href: l.href }));

  return (
    // <Wrapper isWideSelect={selectOptions.some(v => v.label.includes('Português'))}>
    <Wrapper isWideSelect={false}>
      <Select name="languages" value={selectedLang} options={selectOptions} onChange={handelLanguageChange} />
    </Wrapper>
  );
};

export default LanguageSwitcher;
