import InlineSelection from '@anm/components/list/InlineSelection';
import { FC } from 'react';

import MoodPicker from '../MoodPicker';
import TextSplittingHint from '../TextSplittingHint';
import VideoDurationPicker from '../VideoDurationPicker';

import { Group, Label, Row, SettingsWrapper } from './Wrapper';

type ModalSettingsProps = {
  timeList: string[];
  activeTextSceneButton: number;
  activeStockAssetsButton: number;
  isVideoDurationAndTextScene?: boolean;
  setMood(mood: string): void;
  setTime(time: number): void;
  handleTextSceneChange(index: number): void;
  setActiveStockAssetsButton(index: number): void;
};

const ModalSettings: FC<ModalSettingsProps> = ({
  timeList,
  activeTextSceneButton,
  activeStockAssetsButton,
  isVideoDurationAndTextScene = true,
  setTime,
  setMood,
  handleTextSceneChange,
  setActiveStockAssetsButton
}) => (
  <SettingsWrapper>
    <Row>
      <Group>
        <Label>Stock assets in your video</Label>
        <InlineSelection
          activeItem={activeStockAssetsButton}
          onChange={setActiveStockAssetsButton}
          list={['All stock assets', 'Preferably free']}
        />
      </Group>
      <MoodPicker onMoodChange={setMood} />
    </Row>
    {isVideoDurationAndTextScene && (
      <Row>
        <VideoDurationPicker {...{ timeList }} itemWidth={160} onTimeChange={setTime} />
        <Group>
          <Label>Texts on the scene</Label>
          <InlineSelection
            activeItem={activeTextSceneButton}
            onChange={handleTextSceneChange}
            list={['Short', 'Medium', 'Long']}
          />
          <TextSplittingHint />
        </Group>
      </Row>
    )}
  </SettingsWrapper>
);

export default ModalSettings;
