import Image from '@anm/components/image/Image';
import { FC } from 'react';

import { AvatarWrapper } from './Wrapper';

export { AvatarWrapper };

export type AvatarProps = {
  src: string;
  alt?: string;
  size?: number;
  href?: string;
};

const Avatar: FC<AvatarProps> = ({ src, alt, href, size = 50 }) => (
  <AvatarWrapper avatarSize={size}>
    {href ? (
      <a {...{ href }}>
        <Image {...{ src, alt }} data-image={'userAvatar'} />
      </a>
    ) : (
      <Image {...{ src, alt }} data-image={'userAvatar'} />
    )}
  </AvatarWrapper>
);

export default Avatar;
