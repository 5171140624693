import { Request } from '../../types';

import { AnalyticsRequestParams, AnalyticsResponse } from './types';

export * from './types';

type GetAnalyticsProps = {
  params: AnalyticsRequestParams;
  analyticsUrl: string;
};

export const getAnalytics = (request: Request) => ({
  params,
  analyticsUrl
}: GetAnalyticsProps) =>
  request<AnalyticsResponse>({
    method: 'GET',
    baseURL: `${analyticsUrl}query`,
    params: {
      period: 'day',
      sort: 'time',
      sortOrder: 'asc',
      ...params
    }
  });
