import { Request } from '../../types';

import { GetStickersResponse, GetStickerRequestParams } from './types';

export * from './types';

export const getTrendingStickers = (request: Request) => (params?: GetStickerRequestParams) =>
  request<GetStickersResponse>({
    method: 'GET',
    url: `/trending`,
    params
  });

export const getStickersBySearchQuery = (request: Request) => (params?: GetStickerRequestParams) =>
  request<GetStickersResponse>({
    method: 'GET',
    url: `/search`,
    params
  });
