import { inputFieldStyles, truncate } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

export type InputVariants =
  | 'copy'
  | 'short'
  | 'simple'
  | 'warning'
  | 'simple-small'
  | 'animated_placeholder'
  | 'with_button_horizontal'
  | 'disabled_gray'
  | 'warning_animated_placeholder';

type InputProps = {
  variant: InputVariants;
  hasError?: boolean;
};

const disabledGreyInputStyles = css`
  height: auto;
  min-height: 42px;
  padding: 14px;
  color: #292a2b;
  font-size: 14px;
  line-height: 18px;
  background-color: #ebeef5;
  border-radius: 5px;
  border: none;
  box-shadow: none;
`;

const errorStyles = css`
  border: 1px solid #ee3d3d;
`;

const variants: { [key in InputVariants]: ReturnType<typeof css> } = {
  copy: css`
    width: 100%;
    height: 40px;
    background: #f5f7fa;
    color: var(--gray_900);
    border: 1px solid #ccdde6;
    box-sizing: border-box;
    border-radius: 5px;
    padding-right: 45px;
    ${truncate('100%')};
  `,
  simple: css`
    padding: 15px;
  `,
  warning: css`
    border: 1px solid #fcbd26;
    box-sizing: border-box;
    border-radius: 5px;
  `,
  'simple-small': css`
    height: 40px;
    padding: 0 15px;
  `,
  short: css`
    height: 40px;
    padding: 10px;
    text-align: center;
    max-width: 60px;
  `,

  animated_placeholder: css`
    padding: 22px 15px 7px 15px;
    &:focus {
      outline: 0 none;
    }
  `,
  with_button_horizontal: css`
    border-radius: 5px 0 0 5px;
    color: #919eaa;
  `,
  disabled_gray: css`
    ${disabledGreyInputStyles};
  `,
  warning_animated_placeholder: css`
    border: 1px solid #fcbd26;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 22px 15px 7px 15px;
    &:focus {
      outline: 0 none;
    }
  `
};

export default styled.input<InputProps>`
  ${inputFieldStyles};
  &:focus {
    outline: 0 none;
    border-color: var(--blue_100);
  }
  ${({ hasError }) => hasError && errorStyles};
  ${({ variant }: InputProps) => [...variants[variant]]};
`;
