import { Request } from '@anm/api';
import { RoomBan } from '@anm/shared/types/ban';
import { RoomSettings } from '@anm/shared/types/room';
import { CreateRoomResponse } from 'types/stream';

import {
  AddAvatarRequest,
  CreateRoomProps,
  DeleteBanParams,
  RoomIdProp,
  SaveRoomSettingsParams,
  UserAvatar
} from './types';

export const createRoom = (request: Request) => ({ baseUrl, streamId, headers }: CreateRoomProps = {}) =>
  request<CreateRoomResponse>({
    url: 'rooms',
    headers,
    params: { streamId },
    method: 'POST',
    ...(baseUrl && { baseURL: baseUrl })
  });

// Bans
export const getUserBans = (request: Request) => ({ roomId }: RoomIdProp) =>
  request<RoomBan[]>({
    url: `ban/user/${roomId}`
  });

export const deleteUserBan = (request: Request) => ({ roomId, userId }: DeleteBanParams) =>
  request<RoomBan[]>({
    method: 'DELETE',
    url: `ban/user/${roomId}/${userId}`
  });

// Avatars
export const getUserAvatars = (request: Request) => () =>
  request<UserAvatar[]>({
    url: `media?tag=avatar`
  });

export const deleteUserAvatar = (request: Request) => (id: number) =>
  request<UserAvatar>({
    method: 'DELETE',
    url: `media/${id}`
  });

export const disableUserAvatar = (request: Request) => (oldCurrentId: number) =>
  request<UserAvatar>({
    method: 'PUT',
    url: `media/${oldCurrentId}/current/false`
  });

export const enableUserAvatar = (request: Request) => (newCurrentId: number) =>
  request<UserAvatar>({
    method: 'PUT',
    url: `media/${newCurrentId}/current/true`
  });

export const addUserAvatar = (request: Request) => (data: AddAvatarRequest) =>
  request<UserAvatar>({
    method: 'POST',
    url: `media`,
    data
  });

// room Settings
export const getRoomSettings = (request: Request) => (roomId: string) =>
  request<RoomSettings>({
    url: `/rooms/${roomId}/settings`
  });

export const saveRoomSettings = (request: Request) => ({ roomId, ...data }: SaveRoomSettingsParams) =>
  request<UserAvatar>({
    method: 'PUT',
    url: `/rooms/${roomId}/settings`,
    data
  });
