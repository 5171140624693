import { AnimatronProject } from 'types/project';

import { Request } from '../../types';

import {
  CreateProjectParams,
  DuplicateProjectParams,
  EmbedInfo,
  GetProjectProps,
  MoveProjectParams,
  ProjectListParams,
  ProjectListResponse,
  UpdateProjectMeta
} from './types';

export * from './types';

const defaultProjectParams = {
  product: 'WAVE'
};

const extendParams = <T extends {}>(params: T = {} as T) => ({
  ...defaultProjectParams,
  ...params
});

export const getProject = (request: Request) => ({ id, baseURL }: GetProjectProps) =>
  request<AnimatronProject>({
    method: 'GET',
    url: `project/${id}`,
    ...(baseURL && { baseURL })
  });

export const getProjectList = (request: Request) => (params: ProjectListParams) =>
  request<ProjectListResponse>({
    params,
    method: 'GET',
    url: 'project/list'
  });

export const moveProject = (request: Request) => (params: MoveProjectParams) =>
  request<{}>({
    params: extendParams(params),
    method: 'PUT',
    url: 'folder/project/move'
  });

export const deleteProject = (request: Request) => (id: string) =>
  request<{}>({
    method: 'DELETE',
    url: `project/${id}`
  });

export const rehash = (request: Request) => async (projectId: string) =>
  request<string>({
    method: 'POST',
    url: `/backend/render/rehash/${projectId}`
  });

export const duplicateProject = (request: Request) => async ({
  newName,
  folderId,
  projectId
}: DuplicateProjectParams) =>
  request<{
    id: string;
  }>({
    url: '/project-api/duplicate',
    method: 'POST',
    params: {
      folderId,
      name: newName,
      oldProjectId: projectId
    }
  });

export const setEmbedStatus = (request: Request) => (embedInfo: EmbedInfo) =>
  request<string>({
    url: `/project-api`,
    method: 'PUT',
    params: embedInfo
  });

export const updateProjectMeta = (request: Request) => ({ meta, projectId }: UpdateProjectMeta) =>
  request<string>({
    url: `backend/project/update/meta/${projectId}`,
    data: meta,
    method: 'PUT'
  });

export const createProject = (request: Request) => ({ hostingId, uploadId, folderId }: CreateProjectParams) =>
  request<string>({
    url: `/project-api/upload/${uploadId}?`,
    method: 'POST',
    params: { folderId, hostingId }
  });
