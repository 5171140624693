import useHover from '@anm/hooks/useHover';
import { ImageLoader } from 'next/image';
import { FC } from 'react';
import styled from 'styled-components';

import ProgressiveImageLoading, { ProgressiveImageLoadingProps } from '../../image/ProgressiveImageLoading';

import VideoContainer from './VideoContainer';

export type ProgressiveVideoPreviewProps = Omit<ProgressiveImageLoadingProps, 'imageSrc' | 'className'> & {
  loader?: ImageLoader;
  videoSrc?: string | null;
  isHovered?: boolean | null;
  previewSrc?: string;

  // isFirstRenderPreview?: boolean;
};

const Wrapper = styled.div`
  z-index: 125;
  overflow: hidden;
  position: relative;
`;

export { Wrapper };

const ProgressiveVideoPreview: FC<ProgressiveVideoPreviewProps> = ({
  videoSrc,
  previewSrc,
  // isFirstRenderPreview,
  isHovered: isOutHovered = null,
  ...otherProps
}) => {
  const [hoverRef, isOwnHovered] = useHover<HTMLDivElement>();
  const isHovered = isOutHovered === null ? isOwnHovered : isOutHovered;

  return (
    <Wrapper ref={hoverRef}>
      <ProgressiveImageLoading
        imageSrc={previewSrc}
        // isFirstRenderImage={isFirstRenderPreview}
        {...otherProps}
      />
      {isHovered && videoSrc && <VideoContainer loop autoPlay muted src={videoSrc} />}
    </Wrapper>
  );
};

export default ProgressiveVideoPreview;
