import getFromCdn from '@anm/helpers/getFromCdn';
import isUrlAbsolute from '@anm/helpers/is/isUrlAbsolute';
import styled, { css } from 'styled-components';

import { SelectProps, SelectSizes, SelectVariants } from '../Select';

type OptionContainerProps = {
  icon?: string;
  selected?: boolean;
  disabled?: boolean;
};

const sizes: { [key in SelectSizes]: ReturnType<typeof css> } = {
  small: css`
    top: 40px;
  `,
  middle: css`
    top: 50px;
  `,
  narrow: css`
    right: 0;
    left: unset;
    width: 90px;
  `
};

const disabledStyles = css`
  &,
  > div,
  a {
    cursor: not-allowed;
    background-color: unset;
    color: #9ba4b9;

    &:hover {
      background-color: unset !important;
    }
  }
`;

const selectedStyles = css`
  background-color: ${({ theme }) => theme.selectOptionActiveBg};
`;

const getIconUrl = (icon: string) => (isUrlAbsolute(icon) ? icon : getFromCdn(icon));

export const optionIconStyle = css<OptionContainerProps>`
  &:before {
    content: '';
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background: ${({ icon }) => `url(${getIconUrl(icon!)}) no-repeat center / contain`};
  }
`;

export const OptionContainer = styled.div<OptionContainerProps>`
  ${({ selected, disabled }) => selected && !disabled && selectedStyles};
  ${({ icon }) => icon && optionIconStyle};
  ${({ disabled }) => disabled && disabledStyles};
`;

const variants: { [key in SelectVariants]: ReturnType<typeof css> } = {
  'with-border': css``,
  'without-border': css``,
  'with-2-columns': css`
    top: 49px;
    & > ${OptionContainer} {
      width: 50%;
    }
  `
};

export default styled.div<Partial<SelectProps>>`
  left: 0;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 13px 0 8px;
  position: absolute;
  flex-direction: row;
  background-color: ${({ theme }) => theme.dropdownAndModalBg};
  box-shadow: 0 5px 30px 0 rgba(4, 25, 79, 0.2);
  width: ${({ selectWidth }) => (selectWidth ? `${selectWidth}px` : '100%')};
  ${({ size }) => sizes[size || 'middle']};
  > div,
  a {
    width: 100%;
    display: flex;
    color: ${({ theme }) => theme.textColor};
    padding: 0 20px;
    cursor: pointer;
    min-height: 45px;
    user-select: none;
    line-height: 45px;
    align-items: center;
    &:hover {
      background-color: ${({ theme }) => theme.selectOptionHoverBg};
    }
  }
  ${({ variant }) => variants[variant || 'with-border']};
`;
