import TEXT_TO_VIDEO_CHECK_LIST from '@anm/data/textToVideoCheckList';
import { FC } from 'react';

import { ChecklistRow, CheckListContent, WrapperImage } from '../Wrapper';

type CheckListProps = { text: string };

const CheckList: FC<CheckListProps> = ({ text }) => (
  <CheckListContent>
    {TEXT_TO_VIDEO_CHECK_LIST.map(({ label, pattern }) => {
      const isValid = pattern.test(text);
      const val = isValid ? 'success' : 'error';

      return (
        <ChecklistRow key={label}>
          <WrapperImage src={`images/icons/validation_${val}.svg`} />
          <span>{label}</span>
        </ChecklistRow>
      );
    })}
  </CheckListContent>
);

export default CheckList;
