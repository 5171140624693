const decodeChar = (c: string) =>
  `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;

export const b64DecodeUnicode = (str: string) =>
  decodeURIComponent(
    atob(str)
      .split('')
      .map(decodeChar)
      .join('')
  );

const encodeChar = (_: string, p1: string) => String.fromCharCode(+`0x${p1}`);

export const b64EncodeUnicode = (str: string) =>
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, encodeChar));
