import Container from '@anm/components/Container';
import MediaTester from '@anm/components/video/MediaTester';
import LandingButton, { LandingButtonProps } from 'landings/components/LandingButton';
import { FC } from 'react';

import Wrapper, { ButtonContainer, SectionTitleWrapper } from './Wrapper';

export type MediaTesterSectionProps = {
  title: string;
  description: string;
  headingLevel?: number;
  button?: LandingButtonProps;
};

const MediaTesterSection: FC<MediaTesterSectionProps> = ({ headingLevel = 2, button, title, description }) => {
  return (
    <Wrapper>
      <Container fullWidth>
        <SectionTitleWrapper level={headingLevel} color="black" {...{ title, description }} />
        <MediaTester />
        <ButtonContainer>
          <LandingButton size="large" variant="orange" {...button} />
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};

export default MediaTesterSection;
