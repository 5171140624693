import App from './components/App';
import { useVideoData } from './components/App/hooks';
import Loader from './components/Loader';
import playerConfig from './config';
import TapToUnmute from './components/TapToUnmute';
import checkCanDownload from './helpers/checkCanDownload';
import getManifest from './helpers/getManifest';
import getVideoIdFromUrl from './helpers/getVideoIdFromUrl';
import mapConfigs from './helpers/mapConfigs';
import parseManifest from './helpers/parseManifest';
import sortByQuality from './helpers/sortByQuality';
import isVideoBlocked from './helpers/isVideoBlocked';

export * from './types';

const { supportedExtensions, analyticsUrl } = playerConfig();

export {
  Loader,
  mapConfigs,
  getManifest,
  analyticsUrl,
  useVideoData,
  sortByQuality,
  parseManifest,
  isVideoBlocked,
  checkCanDownload,
  getVideoIdFromUrl,
  TapToUnmute,
  supportedExtensions
};

export default App;
