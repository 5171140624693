import mediaManager from '@anm/helpers/media/mediaManager';
import useSwitchState from '@anm/hooks/useSwitchState';
import { useCallback, useEffect } from 'react';

import useMediaPending from '../useMediaPending';

const useAllowMicCamControls = () => {
  const { isMediaLoaded } = useMediaPending();
  const [isCamMicAllowed, allowMicCam] = useSwitchState(isMediaLoaded);
  const handleAllowMicCam = useCallback(async () => {
    await mediaManager().refresh(false);
    allowMicCam();
  }, []);

  useEffect(() => {
    isMediaLoaded && !isCamMicAllowed && allowMicCam();
  }, [isMediaLoaded, isCamMicAllowed]);

  return { isCamMicAllowed, allowMicCam: handleAllowMicCam };
};

export default useAllowMicCamControls;
