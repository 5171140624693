import {
  compose,
  hoistStatics,
  lifecycle,
  InferableComponentEnhancer
} from 'recompose';
import { User } from 'user';

import lfConfig from '../config';

import withUser, { WithUserProps } from './withUser';

let intercom: any;

const getIntercom = async () => {
  if (intercom) return intercom;
  intercom = await import('@anm/helpers/intercom');
  return intercom;
};

const updateIntercom = async (
  profile: User | null,
  isUserLogged: boolean,
  isPrevLogged = false
) => {
  const { setUser, isInited, setAnonymous } = intercom;

  if (!isPrevLogged && isUserLogged && profile) {
    setUser(profile);
  }

  if ((isPrevLogged && !isUserLogged) || !isInited) {
    setAnonymous();
  }
};

const { id, enable } = lfConfig.support.intercom;

async function componentDidMount(this: { props: WithUserProps }) {
  const { user: profile, isUserLogged } = this.props;

  intercom = await getIntercom();

  if (!enable) return;
  intercom.load();
  intercom.init(id);

  intercom && updateIntercom(profile, isUserLogged);
}

async function componentDidUpdate(
  this: { props: WithUserProps },
  { isUserLogged: isPrevLogged }: WithUserProps
) {
  const { user, isUserLogged } = this.props;

  if (!enable) return;

  const intercom = await getIntercom();

  intercom && updateIntercom(user, isUserLogged, isPrevLogged);
}

const WithSupport = compose(
  withUser,
  lifecycle<WithUserProps, {}>({ componentDidMount, componentDidUpdate })
) as InferableComponentEnhancer<{}>;

export default hoistStatics(WithSupport);
