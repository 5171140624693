import { FC } from 'react';
import { ProgressButtonProps } from './types';
import { ProgressWrapper } from './Wrapper';

const ProgressButton: FC<ProgressButtonProps> = ({ variant, totalFiles, currentUploadIndex, progressValue }) => {
  const showCounter = totalFiles > 1;
  const counter = showCounter ? `(${currentUploadIndex}/${totalFiles})` : null;

  return (
    <ProgressWrapper progressVariant={variant} variant="gray-system" size="large" value={progressValue}>
      <span>
        <i>{progressValue}</i> Uploading {counter}
      </span>
    </ProgressWrapper>
  );
};

export default ProgressButton;
