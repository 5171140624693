import { media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { TitleBlockColors, TitleBlockDirection, TitleBlockSizes } from '.';

type TitleBlockWrapperProps = {
  size?: TitleBlockSizes;
  color?: TitleBlockColors;
  direction?: TitleBlockDirection;
};

const superLargeTitleOnMobile = media.tablet`
  font-size: 30px;
  line-height: 1.27;
`;

const superLargeDescriptionOnMobile = media.tablet`
  font-size: 18px;
  line-height: 1.78;
`;

export const TitleDescription = styled.div``;
const sizes: { [key in TitleBlockSizes]: ReturnType<typeof css> } = {
  'super-small': css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      margin: 0;
    }
    ${TitleDescription} {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      margin: 4px 0 0;
    }
  `,
  small: css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin: 0;
    }
    ${TitleDescription} {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      margin: 4px 0 0;
    }
  `,
  medium: css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.5;
      margin: 0;
    }
    ${TitleDescription} {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin: 4px 0 0;
    }
  `,
  'medium-thin': css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      margin: 0;
    }
    ${TitleDescription} {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin: 4px 0 0;
    }
  `,
  'super-medium': css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      margin: 0;
    }
    ${TitleDescription} {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
  `,
  large: css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 34px;
      font-weight: 700;
      line-height: 44px;
      margin: 0;
    }
    ${TitleDescription} {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
  `,
  'large-bold': css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      font-weight: 900;
      font-size: 40px;
      line-height: 50px;
    }
    ${TitleDescription} {
      font-weight: normal;
      font-size: 20px;
      line-height: 50px;
    }
  `,
  'super-large': css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 48px;
      font-weight: 700;
      line-height: 54px;
      margin: 0 0 20px;
      ${superLargeTitleOnMobile};
    }
    ${TitleDescription} {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      ${superLargeDescriptionOnMobile};
    }
  `,
  'extra-large': css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 44px;
      font-weight: 700;
      line-height: 1.08;
      margin: 0 0 24px;
      ${superLargeTitleOnMobile};
    }
    ${TitleDescription} {
      font-size: 24px;
      font-weight: normal;
      line-height: 1.5;
      margin-bottom: 44px;
      ${superLargeDescriptionOnMobile};
    }
  `
};

const directions: { [key in TitleBlockDirection]: ReturnType<typeof css> } = {
  left: css``,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
  `
};

const colors: { [key in TitleBlockColors]: ReturnType<typeof css> } = {
  black: css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${({ theme }) => theme.textColor};
    }
    ${TitleDescription} {
      color: ${({ theme }) => theme.textColor};
    }
  `,
  'black-title-gray-description': css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #292a2b;
    }
    ${TitleDescription} {
      color: #919eaa;
    }
  `,
  red: css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #ee3d3d;
    }
    ${TitleDescription} {
      color: #ee3d3d;
    }
  `,
  green: css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #4eda9d;
    }
    ${TitleDescription} {
      color: #4eda9d;
    }
  `,
  blue: css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--blue_100);
    }
    ${TitleDescription} {
      color: var(--blue_100);
    }
  `,
  white: css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }
    ${TitleDescription} {
      color: #fff;
    }
  `,
  yellow: css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fcbd26;
    }
    ${TitleDescription} {
      color: #fcbd26;
    }
  `
};

const TitleBlockWrapper = styled.div<TitleBlockWrapperProps>`
  width: 100%;

  ${({ size }) => sizes[size || 'large']};
  ${({ color }) => colors[color || 'black']};
  ${({ direction }) => directions[direction || 'left']};
`;

export default TitleBlockWrapper;
