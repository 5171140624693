import ButtonCmp from '@anm/components/buttons/Button';
import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle, { Description } from '../../../SectionTitle';

export const Title = styled(SectionTitle)`
  margin-bottom: 32px;
  ${Description} {
    font-size: 24px;
    margin-top: 16px;
    line-height: 40px;
  }
`;

const ColumnMobile = media.phone`
  margin: 0;
`;

export const Column = styled.div`
  ${ColumnMobile};
  flex: 1;
  display: flex;
  margin-right: 30px;
  flex-direction: column;
  &:last-of-type {
    margin-right: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  a {
    padding: 0 20px;
  }
`;

export const Button = styled(ButtonCmp)`
  margin-top: 10px;
  max-width: unset;
  padding: 0 34px;
`;

const GridWrapperMobile = media.phone`
  flex-direction: column;
`;

export const GridWrapper = styled.div`
  ${GridWrapperMobile};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const itemWrapperMobile = media.phone`
img {
    margin: auto;
    display: block;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
  ${itemWrapperMobile};
`;

const Wrapper = styled.section`
  padding: 20px 0 75px;
`;

export default Wrapper;
