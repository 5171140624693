import { Request } from '../../types';

import {
  JobIdFromSourceParams,
  JobIdFromTextParams,
  JobIdResponse
} from './types';

export * from './types';

export const getJobIdFromUrl = (request: Request) => ({
  source,
  ...restParams
}: JobIdFromSourceParams) =>
  request<JobIdResponse>({
    method: 'PUT',
    url: 'storyboard/url',
    data: {
      source,
      ...restParams
    }
  });

export const getJobIdFromText = (request: Request) => ({
  text,
  ...restParams
}: JobIdFromTextParams) =>
  request<JobIdResponse>({
    method: 'PUT',
    url: `storyboard/text`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: { text, ...restParams }
  });
