import styled from 'styled-components';

export const LogoWrapper = styled.a`
  display: flex;
  flex-shrink: 0;
  img {
    max-width: 100%;
  }
`;

export const NoLinkLogoWrapper = LogoWrapper.withComponent('div');
