import useTextAreaOrInputState from '@anm/hooks/useTextAreaOrInputState';
import { useCallback, useState } from 'react';

import { MOODS } from '../components/MoodPicker';
import { useTextOnScene } from '../hooks';
import { GenerateVideoModalProps } from '../types';

export const useCreateVideoFromUrlHandlers = (onCreateVideo: GenerateVideoModalProps['onCreateVideo']) => {
  const [time, setTime] = useState(0); // in seconds
  const [mood, setMood] = useState(MOODS[0]);
  const [text, handleTextChange] = useTextAreaOrInputState('');
  const [activeStockAssetsButton, setActiveStockAssetsButton] = useState(0);
  const [slideLength, activeTextSceneButton, handleTextSceneChange] = useTextOnScene();

  const textValue = text || '';
  const isPremiumStocks = !activeStockAssetsButton;

  const handleCreateVideo = useCallback(() => {
    onCreateVideo({
      mood,
      max_len: time,
      source: textValue,
      max_slide_len: slideLength,
      use_premium_stocks: isPremiumStocks
    });
  }, [mood, isPremiumStocks, textValue, time, slideLength]);

  return [
    textValue,
    activeTextSceneButton,
    activeStockAssetsButton,
    setTime,
    setMood,
    handleTextChange,
    handleCreateVideo,
    handleTextSceneChange,
    setActiveStockAssetsButton
  ] as const;
};
