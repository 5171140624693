import * as analytic from '@anm/analytic';
import ActionsDropDown, { ActionList } from '@anm/components/ActionsDropdown';
import FormatAndStyleModal from '@anm/components/my-projects/FormatAndStyleModal';
import FormatModal from '@anm/components/my-projects/FormatModal';
import VideoFromTextModal from '@anm/components/my-projects/VideoModals/VideoFromTextModal';
import VideoFromUrlModal from '@anm/components/my-projects/VideoModals/VideoFromUrlModal';
import { DEFAULT_DROPDOWN_DELAY } from '@anm/constants/delays';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import useHover from '@anm/hooks/useHover';
import { useCallback, FC } from 'react';

import useFetchTemplatesFormats from '@anm/hooks/useFetchTemplatesFormats';

import {
  ActionListHeading,
  ActionListHeadingWrapper,
  CreateVideoButton,
  HeaderActions,
  HeaderTitles,
  WrapperNewVideo
} from './Wrapper';
import { getDropdownBodyActions, getDropdownHeaderActions, getUserTimeList } from './helpers';
import {
  useGenerateVideoFromLink,
  useGenerateVideoFromText,
  useProjectActionsHandlers,
  useTemplateModal
} from './hooks';
import { NewVideoButtonProps } from './types';
import ProgressButton from '../buttons/ProgressButton';
import useVideoFromAILimit from '../modals/VideoFromAILimitModal/hooks/useVideoFromAILimit';

const NewVideoButton: FC<NewVideoButtonProps> = ({
  apiUrl,
  jobId,
  waveUrl,
  progress,
  editorUrl,
  isPendingJobId,
  streamingStudioUrl,
  timeList: userTimeList,
  onCreateVideo,
  renderUploadButton,
  onCreateVideoFromText,
  onCreateVideoFromLink,
  isUserLogged,
  isUserFree,
  videoFromAILimit,
  autoClose = true,
  currentFolderId,
  renderLinkWrapper,
  ...buttonProps
}) => {
  const [isFormatStyleModal, openFormatStyleModal, handleCloseTemplateModal] = useTemplateModal();

  const [
    isOpenFormatsModal,
    formatModalLinkTarget,
    getFormatUrl,
    handleStocksOpen,
    handleBlankVideo,
    handleBlankImage,
    handleFormatModalClose
  ] = useProjectActionsHandlers({ jobId, waveUrl, editorUrl, isUserLogged, currentFolderId });

  const [
    isOpenGenerateVideoFromLinkModal,
    handleCreateVideoFromLink,
    openGenerateVideoFromLinkModal,
    closeGenerateVideoFromLinkModal
  ] = useGenerateVideoFromLink({ jobId, isUserLogged, onCreateVideoFromLink, openFormatsModal: openFormatStyleModal });

  const [
    isOpenGenerateVideoFromTextModal,
    handleCreateVideoFromText,
    openGenerateVideoFromTextModal,
    closeGenerateVideoFromTextModal
  ] = useGenerateVideoFromText({ jobId, isUserLogged, onCreateVideoFromText, openFormatsModal: openFormatStyleModal });

  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const templates = useFetchTemplatesFormats({
    apiUrl,
    isOpenModal: isOpenGenerateVideoFromLinkModal || isOpenGenerateVideoFromTextModal
  });

  const timeList = getUserTimeList(userTimeList);

  const handleDropdownOpen = useCallback(() => {
    analytic.trackOpenCreateVideoDropdown();
  }, []);

  const dropdownCloseDelay = isMobileOrIPad() ? 0 : DEFAULT_DROPDOWN_DELAY;

  const isCustomizedButton = !!buttonProps.title;

  const [_, limitGuard, renderModal] = useVideoFromAILimit({ limit: videoFromAILimit });

  return (
    <>
      {renderModal()}
      {isOpenGenerateVideoFromLinkModal && (
        <VideoFromUrlModal
          {...{ timeList }}
          isPending={isPendingJobId}
          onCreateVideo={handleCreateVideoFromLink}
          onModalClose={closeGenerateVideoFromLinkModal}
        />
      )}
      {isOpenGenerateVideoFromTextModal && (
        <VideoFromTextModal
          {...{ timeList }}
          isPending={isPendingJobId}
          onCreateVideo={handleCreateVideoFromText}
          onModalClose={closeGenerateVideoFromTextModal}
        />
      )}
      {isOpenFormatsModal && (
        <FormatModal
          {...{ getFormatUrl, isUserFree }}
          linkTarget={formatModalLinkTarget}
          onModalClose={handleFormatModalClose}
        />
      )}
      {isFormatStyleModal && (
        <FormatAndStyleModal
          {...{ templates, jobId, editorUrl }}
          folderId={currentFolderId}
          onCreateVideo={onCreateVideo}
          onModalClose={handleCloseTemplateModal}
        />
      )}
      <WrapperNewVideo ref={hoverRef} data-button="createProject">
        {progress ? (
          <ProgressButton
            totalFiles={1}
            currentUploadIndex={1}
            progressValue={progress}
            variant={buttonProps.variant}
          />
        ) : (
          <CreateVideoButton
            size="large_long"
            icon={isCustomizedButton ? '' : 'images/dashboard/plus_small.svg'}
            iconSize="18x18"
            variant={isHovered ? 'dark-blue' : 'light-blue'}
            {...buttonProps}
          >
            {buttonProps.title || 'Create'}
          </CreateVideoButton>
        )}

        <ActionsDropDown
          isBigIcon
          isDisabledButton={!!progress}
          autoClose={autoClose}
          onOpen={handleDropdownOpen}
          openDelay={dropdownCloseDelay}
          closeDelay={dropdownCloseDelay}
          isModalOnMobile={true}
          openerVariant="no-icon"
          direction={'to-bottom-right-low-tunnel'}
          renderContentHeader={() => (
            <ActionListHeadingWrapper key="heading">
              {!isMobileOrIPad() && (
                <HeaderActions>
                  <ActionList
                    actions={getDropdownHeaderActions({
                      currentFolderId,
                      streamingStudioUrl,
                      renderUploadButton,
                      onBlankVideo: handleBlankVideo,
                      onBlankImage: handleBlankImage
                    })}
                  />
                </HeaderActions>
              )}
              <HeaderTitles>
                <ActionListHeading>{isMobileOrIPad() ? 'Start with' : 'Generate video from'}:</ActionListHeading>
                {!isMobileOrIPad() && <ActionListHeading>Start by browsing:</ActionListHeading>}
              </HeaderTitles>
            </ActionListHeadingWrapper>
          )}
          actions={getDropdownBodyActions({
            currentFolderId,
            streamingStudioUrl,
            renderLinkWrapper,
            renderUploadButton,
            onBlankVideo: handleBlankVideo,
            onBlankImage: handleBlankImage,
            onStocksOpen: handleStocksOpen,
            onGenerateVideoFromLink: limitGuard(openGenerateVideoFromLinkModal),
            onGenerateVideoFromText: limitGuard(openGenerateVideoFromTextModal)
          })}
        />
      </WrapperNewVideo>
    </>
  );
};

export default NewVideoButton;
