const safeOperation = <T>(unsafeFunc: () => T, errorMessage: string): T | null => {
  try {
    return unsafeFunc();
  } catch (e) {
    console.error(errorMessage);
    return null;
  }
};

export const get = <T>(key: string): T | null =>
  safeOperation<T>(() => JSON.parse(localStorage.getItem(key) as string), `Cannot get ${key} from localStorage`);

export const set = <T>(key: string, value: T) => localStorage.setItem(key, JSON.stringify(value));

export const remove = <T>(key: string): T | null => {
  const value = get<T>(key);
  localStorage.removeItem(key);
  return value;
};

const browserStore = {
  get,
  set,
  remove
};

export default browserStore;
