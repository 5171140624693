import createApiInstance from './helpers/createApiInstance';
import presetAxiosInstance from './helpers/presetAxiosInstance';
import liveStreamApi from './liveStreamApi';
import * as analytics from './modules/analytics';
import * as auth from './modules/auth';
import * as brands from './modules/brands';
import * as hosting from './modules/hosting';
import * as pricing from './modules/pricing';
import * as projects from './modules/projects';
import * as stocks from './modules/stocks';
import * as storyboard from './modules/storyboard';
import * as subscription from './modules/subscription';
import templates from './modules/templates';
import * as uploads from './modules/uploads';
import * as user from './modules/user';
import * as userprefs from './modules/userprefs';
import * as video from './modules/video';
import * as videoFolder from './modules/videoFolder';
import * as waveEvents from './modules/waveEvents';
import nodeStreamApi from './nodeStreamApi';
import stickersApi from './stickersApi';
import { ApiConfig } from './types';
export * from './types';

function genApi(apiConfig: ApiConfig) {
  const { bind, request, axiosInstance } = presetAxiosInstance(apiConfig);

  return {
    axiosInstance,
    auth: bind(auth),
    user: bind(user),
    video: bind(video),
    stocks: bind(stocks),
    stream: { ...nodeStreamApi(), ...liveStreamApi() },
    brands: bind(brands),
    pricing: bind(pricing),
    uploads: bind(uploads),
    hosting: bind(hosting),
    projects: bind(projects),
    userprefs: bind(userprefs),
    analytics: bind(analytics),
    storyboard: bind(storyboard),
    waveEvents: bind(waveEvents),
    templates: templates(request),
    stickersApi: stickersApi(),
    videoFolder: bind(videoFolder),
    subscription: bind(subscription)
  };
}

const api = createApiInstance(genApi);

export default api;
