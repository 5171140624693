import styled, { css } from 'styled-components';

import { RadioButtonSizes } from '.';

export const LabelText = styled.span`
  & {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;

    &:after {
      content: '';
      display: none;
      border-radius: 50%;
      background: var(--blue_100);
      position: absolute;
    }
    &:before {
      content: '';
      border-radius: 50%;
      margin-right: 10px;
      box-sizing: border-box;
      border: 2px solid var(--blue_100);
      flex-shrink: 0;
    }
  }
`;

export const Input = styled.input`
  display: none;
  position: relative;

  &:checked + ${LabelText}:after {
    display: block;
  }
`;

const getSizeStyles = (size: RadioButtonSizes) =>
  ({
    [RadioButtonSizes.small]: css`
      &:after {
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
      }
      &:before {
        width: 20px;
        height: 20px;
      }
    `,
    [RadioButtonSizes.big]: css`
      &:after {
        top: 6px;
        left: 6px;
        width: 12px;
        height: 12px;
      }
      &:before {
        width: 24px;
        height: 24px;
      }
    `
  }[size]);

const Wrapper = styled.div<{ size: RadioButtonSizes }>`
  ${LabelText} {
    ${({ size }) => getSizeStyles(size)};
  }
`;

export default Wrapper;
