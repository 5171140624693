import { FC } from 'react';
import Dialog from '@anm/components/modals/Dialog';
import Confirm from '@anm/components/notification/Confirm';
import { WAVE_TEST, WAVE_PROD } from '@anm/constants/domains';
import isDev from '@anm/helpers/is/isDev';

type SignupModalProps = {
  onCancel: () => void;
};

const VideoFromAILimitModal: FC<SignupModalProps> = ({ onCancel }) => {
  const signupUrl = `${isDev() ? WAVE_TEST : WAVE_PROD}signup`;

  return (
    <Dialog onModalClose={onCancel}>
      <Confirm
        title="Please Sign Up to create more videos from text or URL"
        buttonLeft={{
          text: 'Cancel',
          size: 'upper-medium',
          variant: 'silver-border'
        }}
        buttonRight={{
          text: 'Sign Up',
          size: 'upper-medium',
          variant: 'light-blue'
        }}
        onLeftButtonClick={onCancel}
        onRightButtonClick={() => window.open(signupUrl, '_self')}
      />
    </Dialog>
  );
};

export default VideoFromAILimitModal;
