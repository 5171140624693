import isIOS from '@anm/helpers/is/isIOS';
import isMobile from '@anm/helpers/is/isMobile';
import noop from '@anm/helpers/noop';
import useOutsideClick from '@anm/hooks/useOutsideClick';
import React, { useCallback, useRef, FC, MouseEvent } from 'react';

import DropdownContent from './Content';
import ContentWrapper from './ContentWrapper';
import DropdownWrapper from './Wrapper';
import { useAutoClose, useDropdown, useDropdownGroup, useDropdownOutside } from './hooks';

export { DropdownWrapper, useDropdownGroup, DropdownContent, useDropdownOutside };

export type RenderContentControls = {
  isOpenContent: boolean;
  closeDropdown: () => void;
};

export type DropdownProps = {
  direction: DropdownDirections;
  autoClose?: boolean;
  className?: string;
  openDelay?: number;
  canToggle?: boolean;
  closeDelay?: number;
  preventBubbling?: boolean;
  isModalOnMobile?: boolean;
  isDisabledButton?: boolean;
  openByAction?: 'hover' | 'click';
  openerVariant?: DropdownOpenerVariants;
  onOpen?(): void;
  onClose?(): void;
  renderContent?: (controls: RenderContentControls) => JSX.Element | null;
  dropdownContentWidth?: number;
};

export type DropdownGroupProps = {
  delay: number;
  handleDropdownOpen: () => void;
  handleDropdownClose: () => void;
};

export type DropdownDirections =
  | 'to-top'
  | 'to-top-low-tunnel'
  | 'to-top-center'
  | 'to-top-center-with-arrow'
  | 'to-bottom'
  | 'to-bottom-left'
  | 'to-bottom-right'
  | 'to-bottom-right-low-tunnel'
  | 'to-bottom-left-low-tunnel'
  | 'to-bottom-left-with-arrow'
  | 'to-bottom-no-arrow-static'
  | 'to-bottom-from-left-corner'
  | 'to-bottom-from-right-corner'
  | 'to-left'
  | 'to-left-no-arrow'
  | 'to-right';

export type DropdownOpenerVariants =
  | 'dots'
  | 'black'
  | 'blue'
  | 'no-icon'
  | 'default'
  | 'absolute'
  | 'light-blue'
  | 'blue_square'
  | 'transparent_square';

const Dropdown: FC<DropdownProps> = ({
  children,
  direction,
  className,
  openerVariant,
  renderContent,
  openDelay = 0,
  closeDelay = 0,
  canToggle = true,
  openByAction = 'hover',
  autoClose = true,
  preventBubbling = false,
  isModalOnMobile = true,
  isDisabledButton = false,
  onClose,
  onOpen
}) => {
  const isOpenActionByHover = !isMobile() && openByAction === 'hover';

  const [hoverRef, isHovered, isOpened, openDropdown, closeDropdown, handleMouseLeave] = useDropdown({
    openDelay,
    closeDelay,
    canToggle,
    isOpenActionByHover,
    onClose,
    onOpen
  });

  useAutoClose(autoClose, closeDropdown);

  const isOpenContent = !isDisabledButton && isOpened;

  const handleDropdownContentClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      autoClose && closeDropdown();
    },
    [autoClose, closeDropdown]
  );

  const handleOpenDropdownDesktop = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      preventBubbling && e.stopPropagation();
      onOpen?.();
      openDropdown();
    },
    [onOpen, openDropdown, preventBubbling]
  );

  const canOpenOnTouchEnd = useRef(true);

  const handleOpenDropdownMobile = useCallback(() => {
    if (!canOpenOnTouchEnd.current) canOpenOnTouchEnd.current = true;
    else {
      onOpen?.();
      openDropdown();
    }
  }, [onOpen, openDropdown, canOpenOnTouchEnd.current]);

  const onOutsideClickCb = (isMobile() && isModalOnMobile) || !isOpened ? noop : closeDropdown;
  useOutsideClick(hoverRef, onOutsideClickCb);

  const mobileIOS = isMobile() && isIOS();

  const actionEvents = {
    ...(!mobileIOS && !isOpenActionByHover && { onClick: handleOpenDropdownDesktop }),
    ...(mobileIOS &&
      !isOpenActionByHover && {
        onTouchStart: () => (canOpenOnTouchEnd.current = true),
        onTouchMove: () => (canOpenOnTouchEnd.current = false),
        onTouchEnd: handleOpenDropdownMobile
      })
  };

  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const dropdownContentWidth = dropdownContentRef?.current?.offsetWidth;

  return (
    <DropdownWrapper
      onMouseLeave={handleMouseLeave}
      ref={hoverRef}
      {...{
        isOpened,
        isHovered,
        closeDelay,
        isDisabledButton,
        isOpenActionByHover,
        dropdownContentWidth,
        ...actionEvents,
        ...(openerVariant && { openerVariant }),
        ...{ direction }
      }}
    >
      {children}
      {renderContent && (
        <ContentWrapper isOpenContent={isOpened} {...{ isModalOnMobile, closeDropdown }}>
          <DropdownContent
            ref={dropdownContentRef}
            onClick={handleDropdownContentClick}
            isOpen={isOpenContent}
            {...{ ...(className && { className }), direction }}
          >
            {renderContent({ isOpenContent, closeDropdown })}
          </DropdownContent>
        </ContentWrapper>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
