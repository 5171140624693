import { isAnon } from '@anm/auth/helpers/authAnon';
import useVideoFromAILimit from '@anm/components/modals/VideoFromAILimitModal/hooks/useVideoFromAILimit';
import NewVideoButton from '@anm/components/NewVideoButton';
import { UserContext } from '@anm/components/user/UserProvider';
import { useContext, useState, FC } from 'react';

import config from '../../../../config';
import { useCreateVideoFromSource, ROOT_FOLDER_ID } from '../DropdownButton/hooks/useDropdownButton';
import UploadButton from '../UploadButton';

import { CreateVideoProps } from './types';

const CreateVideo: FC<CreateVideoProps> = props => {
  const { plan, isUserLogged } = useContext(UserContext);
  const [progress, setProgress] = useState('');

  const videoFromAILimit = config.guestLimits.maxVideoFromAI;

  const [jobId, handleCreateVideoFromSource, _, isPendingJobId] = useCreateVideoFromSource();
  const [handleCreateVideo] = useVideoFromAILimit({ limit: videoFromAILimit });

  const isUserFree = !!(isAnon() || plan?.name === 'WaveFree');

  return (
    <NewVideoButton
      {...{
        jobId,
        progress,
        isUserFree,
        isUserLogged,
        isPendingJobId
      }}
      autoClose={false}
      title={props.text}
      apiUrl={config.apiUrl}
      waveUrl={config.waveUrl}
      timeList={3}
      editorUrl={config.editorUrl}
      renderUploadButton={children => (
        <UploadButton {...props} onUploadProgress={setProgress} renderChildren={onClick => children(onClick)} />
      )}
      onCreateVideo={handleCreateVideo}
      currentFolderId={ROOT_FOLDER_ID}
      streamingStudioUrl={config.streamingStudioUrl}
      onCreateVideoFromText={params => handleCreateVideoFromSource({ params, source: 'text' })}
      onCreateVideoFromLink={params => handleCreateVideoFromSource({ params, source: 'url' })}
      videoFromAILimit={videoFromAILimit}
      {...props}
    />
  );
};

export default CreateVideo;
