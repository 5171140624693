import { FC } from 'react';

import { ButtonsWrapper, CancelButton, ModalTitle, ModalWrapper, UpgradeButton } from './Wrapper';

export type ActionType = 'stream' | 'upload';

type ComponentProps = {
  onClose: () => void;
  actionType: ActionType;
};

const StorageErrorModal: FC<ComponentProps> = ({ actionType, onClose }) => {
  const descriptionStart = actionType === 'stream' ? 'To create new stream' : 'To upload new file';

  const descriptionEnding = `please either delete some of the videos, upgrade to premium plan or update your payment info to enable storage charges.`;

  return (
    <ModalWrapper onModalClose={onClose}>
      <ModalTitle title="Video storage is full" description={`${descriptionStart}, ${descriptionEnding}`} />
      <ButtonsWrapper>
        <CancelButton variant="white-blue-border-share" size="medium" onClick={onClose}>
          Cancel
        </CancelButton>
        <UpgradeButton variant="light-blue" size="medium" href="/pricing">
          Upgrade
        </UpgradeButton>
        <UpgradeButton variant="light-blue" size="medium" href="/subscription">
          Update payment info
        </UpgradeButton>
      </ButtonsWrapper>
    </ModalWrapper>
  );
};

export default StorageErrorModal;
