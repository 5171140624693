import { Request } from '@anm/api';
import {
  DEFAULT_MEDIA_KEY,
  LAST_USED_BRAND,
  LAST_USED_STREAM_RESOLUTION,
  LIVE_STUDIO_SHOWN,
  MY_ROOM_ID
} from '@anm/constants/keys';

import { StreamingResolution } from '../subscription';

export type UserPrefs = {
  [LAST_USED_BRAND]: string;
  [MY_ROOM_ID]: string;
  [LIVE_STUDIO_SHOWN]: boolean;
  [DEFAULT_MEDIA_KEY]: boolean;
  [LAST_USED_STREAM_RESOLUTION]: StreamingResolution;
};

export const getUserPrefs = (request: Request) => () =>
  request<UserPrefs>({
    url: 'userprefs',
    method: 'GET'
  });

export const updateUserPrefs = (request: Request) => (data: Partial<UserPrefs>) =>
  request<UserPrefs>({
    data,
    url: 'userprefs',
    method: 'POST'
  });

export const updatePrefferedBrand = (request: Request) => (brandId: number) =>
  request<UserPrefs>({
    url: 'userprefs',
    method: 'POST',
    data: {
      [LAST_USED_BRAND]: `${brandId}`
    }
  });
