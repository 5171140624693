import { absoluteCenter, landscapeMedia, stretchBlock } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

export const Video = styled.video<{ isMirror: boolean }>`
  ${stretchBlock};
  z-index: 1;
  transform: ${({ isMirror }) => (isMirror ? 'scale(-1, 1)' : 'none')};
`;

export const Wrapper = styled.div`
  height: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 56.25%;
`;

const broadcastBlockLandscape = landscapeMedia()<{ isSettings?: boolean }>`
  height: 100%;
  max-height: calc(100% - 70px); 
  ${({ isSettings }) =>
    isSettings &&
    css`
      margin: auto;
      max-width: 250px;
    `}
`;

const wrapperLandscape = landscapeMedia()<{ isSettings?: boolean }>`
  height: 100%;
  ${({ isSettings }) =>
    !isSettings &&
    css`
      padding-top: 0;
    `}

`;

export const StartupBroadcastBlock = styled.div<{ isSettings?: boolean }>`
  width: 100%;
  min-height: 150px;
  ${broadcastBlockLandscape};

  ${Wrapper} {
    ${wrapperLandscape};
  }
`;

export const LoaderWrapper = styled.div`
  ${absoluteCenter}
`;

const MediaWrapper = styled.div`
  ${stretchBlock};
`;

export default MediaWrapper;
