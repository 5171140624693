import Container, { ContainerSize } from '@anm/components/Container';
import Button, { PartialButtonProps } from '@anm/components/buttons/Button';
import Image from '@anm/components/image/Image';
import React, { FC } from 'react';

import { CommonLandingProps } from '../../../features/getLanding';
import getWaves from '../../../helpers/getWaves';
import Background from '../../Background';
import FlexContainer from '../../FlexContainer';
import SectionPicture from '../../SectionPicture';

import Content from './Content';
import Wrapper, {
  ButtonWrapper,
  ContentWrapper,
  ImageHolder,
  MainTitle,
  SecondaryTitle
} from './Wrapper';
import { ContentProps, ImageProps } from './types';

export type TextSectionProps = CommonLandingProps &
  ContentProps &
  ImageProps &
  ContainerSize & {
    title: string;
    imageUrl?: string;
    description?: string;
    customButton?: PartialButtonProps;
    isTitleWithDescription?: boolean;
  };

const TextSection: FC<TextSectionProps> = ({
  content,
  size,
  waves,
  title,
  imageUrl = 'images/data/default-text-section-image_png.webp',
  description,
  headingLevel,
  sectionPicture,
  customBackground,
  rotateImage = true,
  imageMeasure,
  customButton,
  isTitleWithDescription = false
}) => (
  <Wrapper>
    <Background
      variant={getWaves(waves) || 'white'}
      customBackground={customBackground}
      isSectionPicture={!!sectionPicture}
    >
      <Container size={size || 1000}>
        {!isTitleWithDescription && (
          <MainTitle
            color="black"
            {...{ title, description }}
            level={headingLevel || 2}
          />
        )}
        <FlexContainer variant="start-center-on-tablet">
          <ImageHolder {...{ rotateImage, imageMeasure }}>
            <Image src={imageUrl} alt={title} />
          </ImageHolder>
          <ContentWrapper>
            {isTitleWithDescription && (
              <SecondaryTitle
                title={title}
                description={description}
                color="black"
                level={headingLevel || 2}
              />
            )}
            <Content {...{ content }} />
            {customButton && (
              <ButtonWrapper>
                <Button
                  size="big"
                  variant="light-blue-lowercase"
                  {...customButton}
                />
              </ButtonWrapper>
            )}
          </ContentWrapper>
        </FlexContainer>
        {sectionPicture && (
          <SectionPicture pictureUrl={sectionPicture} title={title} />
        )}
      </Container>
    </Background>
  </Wrapper>
);

export default TextSection;
