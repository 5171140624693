import AllowMicCam from '@anm/components/media/AllowMicCam';
import useAllowMicCamControls from '@anm/components/media/AllowMicCam/useAllowMicCamControls';
import MediaLoader from '@anm/components/media/MediaLoader';
import useMediaPending from '@anm/components/media/useMediaPending';
import useMediaDevicesSelector from '@anm/hooks/media/useMediaDevicesSelector';
import useMediaStreams from '@anm/hooks/media/useMediaStreams';
import { noop } from 'lodash/fp';
import { FC } from 'react';

import Wrapper, { MediaTesterFormWrapper, StartupBroadcastWrapper } from './Wrapper';
import useAutoSaveHandler from './hooks/useAutoSaveHandler';
import useInitStream from './hooks/useInitStream';
import useInitialValues from './hooks/useInitialValues';

export type MediaTesterProps = {};

const MediaTester: FC<MediaTesterProps> = () => {
  const [{ audioStream, videoStream }, { createStreams }] = useMediaStreams();
  const [webcams = [], mics = [], speakers = []] = useMediaDevicesSelector();
  const handleAutoSave = useAutoSaveHandler(createStreams);
  const initialValues = useInitialValues();

  useInitStream(createStreams);

  return (
    <Wrapper>
      <StartupBroadcastWrapper videoStream={videoStream} />
      <MediaTesterFormWrapper
        {...{ webcams, mics, speakers, audioStream, videoStream, initialValues }}
        onAutoSave={handleAutoSave}
        onSubmit={noop}
      />
    </Wrapper>
  );
};

const MediaTesterGuard: FC<MediaTesterProps> = props => {
  const { isMediaPending } = useMediaPending(true);
  const { isCamMicAllowed, allowMicCam } = useAllowMicCamControls();

  switch (true) {
    case isMediaPending: {
      return <MediaLoader />;
    }
    case !isCamMicAllowed: {
      return <AllowMicCam onAllow={allowMicCam} />;
    }
    default: {
      return <MediaTester {...props} />;
    }
  }
};

export default MediaTesterGuard;
