import { ApiError } from '@anm/api';
import { AuthUserResponse } from '@anm/api/modules/auth';
import { UserSubscriptionDetails } from '@anm/api/modules/user';
import * as authStatusWatcher from '@anm/auth/helpers/authStatusWatcher';
import authLogger from '@anm/auth/helpers/debugger';
import handleUserSessionError from '@anm/auth/helpers/handleUserSessionError';
import resetAppState from '@anm/auth/helpers/resetAppState';
import updateAuthCookie from '@anm/auth/helpers/updateAuthCookie';
import useAnonUser from '@anm/auth/hooks/useAnonUser';
import { AUTH_COOKIE } from '@anm/constants/auth';
import { Logger } from '@anm/helpers/Debugger';
import fetchUser from '@anm/helpers/user/fetchUser';
import { getUserProfile } from '@anm/helpers/user/userLocalStore';
import { isDev } from '@anm/shared/mode';
import { createContext, useCallback, useEffect, useState, FC } from 'react';
import { User as RegisteredUser } from 'user';

type Anon = {
  isPending: boolean;
  error?: ApiError;
  signup: () => Promise<AuthUserResponse | null | undefined>;
  signupAndRun: (cb: () => void) => Promise<void>;
};

type UserContext = {
  anon: Anon;
  user: RegisteredUser | null;
  plan: UserSubscriptionDetails | null;
  logOut: () => void;
  isUserLogged: boolean;
  updateUser(user: Partial<RegisteredUser>): void;
};

export const UserContext = createContext<UserContext>({
  isUserLogged: false
} as UserContext);

export const Provider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;

const logger = new Logger('UserProvider');

export type UserProviderProps = { apiUrl: string };

const UserProvider: FC<UserProviderProps> = ({ apiUrl, children }) => {
  const [user, setUser] = useState<RegisteredUser | null>(null);
  const [isUserLogged, setUserLogged] = useState(false);
  const [anonUser, anonUserActions] = useAnonUser();

  const handleLogin = async () => {
    try {
      const user = await fetchUser(apiUrl);
      setUser(user);
      setUserLogged(true);
      logger.debug(`authenticated as ${user?.displayName}`);
    } catch (err) {
      logger.warn(`error authenticating`, err);
      await handleLogout();
      handleUserSessionError()(err);
    }
  };

  const handleLogout = async () => {
    logger.debug(`logged out from ${user?.displayName}`);
    if (isDev) {
      logger.warn(`SKIPPING USER RESET LOCALLY`);
    } else {
      authLogger.error(`handleLogout in UserProvider`);
      setUser(null);
      setUserLogged(false);
      await updateAuthCookie(null);
    }
  };

  useEffect(() => {
    const user = getUserProfile();
    setUser(user);
    setUserLogged(!!user);

    const clear = authStatusWatcher.run({
      cookieName: AUTH_COOKIE,
      onLogin: handleLogin,
      onLogout: handleLogout,
      onReset: () => {
        logger.info(`app reset`);
        if (isDev) {
          logger.warn(`SKIPPING APP RESET LOCALLY`);
        } else {
          resetAppState();
        }
      }
    });

    return () => {
      clear();
    };
  }, []);

  const updateUser = useCallback((props: Partial<RegisteredUser>) => {
    setUser(prevState => ({ ...prevState, ...props } as RegisteredUser));
  }, []);

  return (
    <Provider
      value={{
        anon: { ...anonUser, ...anonUserActions },
        user,
        plan: (user && user.subscriptionDetails.find(s => s.product === 'WAVE')) || null,
        isUserLogged,
        updateUser,
        logOut: handleLogout
      }}
    >
      {children}
    </Provider>
  );
};
export default UserProvider;
