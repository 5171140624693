import useMediaDevicesControls from '@anm/hooks/media/useMediaDevicesControls';
import { noop } from 'lodash/fp';
import { useEffect, useRef, useState } from 'react';

const useMediaPending = (enableTimeout = false) => {
  const [isMediaPending, setMediaPending] = useState(true);
  const { webcams, mics } = useMediaDevicesControls();
  const isMediaLoaded = !!webcams?.[0]?.deviceId && !!mics?.[0]?.deviceId;
  const clear = useRef(noop);

  useEffect(() => {
    if (enableTimeout) {
      clear.current();
      const clearId = setTimeout(() => {
        isMediaPending && setMediaPending(false);
      }, 3000);

      clear.current = () => clearTimeout(clearId);
    }

    isMediaLoaded && setMediaPending(false);
  }, [isMediaLoaded]);

  return { isMediaPending, isMediaLoaded };
};

export default useMediaPending;
