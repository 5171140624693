import Head from 'next/head';
import { FC } from 'react';

export type LangCode =
  | 'en'
  | 'de'
  | 'es'
  | 'it'
  | 'pt'
  | 'br'
  | 'ca'
  | 'zh'
  | 'ja'
  | 'fr'
  | 'id'
  | 'uk'
  | 'ru'
  | 'ua'
  | 'hu'
  | 'tr'
  | 'sv'
  | 'ro'
  | 'nl'
  | 'fi'
  | 'et'
  | 'el'
  | 'pl'
  | 'da'
  | 'cs'
  | 'lt'
  | 'lv'
  | 'x-default';
export type HrefLang =
  | 'de-de'
  | 'es-es'
  | 'it-it'
  | 'pt-pt'
  | 'pt-br'
  | 'zh-cn'
  | 'ja-jp'
  | 'fr-fr'
  | 'id-id'
  | 'en-us'
  | 'en-gb'
  | 'en-ca'
  | 'uk-ua'
  | 'ru-ru'
  | 'hu-hu'
  | 'tr-tr'
  | 'sv-sv'
  | 'pl-pl'
  | 'ro-ro'
  | 'nl-nl'
  | 'fi-fi'
  | 'et-et'
  | 'el-el'
  | 'da-da'
  | 'cs-cs'
  | 'lt-lt'
  | 'lv-lv'
  | 'x-default';

export type Lang = {
  code: LangCode;
  hreflang: HrefLang;
  label?: string;
  alias?: LangCode;
};

export const SUPPORTED_LANGUAGES: Lang[] = [
  { code: 'x-default', hreflang: 'x-default' },
  { code: 'en', hreflang: 'en-us', label: 'English' },
  { code: 'ca', hreflang: 'en-ca', alias: 'en', label: 'English CA' },
  { code: 'uk', hreflang: 'en-gb', alias: 'en', label: 'English UK' },
  { code: 'pt', hreflang: 'pt-pt', label: 'Português' },
  { code: 'br', hreflang: 'pt-br', alias: 'pt', label: 'Português Brasileiro' },
  { code: 'de', hreflang: 'de-de', label: 'Deutsch' },
  { code: 'es', hreflang: 'es-es', label: 'Español' },
  { code: 'it', hreflang: 'it-it', label: 'Italiano' },
  { code: 'id', hreflang: 'id-id', label: 'Bahasa Indonesia' },
  { code: 'ua', hreflang: 'uk-ua', label: 'Українська' },
  { code: 'fr', hreflang: 'fr-fr', label: 'Français' },
  { code: 'hu', hreflang: 'hu-hu', label: 'Magyarország' },
  { code: 'ja', hreflang: 'ja-jp', label: '日本語' },
  { code: 'ru', hreflang: 'ru-ru', label: 'Русский' },
  { code: 'zh', hreflang: 'zh-cn', label: '中文 (简体)' },
  { code: 'tr', hreflang: 'tr-tr', label: 'Türk' },
  { code: 'sv', hreflang: 'sv-sv', label: 'Svenska'},
  { code: 'pl', hreflang: 'pl-pl', label: 'Polskie'},
  { code: 'ro', hreflang: 'ro-ro', label: 'Română' },
  { code: 'nl', hreflang: 'nl-nl', label: 'Nederlands'  },
  { code: 'fi', hreflang: 'fi-fi', label: 'Suomalainen'  },
  { code: 'et', hreflang: 'et-et', label: 'Eesti Keel'  },
  { code: 'el', hreflang: 'el-el', label: 'Ελληνικά'  },
  { code: 'da', hreflang: 'da-da', label: 'Dansk'  },
  { code: 'cs', hreflang: 'cs-cs', label: 'Čeština'  },
  { code: 'lt', hreflang: 'lt-lt', label: 'Lietuvių'  },
  { code: 'lv', hreflang: 'lv-lv', label: 'Latviski'  }
];

export const LANGUAGES: Map<LangCode, Lang> = SUPPORTED_LANGUAGES.reduce((map, lng) => {
  map.set(lng.code, lng);
  return map;
}, new Map());

export const LANGUAGES_BY_HREFLANG: Map<HrefLang, Lang> = SUPPORTED_LANGUAGES.reduce((map, lng) => {
  map.set(lng.hreflang, lng);
  return map;
}, new Map());

export const isHreflangAlias = (hreflang: HrefLang) => {
  return !!LANGUAGES_BY_HREFLANG.get(hreflang)?.alias;
}

export const showInLanguageSwitcher = (hrefLang: HrefLang) => {
  return hrefLang !== 'x-default' && !isHreflangAlias(hrefLang);
}

export const getAllLanguagesWithAlias = () => {
  return SUPPORTED_LANGUAGES.filter(lang => !!lang.alias).map(lang => lang.code);
}

type LangScriptsProps = {
  languages: string[];
  path?: string;
};

type GetLangHrefProps = {
  languages: string[];
  path: string;
};

const ROOT_CODES = ['x-default', 'en'];

const getLangHref = ({ languages, path }: GetLangHrefProps) =>
  languages.concat(...ROOT_CODES).concat(...getAllLanguagesWithAlias())
    .reduce((acc, code) => acc.includes(code)? acc: [...acc, code], [] as string[])
    .map(code => {
    const { host, protocol, pathname } = new URL(path);

    const hrefLang = LANGUAGES.get(code as LangCode)!.hreflang;
    const href = ROOT_CODES.includes(code) ? path : `${protocol}//${host}/${code}${pathname}`;

    return { hrefLang, href };
  });

const LangScripts: FC<LangScriptsProps> = ({ path, languages }) => (
  <Head>
    {path &&
      getLangHref({ languages, path }).map(({ hrefLang, href }) => (
        <link key={hrefLang} href={href} rel="alternate" hrefLang={hrefLang} />
      ))}
  </Head>
);

export default LangScripts;
