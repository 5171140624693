import Image from '@anm/components/image/Image';
import { FC } from 'react';

import LogoHolder from './LogoHolder';
import { useLogoData } from './hooks';
import { LogoProps } from './types';
export * from './types';

const Logo: FC<LogoProps> = ({ link, variant, waveUrl, studioUrl, className }) => {
  const [href, iconSrc, alt] = useLogoData({
    link,
    waveUrl,
    variant,
    studioUrl
  });

  return (
    <LogoHolder {...{ href, className }}>
      <Image src={iconSrc} alt={alt} />
    </LogoHolder>
  );
};

export default Logo;
