import { DropdownContent } from '@anm/components/Dropdown';
import getFromCdn from '@anm/helpers/getFromCdn';
import styled from 'styled-components';

export const HintText = styled.p`
  line-height: 24px;
  margin: 4px 0 14px;
`;

export const Info = styled.span`
  right: 6px;
  top: 16.5px;
  width: 15px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  background: url(${getFromCdn('images/icons/i-icon.svg')}) center center / contain no-repeat;
`;

export const HintDropdownContent = styled.div`
  width: 220px;
  overflow: auto;
  max-height: 410px;
  padding: 20px 20px 6px;
`;

const Wrapper = styled.div`
  ${DropdownContent} {
    top: -290%;
    border-radius: 10px;
    right: -14px;
    &:after {
      content: '';
      left: -20px;
      top: 57%;
      position: absolute;
      border-style: solid;
      border-width: 9px 10px 9px;
      border-color: transparent #fff transparent transparent;
    }
  }
`;

export default Wrapper;
