import TitleBlock from '@anm/components/TitleBlock';
import SelectInput from '@anm/components/form/SelectInput';
import { SelectOption } from '@anm/components/select/Select';
import isMobile from '@anm/helpers/is/isMobile';
import { Config as FinalFormConfig } from 'final-form';
import { FC } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';

import AutoSave from '../../form/AutoSave';
import FormHelperText from '../../form/FormHelperText';

import { CameraSettingsBlockWrapper, MediaTesterFormWrapper, MicInputWrapper, TestSpeakerWrapper } from './Wrapper';

type TitleProps = {
  children: string;
};

const Title: FC<TitleProps> = ({ children }) => <TitleBlock title={children} level={4} size="super-small" />;

export type MediaTesterFormFields = {
  camera?: SelectOption<string>;
  speaker?: SelectOption<string>;
  microphone?: SelectOption<string>;
};

export type MediaTesterFormProps = {
  mics: SelectOption<string>[];
  webcams: SelectOption[];
  speakers: SelectOption<string>[];
  onAutoSave: (fields: MediaTesterFormFields) => void;
  onSubmit?: FinalFormConfig['onSubmit'];
  className?: string;
  submitError?: string | null;
  audioStream?: MediaStream | null;
  videoStream?: MediaStream | null;
};

const renderForm = ({
  className,
  mics = [],
  webcams = [],
  speakers = [],
  audioStream,
  videoStream,
  submitError,
  onAutoSave
}: MediaTesterFormProps) => ({ handleSubmit, values }: FormRenderProps<MediaTesterFormFields>) => {
  const isSomeMics = mics.length > 1;
  const speakerId = values.speaker?.value;

  return (
    <MediaTesterFormWrapper className={className}>
      <form onSubmit={handleSubmit}>
        <AutoSave debounce={1} onSave={onAutoSave} />

        {submitError && <FormHelperText variant="static">{submitError}</FormHelperText>}

        <Title>Camera</Title>
        <Field
          type="select"
          component={SelectInput}
          name="camera"
          options={webcams}
          label={webcams[0]?.label}
          size="small"
        />
        {videoStream && <CameraSettingsBlockWrapper stream={videoStream} />}

        <Title>Microphone</Title>
        <Field
          type={isSomeMics ? 'select' : 'text'}
          name="microphone"
          size="small"
          label={mics[0]?.label}
          options={mics}
          audioStream={audioStream}
          component={MicInputWrapper}
        />
        {!isMobile() && speakerId && (
          <>
            <Title>Speaker</Title>
            <Field
              type="select"
              name="speaker"
              size="small"
              label={speakers[0]?.label}
              options={speakers}
              component={SelectInput}
            />
          </>
        )}
        {speakerId && <TestSpeakerWrapper speakerId={speakerId} />}
      </form>
    </MediaTesterFormWrapper>
  );
};

const MediaTesterForm: FC<FinalFormConfig & MediaTesterFormProps> = props => (
  <Form render={renderForm(props)} {...props} />
);

export default MediaTesterForm;
