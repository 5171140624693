import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

import SectionTitle from '../../SectionTitle';

const listTitleOnMobile = media.phone`
  max-width: unset;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 28px;
    line-height: 40px;
    margin: 20px 30px;
  }
  p {
    margin-top: unset;
  }
`;

export const ListTitle = styled(SectionTitle)`
  margin: auto;
  max-width: 70%;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 40px;
    line-height: 54px;
    margin: 15px 0 35px;
  }
  p {
    margin: -25px auto 50px;
  }
  ${listTitleOnMobile};
`;

const CardListWrapper = styled.section`
  & > div > a {
    display: block;
    margin: 0 auto 30px;
    max-width: fit-content;
  }
`;

export default CardListWrapper;
