import Button, { PartialButtonProps } from '@anm/components/buttons/Button';
import Modal from '@anm/components/modals/Modal';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import useSwitchState from '@anm/hooks/useSwitchState';
import useModalDimension from '@anm/hooks/useVideoModalDimension';
import { Manifest } from '@anm/hosting-player';
import { FC } from 'react';

import withUser, { WithUserProps } from '../../../HOCs/withUser';
import EnvHostingPlayer from '../../EnvHostingPlayer';

import Wrapper, { ModalFooter, PlayerWrapper } from './Wrapper';
import useEditTemplateLink, { TemplateLinkProps } from './hooks/useEditTemplateLink';
import useSafeAction from '@anm/auth/hooks/useSafeAction';

export type ButtonActions = 'open-template' | 'open-editor';

type ModalContentProps = WithUserProps &
  TemplateLinkProps & {
    videoId: string;
    manifest?: Manifest;
    customButton?: PartialButtonProps;
  };

type PlayerModalProps = ModalContentProps & {
  manifest?: Manifest;
  hasBorder?: boolean;
  onModalClose(): void;
};

const ModalContent: FC<ModalContentProps> = ({ videoId, manifest, customButton, ...otherProps }) => {
  const [isVideoInited, onVideoInited] = useSwitchState();
  const [modalWidth, containerRef] = useModalDimension(isVideoInited, !customButton);

  const getSafeAction = useSafeAction();
  const buttonText = isMobileOrIPad() ? 'Edit on desktop' : 'Edit this template';
  const buttonHref = useEditTemplateLink(otherProps);
  const canCreateAnon = !isMobileOrIPad() && !otherProps.slug;
  const signUpAndOpenEditor = getSafeAction(() => window.open(buttonHref, '_self'));
  const buttonAction = canCreateAnon ? signUpAndOpenEditor : undefined;

  return (
    <Wrapper ref={containerRef} width={modalWidth} {...{ isVideoInited }}>
      <PlayerWrapper>
        <EnvHostingPlayer
          playerWidth={1000} // force player to take best quality
          {...{ videoId }}
          onInit={onVideoInited}
        />
      </PlayerWrapper>
      {customButton && (
        <ModalFooter>
          <Button
            size={isMobileOrIPad() ? 'big' : 'upper-big'}
            variant="yellow"
            children={buttonText}
            {...customButton}
            {...(buttonAction ? { onClick: buttonAction } : { href: buttonHref })}
          />
        </ModalFooter>
      )}
    </Wrapper>
  );
};

const PlayerModal: FC<PlayerModalProps> = ({ onModalClose, ...otherProps }) => (
  <Modal {...{ onModalClose }}>
    <ModalContent {...otherProps} />
  </Modal>
);

export default withUser(PlayerModal);
