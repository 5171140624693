import createStream from '@anm/helpers/media/createStream';
import mediaManager from '@anm/helpers/media/mediaManager';
import mergeStateWrapper from '@anm/helpers/redux/mergeStateWrapper';
import { useCallback, useEffect, useState } from 'react';

import useMediaManagerOptions from '../useMediaManagerOptions';

export type MediaIds = {
  micId: string;
  webcamId: string;
  speakerId: string;
};

const stopAllTracks = (stream: MediaStream) => stream.getTracks().forEach(track => track.stop());

const useMediaStreams = () => {
  const [mediaIds, setMediaIds] = useState<Partial<MediaIds>>();
  const [audioStream, setAudioStream] = useState<MediaStream | null>();
  const [videoStream, setVideoStream] = useState<MediaStream | null>();
  const micOption = useMediaManagerOptions(mediaManager().mics);
  const camOption = useMediaManagerOptions(mediaManager().webcams);

  const muteUnmuteMic = useCallback(async () => {
    if (audioStream?.active) {
      audioStream.getAudioTracks()[0].stop();
      setAudioStream(undefined);
      mediaManager().mics.disable();
    } else {
      setAudioStream(await createStream({ micId: mediaIds?.micId }));
      mediaManager().mics.enable();
    }
  }, [audioStream?.id, mediaIds?.micId]);

  const onOffCamera = useCallback(async () => {
    if (videoStream?.active) {
      videoStream.getVideoTracks()[0].stop();
      setVideoStream(null);
      mediaManager().webcams.disable();
    } else {
      setVideoStream(await createStream({ webcamId: mediaIds?.webcamId }));
      mediaManager().webcams.enable();
    }
  }, [videoStream?.id, mediaIds?.webcamId]);

  useEffect(() => {
    if (!mediaIds?.micId) return;
    const { micId } = mediaIds;

    audioStream && stopAllTracks(audioStream);
    micId && mediaManager().mics.setCurrentDevice(micId);

    (async () => {
      micOption.enable && setAudioStream(await createStream({ micId }));
    })();
  }, [mediaIds?.micId, micOption.enable]);

  useEffect(() => {
    if (!mediaIds?.webcamId) return;
    const { webcamId } = mediaIds;

    (async () => {
      videoStream && stopAllTracks(videoStream);
      webcamId && mediaManager().webcams.setCurrentDevice(webcamId);
      setVideoStream(camOption.enable ? await createStream({ webcamId }) : null);
    })();
  }, [mediaIds?.webcamId, camOption.resolution]);

  useEffect(() => {
    if (!mediaIds?.speakerId) return;
    mediaManager().speakers.setCurrentDevice(mediaIds.speakerId);
  }, [mediaIds?.speakerId]);

  return [
    { audioStream, videoStream },
    { muteUnmuteMic, onOffCamera, createStreams: mergeStateWrapper(setMediaIds) }
  ] as const;
};

export default useMediaStreams;
