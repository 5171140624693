import Card from '@anm/components/Card';
import { dropDownArrow, fadeInAnimation } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { CardVariants } from '../Card';

import { TooltipDirection, TooltipWidth } from '.';

const colors: { [key in CardVariants]: string } = {
  gray: '#f5f7fa',
  white: '#fff',
  black: '#292a2b',
  transparent: 'transparent'
};

type ContentProps = {
  itemWidth?: number;
  itemHeight?: number;
  withArrow?: boolean;
  width?: TooltipWidth;
  variant?: CardVariants;
  direction?: TooltipDirection;
};

type TooltipWrapperProps = {
  renderContent?: () => JSX.Element;
};

type DirectionProps = {
  direction: TooltipDirection;
  itemHeight?: number;
};

export const getDirectionStyles = ({ direction, itemHeight }: DirectionProps) =>
  ({
    'to-bottom': css`
      &:before {
        top: 0;
        right: 0;
        left: 0;
      }
    `,
    'bottom-center': css`
      right: unset;
      transform: translateX(-50%);
      top: 100%;
      left: 50%;
      &:before {
        top: 0;
        right: 0;
        left: 0;
      }
    `,
    'top-center': css`
      right: unset;
      transform: translateX(-50%);
      bottom: 100%;
      left: 50%;
      &:before {
        top: 0;
        right: 0;
        left: 0;
      }
    `,
    'to-top': css`
      transform: translate(calc(-100% + ${itemHeight}px), calc(-100% - 10px));
      top: 0;
      &:before {
        transform: rotate(180deg);
        top: 100%;
      }
    `,
    above: css`
      transform: translate(21px, calc(-100% - 10px));
      top: 0;
      &:before {
        transform: rotate(180deg);
        top: 100%;
        left: calc(100% - 40px);
        border-width: 0 9px 10px 9px;
      }
    `,
    below: css`
      transform: translate(21px, calc(100% + 10px));
      bottom: 0;
      &:before {
        bottom: 0;
        border-width: 0 9px 10px 9px;
        left: calc(100% - 40px);
      }
    `,
    'to-left': css`
      left: 0;
      transform: translateX(-100%) translateY(-50%);
      top: 50%;
      &:before {
        transform: translateX(-50%) rotate(90deg);
        top: 50%;
        left: calc(100% + 2px);
      }
    `,
    'to-right': css`
      right: 0;
      left: unset;
      transform: translateX(calc(100% + 10px)) translateY(-50%);
      top: 50%;
      &:before {
        transform: translateX(-50%) rotate(270deg);
        top: 50%;
        left: -2px;
      }
    `
  }[direction]);

const TooltipWrapper = styled.div<TooltipWrapperProps>`
  cursor: ${({ renderContent }) => (renderContent ? 'pointer' : 'default')};
  position: relative;
`;

export const TooltipLabel = styled.div``;

const noArrowStyles = css`
  &:after {
    content: none;
  }
  &:before {
    content: none;
  }
`;

const arrowStyles = css<ContentProps>`
  ${dropDownArrow};
  &:after {
    content: unset;
  }
  &:before {
    border-width: 0 9px 10px 9px;
    border-color: transparent transparent ${({ variant }) => colors[variant!]} transparent;
  }
  top: unset;
`;

export const TooltipContent = styled(Card)<ContentProps>`
  ${fadeInAnimation};
  opacity: 0;
  z-index: 200;
  cursor: auto;
  margin: 0 auto;
  font-size: 14px;
  visibility: hidden;
  position: absolute;
  transform-origin: top;
  animation-name: fadeIn;
  padding: 15px 20px 20px;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  transition: visibility 0.2s, opacity 0.2s, transform 0.2s ease;
  right: ${({ width }) => (width === 'fit-content' ? 'unset' : 0)};
  color: ${({ variant }) => (variant === 'black' ? '#fff' : '#000')};
  ${({ withArrow }) => (withArrow ? arrowStyles : noArrowStyles)};
  ${({ direction = 'to-bottom', itemHeight }) => getDirectionStyles({ direction, itemHeight })}
`;

export default TooltipWrapper;
