import { ApiConfig } from '@anm/api/types';
import { getAuthToken } from '@anm/auth/helpers/authToken';
import { WAVE_API_PROD, WAVE_API_TEST } from '@anm/constants/domains';
import { isEqual } from 'lodash/fp';

const getDefaultConfig = () => {
  const token = getAuthToken();
  const { TS_ENV } = process.env;
  const baseURL = TS_ENV === 'prod' ? WAVE_API_PROD : WAVE_API_TEST;
  const defaultConfig: ApiConfig = { baseURL, token };

  return defaultConfig;
};

const createApiInstance = <T>(genApi: (apiConfig: ApiConfig) => T) => {
  let instanceApi: T;
  let savedConfig: ApiConfig | null = null;

  return (newConfig?: ApiConfig) => {
    if (!instanceApi || (newConfig && !isEqual(newConfig, savedConfig))) {
      const config = newConfig || getDefaultConfig();
      instanceApi = genApi(config);
      savedConfig = config;
    }

    return instanceApi;
  };
};

export default createApiInstance;
