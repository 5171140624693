import Loader from '@anm/components/Loader';
import { UserContext } from '@anm/components/user/UserProvider';
import VideoAvatar from '@anm/components/video/VideoAvatar';
import { memo, useContext, FC } from 'react';

import MediaWrapper, { LoaderWrapper, StartupBroadcastBlock, Video, Wrapper } from './Wrapper';
import { useMediaRef } from './hooks';

export type StartupBroadcastProps = {
  avatar?: string;
  userName?: string;
  isMirror?: boolean;
  className?: string;
  isSettings?: boolean;
  videoStream?: MediaStream | null;
};

const StartupBroadcast: FC<StartupBroadcastProps> = ({
  avatar,
  userName,
  className,
  isSettings,
  videoStream,
  isMirror = false
}) => {
  const { user } = useContext(UserContext);
  const videoRef = useMediaRef<HTMLVideoElement>(videoStream);

  const content = (() => {
    switch (true) {
      case videoStream === null:
        return <VideoAvatar size={120} avatar={avatar} name={user?.displayName || userName || ''} />;

      case videoStream?.active:
        return <Video ref={videoRef} autoPlay playsInline muted controls={false} {...{ isMirror, isSettings }} />;

      default:
        return (
          <LoaderWrapper>
            <Loader color="blue" size="large" />
          </LoaderWrapper>
        );
    }
  })();

  return (
    <StartupBroadcastBlock className={className} isSettings={isSettings}>
      <Wrapper>
        <MediaWrapper>{content}</MediaWrapper>
      </Wrapper>
    </StartupBroadcastBlock>
  );
};

export default memo(StartupBroadcast, (prevProps, nextProps) => {
  const isVideoStreamSame =
    ((nextProps.videoStream === null || nextProps.videoStream === undefined) &&
      prevProps.videoStream === nextProps.videoStream) ||
    (!!nextProps.videoStream?.id && prevProps.videoStream?.id === nextProps.videoStream?.id);

  const isUserNameSame = prevProps.userName === nextProps.userName;
  const isMirrorSame = prevProps.isMirror === nextProps.isMirror;
  const isAvatarSame = prevProps.avatar === nextProps.avatar;

  return isVideoStreamSame && isUserNameSame && isMirrorSame && isAvatarSame;
});
