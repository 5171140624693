import Container from '@anm/components/Container';
import { media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import SectionTitle, { Description } from '../../SectionTitle';
import { TitleVariant } from '.';
import { WrapperNewVideo } from '@anm/components/NewVideoButton/Wrapper';

type TitleProps = {
  titleVariant: TitleVariant;
  descriptionMaxWidth?: number;
};

const titleMediumOnMobile = media.phone`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 38px;
    line-height: 48px;
  }
  ${Description} {
    font-size: 25px;
    line-height: 34px;
  }
`;

const titleBigOnMobile = media.phone`
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 16px;
  }
  ${Description} {
    font-size: 16px;
    line-height: 25px;
  }
`;

const getTitleStyles = (variant: TitleVariant) =>
  ({
    medium: css`
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: 48px;
        font-weight: 900;
        line-height: 60px;
        margin-bottom: 16px;
      }

      ${Description} {
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
      }

      ${titleMediumOnMobile}
    `,
    big: css`
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-weight: 900;
        font-size: 82px;
        line-height: 90px;
        margin-bottom: 40px;
      }

      ${Description} {
        font-weight: 400;
        font-size: 24px;
        line-height: 40px;
        max-width: 960px;
      }

      ${titleBigOnMobile};
    `
  }[variant]);

export const UltraTitleText = styled(SectionTitle)<TitleProps>`
  text-align: center;

  ${Description} {
    max-width: ${({ descriptionMaxWidth }) => (descriptionMaxWidth ? `${descriptionMaxWidth}px` : '100%')};
  }

  &:last-child {
    margin-bottom: -25px;
  }

  ${({ titleVariant }) => getTitleStyles(titleVariant)};
`;

export const ContainerWrapper = styled(Container)``;

const wrapperOnMobile = media.phone<{ bottomMargin?: number }>`
  margin-top: 20px;
  ${({ bottomMargin }) =>
    bottomMargin &&
    css`
      margin-bottom: ${(bottomMargin * 2) / 3}px;
    `};
`;

export const Wrapper = styled.section<{ bottomMargin?: number }>`
  margin-top: 70px;
  position: relative;
  z-index: 1;
  ${({ bottomMargin }) =>
    bottomMargin &&
    css`
      margin-bottom: ${bottomMargin}px;
    `};
  ${wrapperOnMobile};
`;

const buttonWrapperOnMobile = media.phone`
  margin-top: 16px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;

  a {
    padding: 0 28px;
  }

  ${WrapperNewVideo} {
    max-width: fit-content;
    margin: 0 auto;
  }

  ${buttonWrapperOnMobile};
`;

export default Wrapper;
