import { ESC_NAME } from '@anm/constants/keyCodes';
import { useCallback, useEffect, useRef, FC, KeyboardEvent } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  outline: none;
  overflow-y: auto;
`;

export type EscOnBlockProps = {
  onEsc: () => void;
  className?: string;
};

const EscOnBlock: FC<EscOnBlockProps> = ({ children, onEsc, className }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    container && setTimeout(() => container.focus(), 0);
  }, []);

  const handleKeyDown = useCallback(({ code }: KeyboardEvent<HTMLDivElement>) => code === ESC_NAME && onEsc(), [onEsc]);

  return (
    <Wrapper ref={containerRef} tabIndex={1} className={className} onKeyDown={handleKeyDown}>
      {children}
    </Wrapper>
  );
};

export default EscOnBlock;
