import styled from 'styled-components';

import MediaTesterForm from '../MediaTesterForm';
import StartupBroadcast from '../StartupBroadcast';

export const MediaTesterFormWrapper = styled(MediaTesterForm)`
  max-width: 280px;
`;
export const StartupBroadcastWrapper = styled(StartupBroadcast)`
  max-width: 850px;
  margin-right: 40px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default Wrapper;
