import checkContainMedia from '@anm/helpers/checkContainMedia';
import isValuesEmpty from '@anm/helpers/is/isValuesEmpty';
import useVideoStatusChecker from '@anm/hooks/useVideoStatusChecker';
import isVideoBlocked from '../../helpers/isVideoBlocked';
import isEqual from 'lodash/fp/isEqual';
import { useEffect, useRef, useState } from 'react';

import playerConfig, { EnvType } from '../../config';
import getManifest from '../../helpers/getManifest';
import parseManifest from '../../helpers/parseManifest';
import {
  ApiError,
  Manifest,
  ManifestErrorResponse,
  ManifestSource,
  ParentVideoData,
  VideoStatusCode,
  VideoData
} from '../../types';
import isSupportedCodec from '@anm/helpers/is/isSupportedCodec';
import isStreamVideo from '@anm/helpers/is/isStreamVideo';

type CommonProps = {
  env?: EnvType;
  playerWidth?: number;
};

type VideoReadyProps = {
  hostId: string;
  manifest?: Manifest;
  parentVideoData?: ParentVideoData;
} & CommonProps;

type VideoDataProps = {
  hostId: string;
  env?: EnvType;
  source?: ManifestSource;
  manifest?: Manifest;
  parentVideoData?: ParentVideoData;
} & CommonProps;

type ProtectedDataProps = {
  hostId: string;
  videoInfo?: ParentVideoData;
} & CommonProps;

type VideoInfo = ParentVideoData & { color?: string };

const { supportedExtensions } = playerConfig();

export const useVideoData = ({
  env,
  source = 'api',
  hostId,
  manifest,
  playerWidth,
  parentVideoData
}: VideoDataProps) => {
  const [color, setColor] = useState<string>();
  const [errorCode, setErrorCode] = useState<VideoStatusCode>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [videoData, setVideoData] = useState<VideoData>();

  useEffect(() => {
    if (parentVideoData || !hostId) return;

    (async () => {
      try {
        const response = manifest || ((await getManifest({ hostId, source, env })) as Manifest);

        const containSupportedVideo = checkContainMedia({
          supportedExtensions,
          list: response.main,
          type: 'video'
        });

        const videos = response.main.filter(s => s.type === 'video');
        const isNoVideo = !videos.length;
        const isProcessingError = !!response.error;
        const isBlocked = isVideoBlocked(manifest);
        const isCodecError = videos.length === 1 && !isStreamVideo(videos[0].path) && !isSupportedCodec(videos[0]);
        const isNoVideoError = isNoVideo && !isProcessingError && !isBlocked;
        const isVideoInProcess = !containSupportedVideo && !isNoVideo && !isProcessingError && !isBlocked;

        switch (true) {
          case isBlocked:
            setErrorCode(VideoStatusCode.blocked);
            break;
          case isProcessingError:
            setErrorCode(VideoStatusCode.processingError);
            setErrorMessage(response.error || '');
            break;
          case isCodecError:
            setErrorCode(VideoStatusCode.codecErorr);
            break;
          case isNoVideoError:
            setErrorCode(VideoStatusCode.no_video);
            break;
          case isVideoInProcess:
            setErrorCode(VideoStatusCode.in_process);
        }

        setVideoData(parseManifest(response, playerWidth));
      } catch (err) {
        const { code, message, meta } = err as ManifestErrorResponse;
        setErrorCode(code);
        setErrorMessage(message);
        meta?.color && setColor(meta.color);
      }
    })();
  }, [hostId, playerWidth, parentVideoData]);

  return parentVideoData || { videoData, errorCode, errorMessage, color };
};

export const useProtectedData = ({ env, hostId, videoInfo, playerWidth }: ProtectedDataProps) => {
  const [password, setPassword] = useState<string>();
  const [isPending, setIsPending] = useState(false);
  const [dynamicData, setDynamicData] = useState<VideoData>();
  const [dynamicError, setDynamicError] = useState<VideoStatusCode>();

  useEffect(() => {
    if (!videoInfo?.videoData || isEqual(videoInfo?.videoData, dynamicData)) {
      return;
    }

    setDynamicData(videoInfo.videoData);
  }, [videoInfo?.videoData]);

  useEffect(() => {
    setDynamicError(videoInfo?.errorCode);
  }, [videoInfo?.errorCode]);

  useEffect(() => {
    if (typeof password === 'undefined') return;

    setIsPending(true);

    (async () => {
      try {
        const response = (await getManifest({
          env,
          hostId,
          query: password,
          source: 'cdn'
        })) as Manifest;
        setDynamicData(parseManifest(response, playerWidth));
        setDynamicError(undefined);
        setIsPending(false);
      } catch (err) {
        setIsPending(false);
        const { code } = err as ApiError;
        setDynamicError(code);
      }
    })();
  }, [password, playerWidth]);

  return {
    setPassword,
    dynamicData,
    dynamicError,
    isPending
  };
};

export const useVideoReady = ({ env, hostId, manifest, playerWidth, parentVideoData }: VideoReadyProps) => {
  const [videoInfo, setVideoInfo] = useState<VideoInfo>();
  const initialVideoInfo = useVideoData({
    env,
    hostId,
    manifest,
    playerWidth,
    parentVideoData,
    source: 'cdn'
  });

  const isParentDataExist = !!parentVideoData;

  const isManifestInProcess = !parentVideoData && initialVideoInfo.errorCode === VideoStatusCode.in_process;

  const { isMediaReady: isVideoReady, videoManifest } = useVideoStatusChecker({
    hostId,
    supportedExtensions,
    progress: isManifestInProcess ? 100 : null
  });

  useEffect(() => {
    if ((isValuesEmpty(initialVideoInfo) && !initialVideoInfo.errorCode) || isEqual(initialVideoInfo, videoInfo)) {
      return;
    }

    setVideoInfo(initialVideoInfo);
  }, [initialVideoInfo]);

  useEffect(() => {
    if (!isVideoReady || !videoManifest) return;

    setVideoInfo({ videoData: parseManifest(videoManifest) });
  }, [isVideoReady]);

  return [videoInfo, isParentDataExist, isManifestInProcess] as const;
};

export const usePlayerWidth = (playerWithFromProps?: number) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const [playerWidth, setPlayerWidth] = useState(playerWithFromProps);

  useEffect(() => {
    if (!playerRef.current || playerWidth) return;

    setPlayerWidth(playerRef.current.offsetWidth || 1);
  }, [playerRef.current]);

  return [playerRef, playerWidth] as const;
};
