import InputFile from '@anm/components/InputFile';
import { UserContext } from '@anm/components/user/UserProvider';
import { useContext, FC } from 'react';
import ProgressButton from '@anm/components/buttons/ProgressButton';
import { ButtonWrapper, Label, PreparingAudio } from './Wrapper';
import getExtensions from './helpers/getExtensions';
import useButtonHandler from './hooks/useButtonHandler';
import useUploadVideo from './hooks/useUploadVideo';
import { UploadMediaProps } from './types';
import useStorageCheck from './hooks/useStorageCheck';

const UploadButton: FC<UploadMediaProps> = ({
  multiple = false,
  uploadType = ['video'],
  renderChildren,
  ...otherProps
}) => {
  const { anon, isUserLogged } = useContext(UserContext);
  const [labelRef, handleClick] = useButtonHandler();
  const [checkStorage, renderStorageModal] = useStorageCheck();
  const [progressValue, handleFileChange, filesCount, currentUploadIndex, isPreparingAudio] = useUploadVideo({
    checkStorage,
    ...otherProps
  });

  const extensionsList = getExtensions(uploadType);

  return (
    <>
      {renderStorageModal()}
      {!!progressValue ? (
        <ProgressButton
          {...{ totalFiles: filesCount, currentUploadIndex, progressValue }}
          variant={otherProps.variant}
        />
      ) : isPreparingAudio ? (
        <PreparingAudio variant="yellow" size="large" icon="images/icons/icon_replace.svg">
          Preparing audio
        </PreparingAudio>
      ) : (
        <Label ref={labelRef}>
          <InputFile
            handleUpload={handleFileChange}
            accept={extensionsList}
            disabled={!!progressValue}
            multiple={multiple}
          />
          {renderChildren?.(handleClick) || (
            <ButtonWrapper isPending={anon.isPending} onClick={handleClick} isLogged={isUserLogged} {...otherProps}>
              {otherProps.text || 'Upload video'}
            </ButtonWrapper>
          )}
        </Label>
      )}
    </>
  );
};

export default UploadButton;
