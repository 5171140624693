import useTextAreaOrInputState from '@anm/hooks/useTextAreaOrInputState';
import { useCallback, useState } from 'react';

import { MOODS } from '../components/MoodPicker';
import { useTextOnScene } from '../hooks';
import { GenerateVideoModalProps } from '../types';

export const useCreateVideoFromTextHandlers = (onCreateVideo: GenerateVideoModalProps['onCreateVideo']) => {
  const [title, handleTitleChange] = useTextAreaOrInputState('');
  const [text, handleTextChange] = useTextAreaOrInputState('');

  const [time, setTime] = useState(0); // in seconds
  const [mood, setMood] = useState(MOODS[0]);
  const [activeSummarizeText, setActiveSummarizeText] = useState(0);
  const [activeStockAssetsButton, setActiveStockAssetsButton] = useState(0);
  const [slideLength, activeTextSceneButton, handleTextSceneChange] = useTextOnScene();

  const textValue = text || '';
  const isUseTextIs = !activeSummarizeText;
  const isPremiumStocks = !activeStockAssetsButton;

  const handleCreateVideoFromText = useCallback(
    () =>
      onCreateVideo({
        mood,
        max_len: time,
        text: textValue,
        max_slide_len: slideLength,
        full_text_summary: isUseTextIs,
        use_premium_stocks: isPremiumStocks,
        ...(title && { title })
      }),
    [title, mood, textValue, slideLength, time, isUseTextIs, isPremiumStocks]
  );

  return [
    text,
    title,
    textValue,
    isUseTextIs,
    activeSummarizeText,
    activeTextSceneButton,
    activeStockAssetsButton,
    setTime,
    setMood,
    handleTextChange,
    handleTitleChange,
    handleTextSceneChange,
    setActiveSummarizeText,
    handleCreateVideoFromText,
    setActiveStockAssetsButton
  ] as const;
};
