import isMobile from '@anm/helpers/is/isMobile';

export type VideoResolution = 'vga' | 'hd' | 'fhd';

export const sizesMap = { vga: 480, hd: 720, fhd: 1080 };
export const resolutionMap = { 480: 'vga', 720: 'hd', 1080: 'fhd' };

export type VideoConstrainOptions = Pick<MediaTrackConstraintSet, 'width' | 'height' | 'aspectRatio' | 'frameRate'>;

const videoConstrains = (res: VideoResolution): VideoConstrainOptions => {
  // looks like for aspect to work it should be like this, rounded a bit
  const aspect = 1.7777777778; // 1920 / 1080;

  if (isMobile()) {
/*
    const vertical = getOrientation() === 'vertical';

    if (vertical) {
      return {
        height: {ideal: Math.round(sizesMap[res] * aspect + 0.3)},
        width: {ideal: sizesMap[res]},
      }
    }
*/
    return {
      width: {ideal: Math.round(sizesMap[res] * aspect + 0.3)},
      height: {ideal: sizesMap[res]},
    }
  } else {
    return {
      width: {ideal: Math.round(sizesMap[res] * aspect + 0.3)},
      aspectRatio: { ideal: aspect },
      frameRate: { ideal: 30 }
    }
  }
};

export default videoConstrains;
