type MediaType = 'image' | 'video' | 'audio' | 'all';

type HelperProps = {
  mediaType: MediaType;
  extensions: {
    supportedImageExtensions: string[];
    supportedVideoExtensions: string[];
    supportedAudioExtensions: string[];
  };
};

const mapExtensions = (list: string[]) => list.map(e => `.${e}`).join();

const getAllowedExtensions = ({ mediaType, extensions }: HelperProps) => {
  switch (true) {
    case mediaType === 'image':
      return mapExtensions(extensions.supportedImageExtensions);

    case mediaType === 'video':
      return mapExtensions(extensions.supportedVideoExtensions);

    case mediaType === 'audio':
      return mapExtensions(extensions.supportedAudioExtensions);

    case mediaType === 'all':
      return mapExtensions([
        ...extensions.supportedAudioExtensions,
        ...extensions.supportedImageExtensions,
        ...extensions.supportedVideoExtensions
      ]);

    default:
      return mapExtensions(extensions.supportedImageExtensions);
  }
};

export default getAllowedExtensions;
