import * as analytic from '@anm/analytic';
import { Action } from '@anm/components/ActionsDropdown';
import { Item } from '@anm/components/ActionsDropdown/ActionItem';
import createArray from '@anm/helpers/array/createArray';
import getFromCdn from '@anm/helpers/getFromCdn';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import noop from '@anm/helpers/noop';
import convertJobIdParamsToAnalytic from '@anm/helpers/storyboard/convertJobIdParamsToAnalytic';
import { DropdownActionsParams, GetDropdownHeaderActions, TrackCreateVideoFromAIProps } from './types';
import { JobIdFromSourceParams } from '@anm/api/modules/storyboard';
import { MouseEvent } from 'react';

const getDesktopOnlyHandler = (handler: (e: MouseEvent) => void) => (isMobileOrIPad() ? noop : handler);

const newVideoIsDisabled = isMobileOrIPad();
const newImageIsDisabled = isMobileOrIPad();
const newRecordingIsDisabled = isMobileOrIPad();

export const getDropdownHeaderActions = ({
  onBlankVideo,
  onBlankImage,
  renderUploadButton,
  currentFolderId,
  streamingStudioUrl
}: GetDropdownHeaderActions): Action[] =>
  [
    {
      icon: `${getFromCdn('images/icons/blank_video_ic_V1.svg')}`,
      desktopOnly: true,
      disabled: newVideoIsDisabled,
      renderComponent: () => <Item name="Blank video" handler={getDesktopOnlyHandler(onBlankVideo)} />
    },
    {
      icon: `${getFromCdn('images/icons/blank_image_ic_V4.svg')}`,
      desktopOnly: true,
      disabled: newImageIsDisabled,
      renderComponent: () => <Item name="Blank image" handler={getDesktopOnlyHandler(onBlankImage)} showBeta />
    },
    {
      icon: `${getFromCdn('images/icons/video_recording_ic_V1.svg')}`,
      desktopOnly: true,
      disabled: newRecordingIsDisabled,
      renderComponent: () => (
        <Item
          name="Video recording"
          target="_blank"
          showBeta
          href={`${streamingStudioUrl}new-recording?folder=${currentFolderId}&skipSetup=yes`}
        />
      )
    },
    renderUploadButton && {
      icon: `${getFromCdn('images/icons/upload_ic.svg')}`,
      desktopOnly: true,
      renderComponent: () =>
        renderUploadButton(onClick => <Item name="Upload" handler={getDesktopOnlyHandler(onClick)} />)
    }
  ].filter(Boolean) as Action[];

export const getDropdownBodyActions = ({
  currentFolderId,
  streamingStudioUrl,
  onBlankVideo,
  onBlankImage,
  onStocksOpen,
  onGenerateVideoFromLink,
  onGenerateVideoFromText,
  renderLinkWrapper,
  renderUploadButton
}: DropdownActionsParams) =>
  [
    ...(isMobileOrIPad()
      ? getDropdownHeaderActions({
          onBlankVideo,
          onBlankImage,
          renderUploadButton,
          currentFolderId,
          streamingStudioUrl
        })
      : []),
    {
      icon: `${getFromCdn('images/icons/text_hover_ic.svg')}`,
      desktopOnly: true,
      disabled: isMobileOrIPad(),
      renderComponent: () => <Item name="Text" handler={getDesktopOnlyHandler(onGenerateVideoFromText)} />
    },
    {
      name: 'Templates',
      icon: `${getFromCdn('images/icons/templates_hover_ic.svg')}`,
      renderLink: (children: JSX.Element) =>
        renderLinkWrapper ? (
          renderLinkWrapper(
            <a data-button="video templates" onClick={() => analytic.trackCreateVideoTemplatesChoosen()}>
              {children}
            </a>
          )
        ) : (
          <a
            data-button="video templates"
            href="./video-templates"
            onClick={() => analytic.trackCreateVideoTemplatesChoosen()}
          >
            {children}
          </a>
        )
    },
    {
      icon: `${getFromCdn('images/icons/blog_hover_ic.svg')}`,
      desktopOnly: true,
      disabled: isMobileOrIPad(),
      renderComponent: () => <Item name="Blog post" handler={getDesktopOnlyHandler(onGenerateVideoFromLink)} />
    },
    {
      icon: `${getFromCdn('images/icons/stocks_hover_ic.svg')}`,
      desktopOnly: true,
      disabled: isMobileOrIPad(),
      renderComponent: () => <Item name="Stock assets" handler={getDesktopOnlyHandler(onStocksOpen)} />
    }
  ].filter(Boolean) as Action[];

export const getUserTimeList = (arrayLength: number) =>
  createArray(arrayLength, 0).map(t => (t === 0 ? `30 sec` : `${t} min`));

export const trackCreateVideoFromAI = ({ params, jobParams }: TrackCreateVideoFromAIProps) => {
  if (!jobParams) return;

  const isFromBlog = !!(jobParams as JobIdFromSourceParams).source;
  const isFromTextAsIs = !!jobParams.full_text_summary;
  const isFromReducedText = !isFromBlog && !isFromTextAsIs;

  const analyticParams = convertJobIdParamsToAnalytic({ ...jobParams, ...params });

  isFromBlog && analytic.trackCreateVideoFromBlogSubmit(analyticParams);
  isFromTextAsIs && analytic.trackCreateVideoFromTextAsIsSubmit(analyticParams);
  isFromReducedText && analytic.trackCreateVideoFromReducedTextSubmit(analyticParams);
};
