import { ANNUAL } from '@anm/constants/subscription';

import { Request } from '../../types';

import { GetSaleParams, SaleResponse } from './types';
export * from './types';

export const getPricingContent = () => async () => (await import('@anm/data/pricing/pricingContent')).default;

export const getSale = (request: Request) => ({ period, saleUrl }: GetSaleParams) => {
  const fileName = period === ANNUAL ? 'sale_annual.json' : 'sale_monthly.json';

  return request<SaleResponse>({
    url: fileName,
    method: 'GET',
    baseURL: saleUrl
  });
};
