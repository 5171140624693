import { Landing, SaveLandingData } from '@anm/api/modules/hosting';

import { Request } from '../../types';

import { getThrottleAdapter } from './../../helpers/cacheAdapters';
import {
  CreateNewVideoData,
  CreateVideoResponse,
  GetFileUploadUrlRequest,
  GetLandingRequest,
  GetManifestResponse,
  GetUserHostingVideoFoldersRequest,
  HostingElement,
  HostingUser,
  ReplaceVideoParams,
  UpdateVideoData,
  UpdateVideoMetaData,
  UploadFileProps,
  UploadFileResponse
} from './types';

export * from './types';

export const getHostedVideoFolders = (request: Request) => ({ folder }: GetUserHostingVideoFoldersRequest) =>
  request<HostingElement[]>({
    url: `v2/hosting/folder/data/list`,
    method: 'GET',
    data: { path: folder }
  });

export const updateVideoData = (request: Request) => ({ hostId, ...data }: UpdateVideoData | UpdateVideoMetaData) =>
  request<string>({
    data,
    url: `v2/host/${hostId}`,
    method: 'PUT'
  });

export const createNewVideo = (request: Request) => (data: CreateNewVideoData) =>
  request<CreateVideoResponse>({
    url: `v2/hosting`,
    method: 'POST',
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const createNewUpload = (request: Request) => (data: CreateNewVideoData) =>
  request<CreateVideoResponse>({
    url: `v2/upload`,
    method: 'POST',
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const replaceVideo = (request: Request) => ({ hostId, uploadId }: ReplaceVideoParams) =>
  request({
    url: `v2/host/${hostId}`,
    method: 'PUT',
    data: { uploadId }
  });

// TODO use from uploads module
// export const createNewUpload = (request: Request) => ({
//   baseURL,
//   nameSpace = 'upload',
//   simpleUpload,
//   ...data
// }: CreateNewUpload) =>
//   request<CreateNewUploadResponse>({
//     url: `v2/${nameSpace}`,
//     method: 'POST',
//     data: JSON.stringify(data),
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     ...(baseURL && { baseURL }),
//     ...(simpleUpload && { params: { simpleUpload } })
//   });

// export const createUploadFolder = (request: Request) => ({ path, baseUrl }: CreateUploadFolderParams) =>
//   request<CreateUploadFolderResponse>({
//     baseURL: baseUrl,
//     url: `v2/upload/folder`,
//     method: 'POST',
//     data: JSON.stringify([path]),
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   });

// const defaultUploadFolderRequestProps: Partial<GetUploadsFolderDataRequest> = {
//   page: '1',
//   order: 'desc',
//   pageSize: '60'
// };

// export const getUploadsFolderData = (request: Request) => ({ baseUrl, ...data }: GetUploadsFolderDataRequest) =>
//   request<GetUploadsFolderDataResponse>({
//     baseURL: baseUrl,
//     url: `v2/upload/folder/data/list`,
//     method: 'GET',
//     params: { ...data, ...defaultUploadFolderRequestProps },
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   });

export const uploadNewVideo = (request: Request) => ({ file, url, token, onUploadProgress }: UploadFileProps) =>
  request<string>({
    onUploadProgress,
    data: file,
    method: 'PUT',
    baseURL: url,
    cancelToken: token
  });

// export const deleteUpload = (request: Request) => ({ id, baseURL }: DeleteUploadProps) =>
//   request<string>({
//     url: `v2/media/${id}`,
//     method: 'DELETE',
//     ...(baseURL && { baseURL })
//   });

export const getVideoUploadUrl = (request: Request) => ({ hostId, extension }: GetFileUploadUrlRequest) =>
  request<UploadFileResponse>({
    url: `v2/media/${hostId}/file`,
    method: 'POST',
    data: { extension, version: 'main' }
  });

export const getPosterUploadUrl = (request: Request) => ({ hostId, extension }: GetFileUploadUrlRequest) =>
  request<UploadFileResponse>({
    url: `v2/host/${hostId}/preview`,
    method: 'POST',
    data: { extension }
  });

export const uploadFile = (request: Request) => ({ file, url }: UploadFileProps) =>
  request<string>({
    data: file,
    method: 'PUT',
    baseURL: url
  });

const cacheAdapter = getThrottleAdapter(15 * 1000);

export const getManifest = (request: Request) => (hostId: string) =>
  request<GetManifestResponse>({
    url: `v2/host/${hostId}/manifest`,
    method: 'GET',
    adapter: cacheAdapter
  });

export const getHostingUser = (request: Request) => () =>
  request<HostingUser>({
    url: `v2/hosting/user`,
    method: 'GET'
  });

export const getMedia = (request: Request) => (uploadId: string) =>
  request<GetManifestResponse>({
    url: `v2/media/${uploadId}`,
    method: 'GET'
  });

export const getLandingPage = (request: Request) => ({ hostId }: GetLandingRequest) =>
  request<Landing>({
    url: `v2/host/${hostId}/page`,
    method: 'GET'
  });

export const updateLandingData = (request: Request) => ({ hostId, ...data }: SaveLandingData) =>
  request<Landing>({
    data,
    url: `v2/host/${hostId}/page`,
    method: 'PUT'
  });

// TODO use from uploads module
// export const getUpload = (request: Request) => ({ uploadId, baseURL }: GetUploadProps) =>
//   request<UploadMediaFile>({
//     url: `v2/media/${uploadId}`,
//     method: 'GET',
//     ...(baseURL && { baseURL })
//   });

// TODO use from uploads module
// export const getUploadFile = (request: Request) => ({ uploadId, baseURL }: GetUploadProps) =>
//   request<GetUploadFileResponse>({
//     url: `v2/media/file/${uploadId}`,
//     method: 'GET',
//     ...(baseURL && { baseURL })
//   });

// export const getUploadByProjectId = (request: Request) => ({ projectId, baseURL }: GetUploadByProjectIdProps) =>
//   request<UploadMediaFile>({
//     url: `v2/media/find`,
//     method: 'GET',
//     params: { projectId },
//     ...(baseURL && { baseURL })
//   });
