import Button from '@anm/components/buttons/Button';
import { FC } from 'react';

import { LandingButtonOwnProps } from '../..';
import lfConfig from '../../../../config';

const { waveUrl } = lfConfig;

export type FindTemplateProps = LandingButtonOwnProps & { slug?: string };

const FindTemplate: FC<FindTemplateProps> = ({ href, slug, ...props }) => {
  const url = href || `${waveUrl}video-templates/${slug}`;

  return (
    <Button href={url} {...props}>
      Find template
    </Button>
  );
};

export default FindTemplate;
