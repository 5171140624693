import { Request } from '../../types';

import {
  CurrentWaveEventParams,
  WaveEvent,
  WaveEventListParams,
  WaveEventListResponse
} from './types';
export * from './types';

const getBaseURL = (calendarDataUrl: string) =>
  `${calendarDataUrl}inspiration-calendar/`;

export const getWaveEventList = (request: Request) => ({
  weekDate,
  calendarBaseURL
}: WaveEventListParams) =>
  request<WaveEventListResponse>({
    url: `${weekDate}/week.json`,
    method: 'GET',
    baseURL: getBaseURL(calendarBaseURL)
  });

export const getWaveEvent = (request: Request) => ({
  weekDate,
  waveEventId,
  calendarBaseURL
}: CurrentWaveEventParams) =>
  request<WaveEvent>({
    url: `${weekDate}/${waveEventId}.json`,
    method: 'GET',
    baseURL: getBaseURL(calendarBaseURL)
  });
