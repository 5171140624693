import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  padding: 40px 0 30px 0;
  text-align: center;
`;

const ContentWrapperOnMobile = media.phone`
  padding: 20px 0 40px 0;
`;

export const ContentWrapper = styled.div`
  padding: 20px 0 90px 0;
  ${ContentWrapperOnMobile};
`;

const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
`;

export const HostingPlayerContainer = styled.div`
  pointer-events: none;
`;

export default Wrapper;
