import api from '@anm/api';
import checkIsStorageError from '@anm/helpers/checkIsStorageError';
import StorageErrorModal from '@anm/components/StorageErrorModal';
import { useState } from 'react';

const useStorageCheck = () => {
  const [isStorageError, setIsStorageError] = useState(false);

  const checkStorage = async () => {
    const storageStats = await api().user.getUserStorageStats();
    const isError = checkIsStorageError({ storageStats });
    isError && setIsStorageError(true);

    return isError;
  };

  const renderStorageModal = () =>
    isStorageError ? <StorageErrorModal actionType="upload" onClose={() => setIsStorageError(false)} /> : null;

  return [checkStorage, renderStorageModal] as const;
};

export default useStorageCheck;
