import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiError } from '../types';

type CreateErrorHandlerProps = { isDebug?: boolean };

const getInfoFromConfig = ({ url, baseURL, method, headers, params }: AxiosRequestConfig) => ({
  url,
  method,
  params,
  headers,
  baseURL
});

const getErrorFromSuccessResponse = (err: AxiosResponse<ApiError>): ApiError =>
  err.data || {
    code: err.status || 444,
    message: err.statusText || 'no response'
  };

const getErrorFromFailureResponse = (err: AxiosError<unknown>) => ({
  data: err.response?.data,
  code: err.code || err.response?.status || 444,
  message: err.response?.statusText || 'no response'
});

const isAxiosError = (err: AxiosError<ApiError> | AxiosResponse<ApiError>): err is AxiosError =>
  (err as AxiosError<ApiError>).isAxiosError;

const createErrorHandler = ({ isDebug }: CreateErrorHandlerProps) => (
  err: AxiosError<ApiError> | AxiosResponse<ApiError>
) => {
  const details = isAxiosError(err) ? getErrorFromFailureResponse(err) : getErrorFromSuccessResponse(err);

  if (isDebug) {
    const message = `Api error - details: ${JSON.stringify({ ...details, ...getInfoFromConfig(err.config) })}`;
    console.error(message, new Error());
  }

  return Promise.reject(details);
};

export default createErrorHandler;
