import { useEffect, useRef, useState } from 'react';

const MODAL_MAX_WIDTH_RATIO = 0.9;

export default (isVideoInited: boolean, isSmallGap = false) => {
  const [modalWidth, setModalWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const video = containerRef.current?.querySelector('video');
    if (!video) return;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const windowRatio = windowHeight / windowWidth;
    const videoRatio = video.clientHeight / video.clientWidth;

    const isVideoVertical = videoRatio > 1;
    const modalVerticalPadding = isVideoVertical || isSmallGap ? 50 : 250;

    const modalWidth =
      (windowWidth * MODAL_MAX_WIDTH_RATIO) / (videoRatio / windowRatio) -
      modalVerticalPadding;

    setModalWidth(modalWidth);
  }, [isVideoInited]);

  return [modalWidth, containerRef] as const;
};
