import getFromCdn from '@anm/helpers/getFromCdn';
import { inputFieldStyles, media } from '@anm/styles/helpers';
import styled, { css } from 'styled-components';

import { SelectSizes, SelectVariants } from '../Select';

import { optionIconStyle } from './SelectOptionsContainer';

type SelectValueContainerProps = {
  isOpened: boolean;
  icon?: string;
  size?: SelectSizes;
  variant?: SelectVariants;
};

const smallSelectMobile = media.phone`
  font-size: 14px;
`;

const sizes: { [key in SelectSizes]: ReturnType<typeof css> } = {
  small: css`
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 15px;
    justify-content: space-between;
    ${smallSelectMobile};
  `,
  middle: css`
    padding: 15px;
  `,
  narrow: css``
};

export const variants: { [key in SelectVariants]: ReturnType<typeof css> } = {
  'without-border': css`
    border-width: 0;
    color: #919eaa;
    font-weight: bold;
  `,
  'with-border': css``,
  'with-2-columns': css`
    padding-left: 10px;
    margin-right: auto;
    height: 40px;
  `
};

export default styled.div<SelectValueContainerProps>`
  ${inputFieldStyles};
  cursor: pointer;
  padding: 18px 0 18px;
  display: flex;
  align-items: center;
  height: auto;
  line-height: 19px;

  &:after {
    display: block;
    content: '';
    width: 28px;
    height: 100%;
    min-height: 6px;
    background: url(${getFromCdn('images/select-dropdown-arrow.svg')}) no-repeat center;
    transition: 0.2s;
    transform: ${({ isOpened }) => (isOpened ? 'rotate(180deg)' : 'none')};
  }

  ${({ size }) => sizes[size || 'middle']};
  ${({ variant }) => variants[variant || 'with-border']};
  ${({ icon }) => icon && optionIconStyle};
`;
