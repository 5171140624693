import { DEFAULT_CONFIGS } from '@anm/data/hostingDefaults';
import getBestFitQuality from '@anm/helpers/getBestFitQuality';
import merge from 'lodash/merge';

import { Configs, Manifest, PlayerConfig, SupportedQualities, VideoData } from '../../types';
import getVideoData from '../getVideoData';
import mapConfigs from '../mapConfigs';
import sortByQuality from '../sortByQuality';

const parseManifest = (manifestData: Manifest, playerWidth?: number): VideoData => {
  const {
    main,
    meta,
    mobile,
    desktop,
    uploadId,
    projectId,
    password,
    isEmbedded,
    configs: configsFromManifest
  } = manifestData;

  const configs: Configs = merge(DEFAULT_CONFIGS, configsFromManifest);

  const mediaObjects = { main, mobile, desktop };
  const { audio, sources, posters, thumbnailsConfig } = getVideoData(mediaObjects);

  const bestFitPosterQuality = getBestFitQuality<SupportedQualities>({
    playerWidth,
    sources: posters,
    type: 'image'
  });

  const bestQualityPoster = sortByQuality(posters)[0];

  const bestFitPoster = posters.filter(p => p.width === bestFitPosterQuality)[0] || bestQualityPoster;

  const bestQualityVideoSource = sortByQuality(sources)[0];
  const bestQualityVideo = bestQualityVideoSource?.path;

  const width = bestQualityVideoSource?.width || bestFitPoster?.width;
  const height = bestQualityVideoSource?.height || bestFitPoster?.height;

  const parsedConfigs = mapConfigs(configs, sources);

  const customConfigs: PlayerConfig = {
    ...parsedConfigs,
    ...thumbnailsConfig
  };
  const poster = !posters.length ? '' : bestFitPoster?.path;

  return {
    meta,
    audio,
    sources,
    configs,
    uploadId,
    projectId,
    isEmbedded,
    customConfigs,
    bestQualityVideo,
    sizes: {
      width,
      height
    },
    poster: {
      posterUrl: poster
    },
    password: {
      password: password.hash,
      isEnabled: password.isEnabled,
      description: password.description
    }
  };
};

export default parseManifest;
