import { Request } from '@anm/api';
import { getThrottleAdapter } from '@anm/api/helpers/cacheAdapters';

import {
  CreateFolderResponse,
  CreateUploadRequest,
  CreateUploadResponse,
  DownloadStatus,
  FetchUploadRequest,
  GetUploadsCountResponse,
  MoveUploadProps,
  TGetFolderData,
  UpdateFolderParams,
  UpdateMediaParams,
  UploadElement,
  UploadFileRequest,
  UploadFolder,
  UploadMediaFile,
  UploadProjectsResponse
} from './types';

export * from './types';

export const getFolderData = (request: Request) => (params: TGetFolderData) =>
  request<UploadElement[]>({
    url: 'v2/upload/folder/data/list',
    method: 'GET',
    params: {
      ...params,
      order: 'desc',
      path: encodeURIComponent(params.path)
    }
  });

const cacheAdapter = getThrottleAdapter(15 * 1000);

export const getUploadMedia = (request: Request) => ({ id, isCached }: FetchUploadRequest) =>
  request<UploadMediaFile>({
    url: `v2/media/${id}`,
    method: 'GET',
    adapter: isCached ? cacheAdapter : undefined
  });

export const updateFolderPath = (request: Request) => (data: UpdateFolderParams) =>
  request<{ id: string }>({
    url: 'v2/upload/folder',
    method: 'PUT',
    data
  });

export const updateMediaMeta = (request: Request) => ({ uploadId, ...meta }: UpdateMediaParams) =>
  request<UploadFolder>({
    url: `v2/media/${uploadId}`,
    method: 'PUT',
    data: {
      meta,
      isEmbedded: false
    }
  });

export const createUploadFolder = (request: Request) => (folders: string[]) =>
  request<CreateFolderResponse>({
    url: 'v2/upload/folder',
    method: 'POST',
    data: folders
  });

export const deleteFolder = (request: Request) => (folderPath: string) =>
  request<string>({
    url: 'v2/upload/folder',
    method: 'DELETE',
    params: {
      path: encodeURIComponent(folderPath)
    }
  });

export const createUpload = (request: Request) => ({
  nameSpace = 'upload',
  simpleUpload = true,
  ...data
}: CreateUploadRequest) =>
  request<CreateUploadResponse>({
    url: `v2/${nameSpace}`,
    method: 'POST',
    data,
    ...(simpleUpload && { params: { simpleUpload } })
  });

export const uploadFile = (request: Request) => ({
  url,
  file,
  method,
  cancelToken,
  onUploadProgress
}: UploadFileRequest) =>
  request<void>({
    method,
    data: file,
    cancelToken,
    baseURL: url,
    onUploadProgress
  });

export const deleteUploadFile = (request: Request) => (uploadId: string) =>
  request<{ id: string }>({
    method: 'DELETE',
    url: `v2/media/${uploadId}`
  });

export const getUploadProjects = (request: Request) => (uploadId: string) =>
  request<UploadProjectsResponse>({
    url: `uploads/projects`,
    method: 'GET',
    params: { uploadId }
  });

export const getUploadByProjectId = (request: Request) => (projectId: string) =>
  request<UploadMediaFile>({
    url: `v2/media/find`,
    method: 'GET',
    params: { projectId }
  });

export const moveUploadToFolder = (request: Request) => ({ uploadId, folderPath }: MoveUploadProps) =>
  request<{ id: string }>({
    url: `v2/media/${uploadId}/move`,
    method: 'POST',
    params: { folderPath }
  });

export const getDownloadUrl = (request: Request) => (uploadId: string) =>
  request<string>({
    url: `streaming/download/${uploadId}`
  });

export const checkDownloadStatus = (request: Request) => (uploadId: string) =>
  request<DownloadStatus>({
    url: `streaming/download/status/${uploadId}`
  });

export const generateDownload = (request: Request) => (uploadId: string) =>
  request<DownloadStatus>({
    method: 'POST',
    url: `streaming/download/${uploadId}`
  });

export const getUploadsCount = (request: Request) => () =>
  request<GetUploadsCountResponse>({
    url: `v2/upload/user/uploadsCount`,
    method: 'GET'
  });
