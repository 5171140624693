import useDefaultDevices from '@anm/hooks/media/useDefaultDevices';
import { MediaIds } from '@anm/hooks/media/useMediaStreams';
import { useEffect } from 'react';

const useInitStream = (createStreams: (ids: Partial<MediaIds>) => void) => {
  const { webcam, mic, speaker } = useDefaultDevices();
  const isDevicesExist = !!webcam || !!mic || !!speaker;

  useEffect(() => {
    if (isDevicesExist) {
      createStreams({
        webcamId: webcam?.deviceId,
        micId: mic?.deviceId
      });
    }
  }, [isDevicesExist]);
};

export default useInitStream;
