import { MediaDevicesList, MediaOptions } from '@anm/helpers/media/mediaManager';
import { useEffect, useState } from 'react';

const useMediaManagerOptions = <T extends MediaOptions>(mediaDevice: MediaDevicesList<T>) => {
  const [options, setOptions] = useState(mediaDevice.getCurrentOptions());

  useEffect(
    () =>
      mediaDevice.changeOptionsEvent.on(newOptions => {
        setOptions(newOptions);
      }),

    []
  );

  return options;
};

export default useMediaManagerOptions;
