import { flexCenter, media } from '@anm/styles/helpers';
import { FC, RefObject } from 'react';
import styled, { css } from 'styled-components';

export type BadgeVariant = 'red' | 'grey' | 'blue' | 'light-red' | 'orange' | 'green' | 'yellow' | 'light-orange';

export type BadgeProps = {
  variant: BadgeVariant;
  icon?: string;
  badgeRef?: RefObject<HTMLDivElement>;
  className?: string;
};

const badgeAdaptiveStyles = media.tablet`
  &:before {
    margin: 0;
  }
`;

const iconStyles = css<BadgeProps>`
  ${({ icon }) =>
    icon &&
    css`
      &:before {
        width: 14px;
        height: 14px;
        margin-right: 4px;
        background-image: url(${icon});
      }
    `}
`;

const badgeCommonStyles = css<BadgeProps>`
  height: 23px;
  font-size: 13px;
  line-height: 16px;
  margin-right: 6px;
  margin-bottom: 8px;
  border-radius: 4px;
  font-weight: normal;
  padding: 1px 6px 0 5px;
  ${iconStyles};
`;

const lightOrangeBadgeMobile = media.phone`
  top: -100%;
  white-space: normal;
  width: 108px;
  line-height: 15px;
  text-align: center;
`;

const variants: Record<BadgeVariant, ReturnType<typeof css>> = {
  blue: css<BadgeProps>`
    color: #1593b7;
    background: #e1f9ff;
    ${badgeCommonStyles};
  `,
  orange: css<BadgeProps>`
    color: #d38528;
    background: #fff4db;
    ${badgeCommonStyles};
  `,
  green: css<BadgeProps>`
    color: #0c9744;
    background: #dcf4d9;
    ${badgeCommonStyles};
  `,
  'light-orange': css<BadgeProps>`
    background-color: #fff4db;
    border-radius: 5px;
    font-size: 12px;
    line-height: 12px;
    padding: 10px 10px 9px;
    color: inherit;
    display: inline-block;
    cursor: pointer;
    text-transform: none;
    position: absolute;
    white-space: nowrap;
    top: -3px;
    margin-left: 5px;
    span {
      color: #292a2b;
    }
    ${({ icon }) =>
      icon &&
      `
    &:before {
      display: none;
    }
  `};
    ${lightOrangeBadgeMobile};
  `,
  yellow: css`
    border-radius: 12px;
    background-color: #fff200;
    line-height: 24px;
    color: #292a2b;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 12px;
  `,
  'light-red': css`
    color: var(--error);
    background: #ffe3e3;
    ${badgeCommonStyles}
    margin-bottom: 0;
  `,
  red: css`
    color: #fff;
    background: var(--error);
    ${badgeCommonStyles}
    margin-bottom: 0;
  `,
  grey: css`
    color: ${({ theme }) => theme.textColor};
    background-color: ${({ theme }) => theme.destinationHeaderBlockBg};
    ${badgeCommonStyles}
    margin-bottom: 0;
  `
};

export const Wrapper = styled.div<BadgeProps>`
  ${flexCenter};
  &:before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  ${({ variant }) => variants[variant]}
  ${badgeAdaptiveStyles};
`;

const Badge: FC<BadgeProps> = ({ icon, variant, className, badgeRef, children: title }) => (
  <Wrapper {...{ icon, variant, ...(className && { className }) }} ref={badgeRef}>
    <span>{title}</span>
  </Wrapper>
);

export default Badge;
